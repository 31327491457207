<template>
  <a-popover v-model:open="visible" class="share-popover" trigger="click" placement="bottom">
    <a-button type="primary" size="large" class="ant-popover-link" @click.prevent>
      <!-- <template #icon>
                <SvgIcon name="icon-zshare" size="16" />
            </template> -->
      分享
    </a-button>
    <template #content>
      <div class="Menu">
        <a-button class="Menu-item" type="text" @click="copy">
          <template #icon>
            <link-outlined />
          </template>
          复制链接
        </a-button>
        <!-- <a-button class="Menu-item" type="text">
                    <template #icon>
                        <weibo-outlined :style="{ color: '#FB6622' }" />
                    </template>
                    新浪微博
                </a-button> -->
        <div class="Menu-item">
          <a-button type="text">
            <template #icon>
              <WechatOutlined :style="{ color: '#07C160' }" />
            </template>
            微信扫一扫
          </a-button>
          <a-qrcode :value="href" :size="120" :bordered="false" />
        </div>
      </div>
    </template>
  </a-popover>
</template>

<script>
import { LinkOutlined, WechatOutlined } from '@ant-design/icons-vue'
export default {
  name: 'ShareLink',
  components: {
    LinkOutlined,
    WechatOutlined
  },
  data() {
    return {
      visible: false
    }
  },
  props: {
    href: {
      type: String,
      required: true
    }
  },
  //   created() {
  //     console.log('===================href', this.href)
  //   },
  methods: {
    open() {
      this.visible = true
    },
    copy() {
      // 创建输入框元素
      const input = document.createElement('input') //不会保留文本格式
      //如果要保留文本格式，比如保留换行符，或者多行文本，可以使用  textarea 标签，再配和模板字符串 ` `
      //const input = document.createElement('textarea')
      // 将想要复制的值
      input.value = this.href
      // 页面底部追加输入框
      document.body.appendChild(input)
      // 选中输入框
      input.select()
      // 执行浏览器复制命令
      document.execCommand('Copy')
      // 弹出复制成功信息
      this.$message.success('复制成功')
      // 复制后移除输入框
      input.remove()
    }
  }
}
</script>

<style lang="less" scoped>
.Menu {
  .ant-btn {
    display: flex !important;
    justify-content: flex-start !important;
    width: 100%;
    height: 40px;
    color: #8590a6;
  }

  .Menu-item {
    color: #8590a6;
  }
}
</style>