<script>
import Swiper from 'swiper'
import { mapGetters } from 'vuex'

import calMixIn from './calMixIn.vue'
import { ZoomInOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue'
import {
  numberToFixed,
  roundToDotNumber,
  debounce,
  fnc_excelData_yc_line,
  fnc_excelData_yc_after,
  checkZero,
  tableSortFnc,
  tableDefaultPage,
  tableDefaultPage2,
  tableDefautlLine,
  dealThousands
} from '@/utils/util'

import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
import { htmlToPDF } from '@/utils/html2pdf'

import SvpLine from './components/SvpLine.vue'
import SvpScatter from './components/SvpScatter.vue'

const groupTypeName = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }

export default {
  components: {
    SvpLine,
    SvpScatter,
    ZoomInOutlined,
    CaretDownOutlined,
    CaretUpOutlined
  },
  mixins: [calMixIn],
  // props: {
  //   listQuery: {
  //     type: Object,
  //     default: ''
  //   }
  // },
  data() {
    let checkedOptions = []
    for (let key in groupTypeName) {
      checkedOptions.push({ label: groupTypeName[key], value: key })
    }

    return {
      detail: {},
      checkedOptions: checkedOptions,
      checkedList: [],
      listQuery: {
        id: '',
        compare_id: ''
      },
      detail: {},
      detailCompare: {},

      tableDataList: [],
      columns: [
        // { title: '纬度', dataIndex: 'name', align: 'center', resizable: true, ellipsis: true },
        { width: 100, title: '项', dataIndex: 'type_name', align: 'left', resizable: true, ellipsis: true, fixed: 'left' },
        { width: 80, title: '销量', dataIndex: 'sold_num', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('sold_num') },
        { width: 80, title: '收入', dataIndex: 'price_come_in', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('price_come_in') },
        { width: 80, title: '变动成本', dataIndex: 'material_cost_money', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('material_cost_money') },
        { width: 80, title: '边际收益', dataIndex: 'slid_money', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('slid_money') }
      ],
      svpPagination: tableDefaultPage(),
      svpPagination2: tableDefaultPage2(),
      svpTableData: [],
      svpColumns: [
        { width: 100, title: '产品编码', dataIndex: 'no', align: 'left', resizable: true, ellipsis: true, ...tableSortFnc('no', 'string') },
        { width: 160, title: '产品规格/描述', dataIndex: 'attr', align: 'left', resizable: true, ellipsis: true },
        { width: 80, title: '计量单位', dataIndex: 'unit', align: 'center', resizable: true, ellipsis: true },
        { width: 80, title: '销量', dataIndex: 'sold_num_result', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('sold_num_result') },
        { width: 100, title: '收入', dataIndex: 'price_come_in_result', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('price_come_in_result') },
        { width: 100, title: '变动成本', dataIndex: 'material_cost_money_result', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('material_cost_money_result') },
        { width: 100, title: '边际收益', dataIndex: 'slid_money_result', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('slid_money_result') }
      ],
      //总收入
      diff_sum_price_come_in: 0,
      //总成本
      diff_material_cost_money: 0,
      //变动成本
      diff_material_cost: 0,
      //边际贡献
      diff_side_money: 0,

      //预测
      diff_sold_num: 0,
      //固定成本
      diff_fixed_money: 0,
      //毛利
      diff_maoli: 0,
      //管理利润
      diff_manager_rate: 0,
      //固定
      diff_fixed_money: 0,
      //混合
      diff_mix_money: 0,
      //混合差异
      diffMixGroup: [],
      //显示混合成本块
      showMixMenu: false,

      svp_tableData: [],
      svp_tableData_compare: [],

      svp_tableData_fixed: [],
      svp_tableData_fixed_compare: [],

      svp_tableData_mix: [],
      svp_tableData_mix_compare: [],

      svp_tableData_mix_columns: [],
      svp_tableData_mix_columns_compare: [],

      scatter_tableData: [],
      scatter_tableData_compare: [],
      topSumObj: {
        sum_sold_num_result: 0,
        // sum_price_add_tax:0,
        sum_price_come_in_result: 0,
        sum_material_cost_money_result: 0,
        sum_slid_money_result: 0,
        upSortData: []
      },

      topSumObjScatter: {
        sum_sold_num: 0,
        sum_price_add_tax: 0,
        sum_price_come_in: 0,
        sum_material_cost_money: 0,
        sum_side_money: 0,
        upSortData: []
      },

      historyId: '',
      remark: '',
      isPrint: false,
      downLoading: false,
      scatterType: 'customer',
      scatterTypeCompare: 'customer',
      scatterTypetableData: [],
      planType: 0,

      getIsSave: false,

      scatterTable: [],
      scatterTableCompare: [],
      topSumObjCompare: {},

      scatterTableData: [],

      submitLoading: false,

      remark_base: '',
      remark_base_mobile: '',
      //备注-扩展
      remark_extra: '',
      remark_extra_mobile: '',

      mySwiper: null,
      mySwiper2: null
    }
  },
  created() {
    // console.log('================this.$route.query', this.$route.query)
    this.historyId = this.$route.query.historyId || 0
    this.listQuery.id = this.$route.query.id || 0
    this.listQuery.compare_id = this.$route.query.id1 || 0
    // this.getData()
  },
  mounted() {
    this.mySwiper = new Swiper('.swiper-canvas', {
      noSwiping: true,
      on: {
        slideChangeTransitionEnd: (e) => {
          // console.log(e)
          // setTimeout(()=>{
          this.planType = this.mySwiper.activeIndex
          // },100)
        }
      }
    })
    this.mySwiper2 = new Swiper('.swiper-table', {
      // 允许触摸滑动
      allowTouchMove: true
    })
    //项目初始化主題色
    // let themeColor = (ls.get(SITE_SETTINGS) || {}).THEME_COLOR
    // if (themeColor) {
    //   systemConfig.commit(THEME_COLOR, (ls.get(SITE_SETTINGS) || {}).THEME_COLOR)
    // }

    let themeColor = ''
    if (this.$route.query.themeColor && this.$route.query.themeColor !== '') {
      themeColor = '#' + this.$route.query.themeColor
      systemConfig.commit(THEME_COLOR, themeColor)
    }
    if (this.getHasToolAuth('tools1_compare')) {
      this.getCompareDetail()
      this.getData()
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getMoneyUnit() {
      // let moneyRange = [1, '元']
      // if (this.getSumPriceComeIn * 1 > 100000) {
      //   moneyRange = [10000, '万元']
      // }
      let moneyRange = [10000, '万元']
      return moneyRange
    },
    getNumberFnc() {
      const w_1 = 10000
      const w_10 = 100000
      const div = this.getMoneyUnit[0]
      const soldUnit = this.getMoneyUnit[1]
      return (number, decimal = 2, bindUnit = false) => {
        let unit = soldUnit
        let str = dealThousands((number / div).toFixed(decimal), decimal)
        if (bindUnit) {
          //处理销量问题
          str = dealThousands((number / 1).toFixed(decimal), decimal)
          unit = ''
          if (number * 1 > w_10) {
            unit = '万'
            str = dealThousands((number / w_1).toFixed(decimal), decimal)
          }
        }
        if (bindUnit) {
          str += unit
        }
        return str
      }
    },
    getTitle() {
      return this.detail.name + '（基准） VS ' + this.detailCompare.name
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.compare_name || ''
    },
    getPlanName_en() {
      return this.detail.compare_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },

    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    sum_svp_sold_num() {
      return this.getSum_svp_tableData('sold_num_result')
    },
    sum_svp_price_come_in() {
      return this.getSum_svp_tableData('price_come_in_result')
    },
    sum_svp_material_cost_money() {
      return this.getSum_svp_tableData('material_cost_money_result')
    },
    sum_svp_slid_money() {
      return this.getSum_svp_tableData('slid_money_result')
    },
    conditionTableData() {
      return this.tableData.filter((e) => this.checkedList.indexOf(e.key) > -1)
    },
    gettableYheight() {
      // return document.body.clientWidth < 1441 ? 140 : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 200 : 280
      return window.innerHeight - 88
    },
    gettableYheight2() {
      // return document.body.clientWidth < 1441 ? 290 : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 350 : 420
      return window.innerHeight - 128
    },
    getColumntableYheight() {
      return window.innerHeight - 420
    },
    getColumntableYheight2() {
      return window.innerHeight - 460
    },

    getScatterTableInfo() {
      let scatterInfo = {}
      for (let i = 0; i < this.scatterTable.length; i++) {
        const info = this.scatterTable[i]
        if (info.key === this.scatterType) {
          scatterInfo = info
          break
        }
      }
      // console.log('======================scatterInfo', scatterInfo)
      return scatterInfo
    },
    getScatterTableData() {
      return this.getScatterTableInfo.data || []
    },

    getScatterTableCompareInfo() {
      let scatterInfo = {}
      for (let i = 0; i < this.scatterTableCompare.length; i++) {
        const info = this.scatterTableCompare[i]
        if (info.key === this.scatterTypeCompare) {
          scatterInfo = info
          break
        }
      }
      return scatterInfo
    },
    getScatterTableCompareData() {
      return this.getScatterTableCompareInfo.data || []
    }
  },
  unmounted() {
    //清空工具信息
    this.$store.commit('clearTools')
  },
  beforeDestroy() {
    if (this.mySwiper) {
      this.mySwiper.destroy()
      this.mySwiper = null
    }
    if (this.mySwiper2) {
      this.mySwiper2.destroy()
      this.mySwiper2 = null
    }
  },
  methods: {
    onTableChange(item, pagination, filters, sorter, { currentDataSource, action }) {
      if (action == 'sort') {
        return this.calOtherSum(item, currentDataSource)
      }
    },

    onScatterData({ subType, tableDataList, topSumObj }) {
      if (subType && subType === 'compare') {
        this.scatterTableCompare = tableDataList
        this.topSumObjCompare = topSumObj
      } else {
        this.scatterTable = tableDataList
        this.topSumObj = topSumObj
        // this.getScatterTableData('', tableDataList)
      }
      this.$nextTick(() => {
        tableDataList.map((item) => {
          this.calOtherSum(item, item.data)
        })
      })
    },
    onScatterTypeChange(e) {
      var obj = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
      var selected = {}
      var selectedcompare = {}

      if (e === 'compare') {
        for (let key in obj) {
          selectedcompare[obj[key]] = this.scatterTypeCompare == key
        }
        this.$refs.svpCatterAfter.setEchart({
          legend: { selected: selectedcompare }
        })
      } else {
        for (let key in obj) {
          selected[obj[key]] = this.scatterType == key
        }
        this.$refs.svpCatterBefore.setEchart({
          legend: { selected: selected }
        })
      }
      // for (let key in obj) {
      //   selected[obj[key]] = this.scatterType == key
      // }
      // this.$refs.svpCatterAfter.setEchart({
      //   legend: { selected: selected }
      // })
    },
    handleShare() {
      this.getIsSave = true
      // this.$nextTick(() => {
      //   this.$refs.shareLink.open()
      // })
    },
    changeScatterType(value) {
      this.scatterTypetableData = []
      this.getData()
    },
    handleShowResult(item) {
      // console.log(item)
      this.$refs.DialogToolsScatterTableCompare.showDialog(item)
    },
    handleShowResultSVP(item) {
      // console.log(item)
      this.$refs.DialogToolsSvpTableCompare.showDialog(item)
    },
    getCompareDetail() {
      // console.log('=========================this.historyId', this.historyId)
      if (!this.historyId) {
        return
      }
      this.$network('/api/tools/planCompareHistoryDetail', { id: this.historyId }).then((res) => {
        const data = res.data || {}
        const detail = data.detail || ''
        this.remark = detail.remark
      })
    },
    handleSave(callback) {
      this.$refs.SavePlanCompare.open(
        {
          name: this.detail.name || '',
          remark: this.remark,
          plan1: this.listQuery.id,
          plan2: this.listQuery.compare_id,
          tool_type: 'one'
        },
        () => {
          if (callback) {
            callback()
          } else {
            this.$router.replace({ path: '/history/compare' })
          }
        }
      )
    },
    handlePrint() {
      this.$network('/api/tools/planComparePrintTimes', { id: this.historyId, plan1: this.listQuery.id, plan2: this.listQuery.compare_id, tool_type: 'one' })
    },
    async downPDF() {
      this.downLoading = true
      this.isPrint = true
      this.$refs.pageLoading.open('正在进行方案下载')
      //增加打印次数
      this.handlePrint()

      setTimeout(async () => {
        await this.$refs.SolutionTemplatePDFCompare.downPDF(this)

        this.$nextTick(async () => {
          this.$refs.pageLoading.text('方案构件中...')

          let elId = 'SolutionTemplatePDFCompare'
          let pdfName = '对比方案'

          this.$refs.pageLoading.text('正在生成PDF文件')
          await htmlToPDF(elId, pdfName, 'PDF-foot')
          this.$refs.pageLoading.text('下载成功')
          // this.$refs.pageLoading.close()
          // this.isPrint = false

          setTimeout(() => {
            location.reload()
          }, 800)
        })
      }, 200)
    },
    showMoreTableList(item) {
      if (item) {
        const selectKey = item.key || ''
        if (this.topSumObjScatter[selectKey].pagination === false) {
          this.topSumObjScatter[selectKey].pagination = tableDefaultPage2()
          this.calOtherSum(item, this.topSumObjScatter[selectKey].upSortData, false)
        } else {
          this.topSumObjScatter[selectKey].pagination = false
        }
      } else {
        if (this.svpPagination === false) {
          this.svpPagination = tableDefaultPage()
          this.calOtherSum(item, this.topSumObj.upSortData, false)
        } else {
          this.svpPagination = false
        }
      }
    },
    //计算其它加个
    calOtherSum(item, currentDataSource, recordUpData = true) {
      let selectKey = ''
      let selectBlock = this.topSumObj

      if (item) {
        selectKey = item.key || ''
        selectBlock = this.topSumObjScatter[selectKey] || {}
      } else {
      }

      const totalObj = {}
      for (let key in selectBlock) {
        if (key == 'upSortData') continue

        const pageSize = tableDefautlLine //this.pagination.pageSize||0
        const field = key.replace('sum_', '')
        const topSumObj_field = key
        totalObj[topSumObj_field] = 0
        let total = 0
        for (let i = 0; i < currentDataSource.length; i++) {
          const info = currentDataSource[i]

          if (i >= tableDefautlLine) {
            break
          }
          totalObj[topSumObj_field] = totalObj[topSumObj_field] + info[field] * 1
        }
      }
      if (recordUpData) {
        totalObj.upSortData = currentDataSource
      } else {
        totalObj.upSortData = this.topSumObj.upSortData
      }
      totalObj.pagination = tableDefaultPage()
      // console.log(totalObj)
      if (item) {
        // console.log( this.topSumObjScatter)
        this.topSumObjScatter[selectKey] = totalObj
      } else {
        this.topSumObj = totalObj
      }
    },
    onTableChange(item, pagination, filters, sorter, { currentDataSource, action }) {
      // console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
      if (action == 'sort') {
        return this.calOtherSum(item, currentDataSource)
      }
    },
    handleShowData(type) {
      const svpTableData = this._handleTableDataCompare(type)
      const newSort = svpTableData.sort(tableSortFnc('side_money').sorter.compare)
      //计算其它加个
      this.calOtherSum(null, newSort)
      this.svpTableData = newSort
    },
    _handleTableDataCompare(type) {
      const keyFnc = (item) => {
        return item.no
      }
      const calTableData = JSON.parse(JSON.stringify(this.svp_tableData))

      const calTableData_compare = JSON.parse(JSON.stringify(this.svp_tableData_compare))
      const tableData_compare_obj = {}
      calTableData_compare.map((item) => {
        const key = keyFnc(item)
        tableData_compare_obj[key] = item
      })

      return calTableData.map((item) => {
        const key = keyFnc(item)
        const tableData_compare_objInfo = tableData_compare_obj[key] || {}
        const sold_num = tableData_compare_objInfo.sold_num || 0
        const price_come_in = tableData_compare_objInfo.price_come_in || 0
        const material_cost_money = tableData_compare_objInfo.material_cost_money || 0

        const material_cost = tableData_compare_objInfo.material_cost || 0

        //边际贡献
        const slid_money_compare = numberToFixed(price_come_in - material_cost_money)

        item.sold_num = numberToFixed(item.sold_num)
        item.price_come_in = numberToFixed(item.price_come_in)
        item.material_cost_money = numberToFixed(item.material_cost_money)
        item.slid_money = numberToFixed(item.price_come_in - item.material_cost_money)

        item.sold_num_compare = numberToFixed(sold_num)
        item.price_come_in_compare = numberToFixed(price_come_in)
        item.material_cost_money_compare = numberToFixed(material_cost_money)
        item.slid_money_compare = numberToFixed(slid_money_compare)

        item.sold_num_result = numberToFixed(sold_num - item.sold_num)
        item.price_come_in_result = numberToFixed(price_come_in - item.price_come_in)
        item.material_cost_money_result = numberToFixed(material_cost_money - item.material_cost_money)
        item.slid_money_result = numberToFixed(item.slid_money_compare - item.slid_money)

        return item
      })
    },

    getData() {
      this.$network('/api/tools/planCompare', { id: this.listQuery.id, compare_id: this.listQuery.compare_id })
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const detailCompare = data.detail_compare || {}
          this.detail = detail
          this.detailCompare = detailCompare
          const mixColumnsFnc = (values) => {
            const data = []
            values.map(function (item, index) {
              if (index > 0) {
                item.diffMoney = 0
                data.push(item)
              }
            })
            return data
          }
          this.$nextTick(() => {
            let SolutionStuctTableData = null
            let SolutionStuctTableData_compare = null
            let tableData = null
            let tableData_compare = null
            let tableData_fixed = null
            let tableData_fixed_compare = null
            let tableData_mix = null
            let tableData_mix_compare = null
            let tableData_mix_columns = null
            let tableData_mix_columns_compare = null
            let hasMixData = false
            this.typePlanData_fixed(this.listQuery.id, (data) => {
              tableData_fixed = data.list || []
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })
            this.typePlanData_fixed(this.listQuery.compare_id, (data) => {
              tableData_fixed_compare = data.list || []
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })
            this.typePlanData_mix(this.listQuery.id, (data) => {
              hasMixData = true
              tableData_mix_columns = mixColumnsFnc(data.columns || [])
              tableData_mix = data.list || []
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })
            this.typePlanData_mix(this.listQuery.compare_id, (data) => {
              hasMixData = true
              tableData_mix_columns_compare = mixColumnsFnc(data.columns || [])
              tableData_mix_compare = data.list || []
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })
            this.typePlanData('', detail, (data) => {
              tableData = data
              SolutionStuctTableData = this.planSolutionStuct('', data)
              this.diffTableData(SolutionStuctTableData, SolutionStuctTableData_compare)
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })
            this.typePlanData('compare', detailCompare, (data) => {
              tableData_compare = data
              SolutionStuctTableData_compare = this.planSolutionStuct('compare', data)
              this.diffTableData(SolutionStuctTableData, SolutionStuctTableData_compare)
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })
          })
          this.$store.commit('updateToolsState', { field: 'spinning1', value: false })
        })
        .catch((err) => {
          console.error(err)
        })
    },

    getSum_tableData(field) {
      let money = 0
      this.conditionTableData.map(function (item) {
        if (item.lineType !== 'sum_line') {
          money += +item[field]
        }
      })
      return numberToFixed(money)
    },

    getSum_svp_tableData(field) {
      let money = 0
      this.svpTableData.map(function (item) {
        money += +item[field]
      })
      return numberToFixed(money)
    },

    diffTableSum(tableData, tableData_compare, tableData_fixed, tableData_fixed_compare, hasMixData, tableData_mix, tableData_mix_compare, tableData_mix_columns, tableData_mix_columns_compare) {
      if (!tableData || !tableData_compare || !tableData_fixed || !tableData_fixed_compare || !hasMixData) {
        return
      }

      this.svp_tableData = tableData
      this.svp_tableData_compare = tableData_compare

      // console.log('=====================this.svp_tableData_compare22', tableData_compare)

      this.svp_tableData_fixed = tableData_fixed
      this.svp_tableData_fixed_compare = tableData_fixed_compare

      this.svp_hasMixData = hasMixData

      this.svp_tableData_mix = tableData_mix
      this.svp_tableData_mix_compare = tableData_mix_compare

      this.svp_tableData_mix_columns = tableData_mix_columns
      this.svp_tableData_mix_columns_compare = tableData_mix_columns_compare

      const keyFnc = (item) => {
        return item.no
      }

      // console.log('=============================svp_tableData_fixed', this.svp_tableData_fixed, this.svp_tableData_fixed_compare)

      // console.log('=============================svp_tableData_mix', this.svp_tableData_mix, this.svp_tableData_mix_compare)

      // console.log('=============================svp_tableData_fixed', this.svp_tableData_mix_columns, this.svp_tableData_mix_columns_compare)

      const tableData_compare_obj = {}
      tableData_compare.map((item) => {
        const key = keyFnc(item)
        tableData_compare_obj[key] = item
      })

      const dataObj_compare = this.handleData(tableData, tableData_fixed, tableData_mix, tableData_mix_columns || [])
      const dataObj = this.handleData(tableData_compare, tableData_fixed_compare, tableData_mix_compare, tableData_mix_columns_compare || [])

      this.diff_sum_price_come_in = roundToDotNumber(dataObj.sum_price_come_in * 1 - dataObj_compare.sum_price_come_in * 1)
      //边际贡献
      this.diff_side_money = roundToDotNumber(dataObj.sum_side_money - dataObj_compare.sum_side_money)
      //预测
      this.diff_sold_num = roundToDotNumber(dataObj.sum_sold_num - dataObj_compare.sum_sold_num)
      //毛利
      this.diff_maoli = roundToDotNumber(dataObj.maoli - dataObj_compare.maoli)
      //管理利润
      this.diff_manager_rate = roundToDotNumber(dataObj.manager_rate - dataObj_compare.manager_rate)
      //固定
      this.diff_fixed_money = roundToDotNumber(dataObj.sum_fixed_total_money - dataObj_compare.sum_fixed_total_money)
      //混合
      this.diff_mix_money = roundToDotNumber(dataObj.sum_mix_total_money - dataObj_compare.sum_mix_total_money)
      // console.log(dataObj.sum_mix_total_money , dataObj_compare.sum_mix_total_money)
      const material_cost_money = dataObj.sum_material_cost_money * 1 + dataObj.sum_fixed_total_money * 1 + dataObj.sum_mix_total_money * 1
      const material_cost_money_compare = dataObj_compare.sum_material_cost_money * 1 + dataObj_compare.sum_fixed_total_money * 1 + dataObj_compare.sum_mix_total_money * 1

      this.diff_material_cost = roundToDotNumber(dataObj.sum_material_cost_money - dataObj_compare.sum_material_cost_money)

      this.diff_material_cost_money = roundToDotNumber(material_cost_money - material_cost_money_compare)

      const sum_sold_num = dataObj.sum_sold_num
      const sum_sold_num_compare = dataObj_compare.sum_sold_num

      const diffMixFnc = (item, sold_num, index, _tableData_mix, _columns_step_mix) => {
        const item_range_min = item.range_min * 1
        const item_range_max = item.range_max * 1
        let total = 0
        if (item_range_min <= sold_num * 1 && item_range_max > sold_num) {
          total = this.tableData_step_mix_field('total', sold_num * 1, _tableData_mix, _columns_step_mix)
        }

        return numberToFixed(total)
      }

      const disffMixGroup = (tableData_mix_columns || []).map(function (item, index) {
        item.diffMoney = diffMixFnc(item, sum_sold_num, index, tableData_mix, tableData_mix_columns)
        return item
      })

      const disffMixGroup_compare = (tableData_mix_columns_compare || []).map(function (item, index) {
        item.diffMoney = diffMixFnc(item, sum_sold_num_compare, index, tableData_mix_compare, tableData_mix_columns_compare)
        return item
      })

      const diffMixFncResult = (item, index) => {
        const item_range_min = item.range_min * 1
        const item_range_max = item.range_max * 1
        let diffMoney = item.diffMoney
        let diffMoney_compare = 0
        for (let i = 0; i < (disffMixGroup_compare || []).length; i++) {
          const compareInfo = disffMixGroup_compare[i]
          const item_range_min_compare = compareInfo.range_min
          const item_range_max_compare = compareInfo.range_max
          if (item_range_min >= item_range_min_compare && item_range_max <= item_range_max_compare) {
            diffMoney_compare = compareInfo.diffMoney
            break
          }
        }
        return numberToFixed(diffMoney - diffMoney_compare)
      }

      this.diffMixGroup = (disffMixGroup || []).map(function (item, index) {
        item.diffMoney = diffMixFncResult(item, index)
        return item
      })

      //执行差异对比
      this.handleShowData()
    },

    diffTableData(tableData, tableData_compare) {
      if (!tableData || !tableData_compare) {
        return
      }

      this.scatter_tableData = tableData
      // console.log(this.scatter_tableData)
      this.scatter_tableData_compare = tableData_compare
      // return ;

      const keyFnc = (item) => {
        return item.key + '_' + item.name + item.type_name
      }

      const keyFncNo = (item) => {
        return item.no
      }
      const tableData_compare_obj = {}
      tableData_compare.map(function (item) {
        const key = keyFnc(item)
        tableData_compare_obj[key] = item
      })

      let tableDataList = {}
      let topSumObjScatter = {}

      tableData.map(function (item) {
        const groupKey = item.key
        if (!(groupKey in tableDataList)) {
          tableDataList[groupKey] = {
            key: groupKey,
            price_come_in: 0,
            material_cost_money: 0,
            sold_num: 0,
            slid_money: 0,
            list: []
          }

          topSumObjScatter[groupKey] = {
            sum_price_come_in: 0,
            sum_material_cost_money: 0,
            sum_sold_num: 0,
            sum_slid_money: 0,
            upSortData: [],
            pagination: tableDefaultPage2()
          }
        }

        const key = keyFnc(item)
        const tableData_compare_objInfo = tableData_compare_obj[key] || {}
        // console.log(key,tableData_compare_objInfo)
        //处理每一项数据
        const itemList = item.list || []
        const itemList_compare = tableData_compare_objInfo.list || []
        let itemList_compare_obj = {}
        itemList_compare.map(function (item) {
          const key = keyFncNo(item)
          itemList_compare_obj[key] = item
        })

        // console.log(itemList_compare_obj)

        itemList.map((_item) => {
          const key = keyFncNo(_item)
          const item_compare_objInfo = itemList_compare_obj[key] || {}

          const sold_num = item_compare_objInfo.sold_num || 0
          const price_come_in = item_compare_objInfo.price_come_in || 0
          const material_cost_money = item_compare_objInfo.material_cost_money || 0
          const slid_money = item_compare_objInfo.slid_money || 0

          _item.sold_num_compare = numberToFixed(sold_num)
          _item.price_come_in_compare = numberToFixed(price_come_in)
          _item.material_cost_money_compare = numberToFixed(material_cost_money)
          _item.slid_money_compare = numberToFixed(slid_money)

          _item.sold_num_result = numberToFixed(sold_num - _item.sold_num)
          _item.price_come_in_result = numberToFixed(price_come_in - _item.price_come_in)
          _item.material_cost_money_result = numberToFixed(material_cost_money - _item.material_cost_money)
          _item.slid_money_result = numberToFixed(slid_money * 1 - _item.slid_money * 1)
        })

        const sold_num = tableData_compare_objInfo.sold_num || 0
        const price_come_in = tableData_compare_objInfo.price_come_in || 0
        const material_cost_money = tableData_compare_objInfo.material_cost_money || 0
        const slid_money = tableData_compare_objInfo.slid_money || 0

        item.sold_num = numberToFixed(sold_num - item.sold_num)
        item.price_come_in = numberToFixed(price_come_in - item.price_come_in)
        item.material_cost_money = numberToFixed(material_cost_money - item.material_cost_money)
        item.slid_money = numberToFixed(slid_money - item.slid_money)

        item.itemList = itemList
        tableDataList[groupKey].list.push(item)

        tableDataList[groupKey].price_come_in = numberToFixed(tableDataList[groupKey].price_come_in * 1 + item.price_come_in * 1)
        tableDataList[groupKey].material_cost_money = numberToFixed(tableDataList[groupKey].material_cost_money * 1 + item.material_cost_money * 1)
        tableDataList[groupKey].sold_num = numberToFixed(tableDataList[groupKey].sold_num * 1 + item.sold_num * 1)
        tableDataList[groupKey].slid_money = numberToFixed(tableDataList[groupKey].slid_money * 1 + item.slid_money * 1)
        return item
      })

      this.tableDataList = tableDataList
      this.topSumObjScatter = topSumObjScatter

      this.scatterTypetableData.push(this.tableDataList[this.scatterType])

      //计算其它加个
      this.$nextTick(() => {
        for (let key in tableDataList) {
          const item = tableDataList[key]
          this.calOtherSum(item, item.list)
        }
      })

      // console.log('==========================this.scatterTypetableData', this.scatterTypetableData)
      // console.log('==========================this.topSumObjScatter', this.topSumObjScatter)

      // this.onScatterData(type, tableDataList, topSumObjScatter)
    },

    typePlanData_fixed(id, callback) {
      return this.$network('/api/tools/planResultData', {
        id: id,
        type: 'step-fixed'
      })
        .then((res) => {
          const data = res.data || {}
          callback(data)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    typePlanData_mix(id, callback) {
      return this.$network('/api/tools/planResultData', {
        id: id,
        type: 'step-mix'
      })
        .then((res) => {
          const data = res.data || {}
          callback(data)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    typePlanData(type, detail = {}, callback) {
      const pageParams = detail.page_params || {}
      const params_tax_per = pageParams.params_tax_per || 0
      const params_sold_per = pageParams.params_sold_per || 0
      const params_cost_per = pageParams.params_cost_per || 0
      const params_pass_per = pageParams.params_pass_per || 0
      const solutionBaseTable = pageParams.solutionBaseTable || {}

      this.$network('/api/tools/planResultData', { id: detail.id, type: 'step-yc' })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []
          const excelDataChange = []
          list.map(function (item) {
            const no = item.no || ''
            const noPerInfo = solutionBaseTable[no] || {}
            item.params_line_tax_per = noPerInfo.params_line_tax_per || 0
            item.params_line_sold_per = noPerInfo.params_line_sold_per || 0
            item.params_line_cost_per = noPerInfo.params_line_cost_per || 0
            item.params_line_pass_per = noPerInfo.params_line_pass_per || 0
            const afterItem = { ...item }
            for (let key in item) {
              afterItem['__' + key] = item[key]
            }
            excelDataChange.push(afterItem)
          })

          const dataAfter = fnc_excelData_yc_after(list, { params_tax_per, params_sold_per, params_cost_per, params_pass_per })

          callback(dataAfter)
          return dataAfter
        })
        .catch((err) => {
          console.error(err)
        })
    },

    planSolutionStuct(type, value) {
      let groupObject = {}
      value.map(function (item) {
        for (let key in groupTypeName) {
          const itemValue = item[key]
          const name = groupTypeName[key]
          if (!(name in groupObject)) {
            groupObject[name] = {}
          }
          if (!(itemValue in groupObject[name])) {
            groupObject[name][itemValue] = {
              name: name,
              key: key,
              type_name: itemValue,
              //销售收入
              price_come_in: 0,
              //变动成本
              material_cost_money: 0,
              //销量
              sold_num: 0,
              //边际收益
              slid_money: 0,

              list: []
            }
          }
          const sold_num = item.sold_num * 1
          //产量
          const pass_sold_num = item.pass_sold_num * 1

          groupObject[name][itemValue]['price_come_in'] += item.price_come_in * 1
          groupObject[name][itemValue]['material_cost_money'] += item.material_cost * 1 * pass_sold_num
          groupObject[name][itemValue]['sold_num'] += sold_num

          //边际贡献
          item.slid_money = numberToFixed(item.price_come_in - item.material_cost)
          groupObject[name][itemValue]['list'].push(item)

          // groupObject[name][itemValue]['slid_money'] +=  groupObject[name][itemValue]['price_come_in'] - groupObject[name][itemValue]['material_cost_money'];
        }
      })

      for (let _key in groupObject) {
        for (let _member in groupObject[_key]) {
          groupObject[_key][_member]['price_come_in'] = numberToFixed(groupObject[_key][_member]['price_come_in'])
          groupObject[_key][_member]['material_cost_money'] = numberToFixed(groupObject[_key][_member]['material_cost_money'])
          groupObject[_key][_member]['sold_num'] = numberToFixed(groupObject[_key][_member]['sold_num'])
          groupObject[_key][_member]['slid_money'] = numberToFixed(groupObject[_key][_member]['price_come_in'] - groupObject[_key][_member]['material_cost_money'])
        }
      }

      let tableData = []

      for (let typeKey in groupTypeName) {
        const name = groupTypeName[typeKey]

        //表格数据
        let sum_price_come_in = 0
        let sum_material_cost_money = 0
        let sum_sold_num = 0
        let sum_slid_money = 0

        for (let memberKey in groupObject[name]) {
          const item = groupObject[name][memberKey]
          sum_price_come_in = sum_price_come_in + item.price_come_in * 1
          sum_material_cost_money = sum_material_cost_money + item.material_cost_money * 1
          sum_sold_num = sum_sold_num + item.sold_num * 1
          sum_slid_money = sum_slid_money + item.slid_money * 1

          tableData.push({
            name: name,
            key: typeKey,
            lineType: 'line',
            type_name: memberKey,
            price_come_in: item.price_come_in,
            material_cost_money: item.material_cost_money,
            sold_num: item.sold_num,
            slid_money: item.slid_money,
            list: item.list
          })
        }
      }

      return tableData
    },
    handleSubmit() {}
  }
}
</script>