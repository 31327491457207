<template>
  <div class="bar_echart cloud3-table" :class="isMobile ? 'bar_echart_mobile' : 'bar_echart_pc'" v-show="getShowBar">
    <div class="bar_echart_head">
      <a-radio-group v-model:value="type" button-style="solid" @change="selectTypeChange()">
        <a-radio-button value="asyncal">分析维度</a-radio-button>
        <a-radio-button value="manager">管理维度</a-radio-button>
      </a-radio-group>

      <div class="echart-tools-bar-params">
        <!-- <div class="switch-block" @click="toolsOpen = !toolsOpen">{{ toolsOpen ? '收 起' : '展 开' }}</div> -->

        <a-button type="primary" @click="toolsOpen = !toolsOpen">
          <span>{{ toolsOpen ? '收 起' : '展 开' }}</span>
          <svg class="icon svg-icon" aria-hidden="true">
            <use xlink:href="#icon-xiangxia"></use>
          </svg>
        </a-button>

        <a-form :model="formState" :labelCol="{ style: { width: '100px' } }" size="small">
          <div class="form-block" v-show="toolsOpen">
            <a-form-item label="角度">
              <a-row>
                <a-col :span="10">
                  <a-slider v-model:value="formState.rotate" :min="-90" :max="90" :tipFormatter="null" />
                </a-col>
                <a-col :span="4">
                  <a-input-number v-model:value="formState.rotate" :min="-90" :max="90" style="margin-left: 16px" />
                </a-col>
              </a-row>
            </a-form-item>
            <a-form-item label="对齐方式">
              <a-select v-model:value="formState.align" placeholder="please select your zone">
                <a-select-option value="left">左对齐</a-select-option>
                <a-select-option value="center">居中对齐</a-select-option>
                <a-select-option value="right">右对齐</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="垂直对齐方式">
              <a-select v-model:value="formState.verticalAlign" placeholder="please select your zone">
                <a-select-option value="top">顶部</a-select-option>
                <a-select-option value="middle">居中</a-select-option>
                <a-select-option value="bottom">底部</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="位置">
              <a-select v-model:value="formState.position" placeholder="please select your zone">
                <a-select-option value="left">左边</a-select-option>
                <a-select-option value="right">右边</a-select-option>
                <a-select-option value="top">顶部</a-select-option>
                <a-select-option value="bottom">底部</a-select-option>
                <a-select-option value="inside">内侧</a-select-option>
                <a-select-option value="insideTop">内侧顶部</a-select-option>
                <a-select-option value="insideLeft">内侧左边</a-select-option>
                <a-select-option value="insideRight">内侧右边</a-select-option>
                <a-select-option value="insideBottom">内侧底部</a-select-option>
                <a-select-option value="insideTopLet">内侧左上</a-select-option>
                <a-select-option value="insideTopRight">内侧右上</a-select-option>
                <a-select-option value="insideBottomLeft">内侧左下</a-select-option>
                <a-select-option value="insideBottomRight">内侧右下</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="距离">
              <a-row>
                <a-col :span="10">
                  <a-slider v-model:value="formState.distance" :min="0" :max="100" :tipFormatter="null" />
                </a-col>
                <a-col :span="4">
                  <a-input-number v-model:value="formState.distance" :min="0" :max="100" style="margin-left: 16px" />
                </a-col>
              </a-row>
            </a-form-item>
          </div>
        </a-form>
      </div>
    </div>
    <div class="bar_echart_tabs">
      <a-space>
        <div class="item" v-if="usePlan.indexOf('step3-material') > -1">
          <span> {{ getTypeName('step3-material') }}</span>

          <a-select v-model:value="materialValue" :options="type == 'manager' ? materialOptions_manager : materialOptions" @change="selectChange('type', $event)"></a-select>
        </div>
        <div class="item" v-if="usePlan.indexOf('step3-stock') > -1">
          <span>{{ getTypeName('step3-stock') }}</span>

          <a-select v-model:value="stockValue" :options="type == 'manager' ? stockOptions_manager : stockOptions" @change="selectChange('type', $event)"></a-select>
        </div>
        <div class="item" v-if="usePlan.indexOf('step3-send-product') > -1">
          <span>{{ getTypeName('step3-send-product') }}</span>

          <a-select v-model:value="sendProductValue" :options="type == 'manager' ? sendProductOptions_manager : sendProductOptions" @change="selectChange('type', $event)"></a-select>
        </div>
      </a-space>
    </div>

    <div :class="platform === 'tablet' ? 'tablet-bar_echartId' : 'bar_echartId'" :id="echartId"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { numberToFixed, checkZero, debounce, echartGraphic, dateFormatter, getUsePlatform } from '@/utils/util'
import mixinFnc from '../mixin-fnc.js'

export default {
  name: 'BarEchart',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isPrint: {
      type: Boolean,
      default: false
    },
    usePlan: {
      type: Array,
      default: () => []
    },
    material_origin: {
      type: Array,
      default: () => []
    },
    stock_origin: {
      type: Array,
      default: () => []
    },
    send_product_origin: {
      type: Array,
      default: () => []
    },
    initDataState: {
      //数据初始化状态
      type: String,
      default: ''
    },
    planType: {
      type: String,
      default: ''
    }
  },
  mixins: [mixinFnc],
  components: {},
  data() {
    const dateOptions = [
      { label: '0-30天区间', value: 'date30' },
      { label: '30-60天区间', value: 'date60' },
      { label: '60-90天区间', value: 'date90' },
      { label: '90-180天区间', value: 'date180' },
      { label: '180-360天区间', value: 'date360' },
      { label: '360天以上', value: 'datemore360' }
    ]
    const dateManagerOptions = [
      { label: '>30天', value: 'date30' },
      { label: '>60天', value: 'date60' },
      { label: '>90天', value: 'date90' },
      { label: '>180天', value: 'date180' },
      { label: '>360天', value: 'date360' }
    ]
    return {
      bodyWidth: document.body.offsetWidth,
      toolsOpen: false,
      type: 'asyncal',
      formState: {
        rotate: 90,
        align: 'left',
        verticalAlign: 'middle',
        position: 'insideBottom',
        distance: 15
      },
      materialValue: 'date180',
      materialOptions: dateOptions,
      materialOptions_manager: dateManagerOptions,
      stockValue: 'date180',
      stockOptions: dateOptions,
      stockOptions_manager: dateManagerOptions,
      sendProductValue: 'date180',
      sendProductOptions: dateOptions,
      sendProductOptions_manager: dateManagerOptions,
      //所有月份
      AllMonth: [],
      echart_series: [],
      echartId: 'echart-bar-' + new Date().getTime() + '-' + Math.ceil(Math.random() * 10),
      echartObj: () => {
        return {}
      },

      formStateInter: 0,

      pageParams: {},

      materialMonth_firstMonth: '',
      materialMonth_lastMonth: '',
      materialMonth_diffMonth: 0,

      stockMonth_firstMonth: '',
      stockMonth_lastMonth: '',
      stockMonth_diffMonth: 0,

      sendProduct_firstMonth: '',
      sendProduct_lastMonth: '',
      sendProduct_diffMonth: 0,

      platform: ''
    }
  },
  computed: {
    getShowBar() {
      return this.materialMonth_diffMonth > 12 || this.stockMonth_diffMonth > 12 || this.sendProduct_diffMonth > 12
    },
    getSelectTypeName() {
      let name = '分析维度'
      if (this.type === 'manager') {
        name = '管理维度'
      }
      return name
    }
  },
  watch: {
    initDataState: {
      immediate: true,
      handler(value) {
        if (value === 'complete') {
          //数据初始化完成
          this.initPageData()
        }
      }
    },
    formState: {
      deep: true,
      handler(value) {
        this.changeEchartFormState()
      }
    },
    planType(value) {
      if (value == 'base') {
        this.$nextTick(() => {
          setTimeout(() => {
            // this.echartObj()['echart'] 就是对这个 echarts 实例的引用，而 .resize() 是对该实例调用 resize 方法,用于重新计算和更新图表尺寸。
            this.echartObj()['echart'].resize()
          }, 200)
        })
      }
    }
  },
  mounted() {
    this.platform = getUsePlatform()
    const that = this
    this.echartObj = (() => {
      let obj = null
      return function () {
        if (obj) {
          return obj
        } else {
          obj = {}
          const echart = echarts.init(document.getElementById(that.echartId), null, {
            renderer: 'canvas',
            useDirtyRect: false
          })
          obj.echart = echart
          return obj
        }
      }
    })()
    //    this.handleData()
  },
  methods: {
    setPageParams(params) {
      this.pageParams = params || {}
      const formState = this.pageParams.formState || {}
      this.type = this.pageParams.type || 'asyncal'
      this.materialValue = this.pageParams.materialValue || 'date180'
      this.stockValue = this.pageParams.stockValue || 'date180'
      this.sendProductValue = this.pageParams.sendProductValue || 'date180'
      this.formState = {
        rotate: formState.rotate || 90,
        align: formState.align || 'left',
        verticalAlign: formState.verticalAlign || 'middle',
        position: formState.position || 'insideBottom',
        distance: formState.distance || 15
      }
    },
    getPageParams() {
      return {
        type: this.type,
        formState: this.formState,
        materialValue: this.materialValue,
        stockValue: this.stockValue,
        sendProductValue: this.sendProductValue
      }
    },
    getMonthRange(data) {
      const dataLen = data.length || 0
      const firstItem = data[0] || {}
      const lastItem = data[dataLen - 1] || {}
      const monthArr = [firstItem.month, lastItem.month].sort()
      const firstMonth = monthArr[0]
      const lastMonth = monthArr[1]
      return {
        firstMonth: firstMonth,
        lastMonth: lastMonth,
        diffMonth: this.$diffMonth(firstMonth, lastMonth)
      }
    },
    initPageData() {
      // const materialObj = this.handleClearData(this.material_origin,'step3-material')
      const materialMonth = this.getMonthRange(this.material_origin)
      this.materialMonth_firstMonth = materialMonth.firstMonth || ''
      this.materialMonth_lastMonth = materialMonth.lastMonth || ''
      this.materialMonth_diffMonth = materialMonth.diffMonth || 0
      this.materialGroupMonthData = this.handleData(this.material_origin, 'step3-material')

      const stockMonth = this.getMonthRange(this.stock_origin)
      this.stockMonth_firstMonth = stockMonth.firstMonth || ''
      this.stockMonth_lastMonth = stockMonth.lastMonth || ''
      this.stockMonth_diffMonth = stockMonth.diffMonth || 0
      this.stockGroupMonthData = this.handleData(this.stock_origin, 'step3-stock')

      const sendProductMonth = this.getMonthRange(this.send_product_origin)
      this.sendProduct_firstMonth = sendProductMonth.firstMonth || ''
      this.sendProduct_lastMonth = sendProductMonth.lastMonth || ''
      this.sendProduct_diffMonth = sendProductMonth.diffMonth || 0
      this.sendProductGroupMonthData = this.handleData(this.send_product_origin, 'step3-send-product')

      this.$nextTick(() => {
        this.selectChange()
      })
    },
    selectTypeChange() {
      if (this.type === 'manager') {
        console.log('-materialValue-', this.materialValue)
        console.log('-stockValue-', this.stockValue)
        console.log('-sendProductValue-', this.sendProductValue)
        if (this.materialValue === 'datemore360') {
          this.materialValue = 'date360'
        }
        if (this.stockValue === 'datemore360') {
          this.stockValue = 'date360'
        }
        if (this.sendProductValue === 'datemore360') {
          this.sendProductValue = 'date360'
        }
      }
      this.$nextTick(() => {
        this.selectChange()
      })
    },
    selectChange() {
      const material_serial = this.calData(this.materialGroupMonthData, 'step3-material', this.materialValue)
      const stock_serial = this.calData(this.stockGroupMonthData, 'step3-stock', this.stockValue)
      const send_product_serial = this.calData(this.sendProductGroupMonthData, 'step3-send-product', this.sendProductValue)
      const series = []
      if (material_serial.name) {
        series.push(material_serial)
      }
      if (stock_serial.name) {
        series.push(stock_serial)
      }
      if (send_product_serial.name) {
        series.push(send_product_serial)
      }

      this.echart_series = series
      //绑定变化
      this.$emit('change')

      this.drawEchart()
    },
    calData(groupMonthObj, dataType, selectValue) {
      if (this.usePlan.indexOf(dataType) === -1) {
        return {}
      }

      const colors = {
        'step3-material': '#fc7d02',
        'step3-stock': '#fbdb0f',
        'step3-send-product': '#1890ff'
      }

      const series = {
        name: this.getTypeName(dataType),
        monthRange: '',
        type: 'bar',
        barGap: 0,
        label: {
          show: true,
          rotate: this.formState.rotate,
          align: this.formState.align,
          verticalAlign: this.formState.verticalAlign,
          position: this.formState.position,
          distance: this.formState.distance,
          formatter: function (item) {
            return item.seriesName + '  ' + numberToFixed(item.data, 2)
          }, // '{c}  {name|{a}}',
          // fontSize: 12,
          textStyle: {
            // color: '#000000' // 设置字体颜色为黑色
            // color: dataType === 'step3-send-product' ? '#ffffff' : '#000000'
            fontSize: this.bodyWidth < 1441 ? 9 : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? 12 : 12
          },
          rich: {
            name: {}
          }
        },
        emphasis: { focus: 'series' },
        data: [],
        itemStyle: {
          color: colors[dataType]
        }
      }

      this.AllMonth.map((month) => {
        let value = 0
        if (month in groupMonthObj) {
          const obj = groupMonthObj[month] || {}
          const data = obj.data || []
          data.map((item) => {
            switch (selectValue) {
              case 'date30':
                value += this.type === 'manager' && selectValue == 'date30' ? 0 : (item.date_30_money || 0) * 1
                if (this.type === 'asyncal') {
                  break
                }
              case 'date60':
                value += this.type === 'manager' && selectValue == 'date60' ? 0 : (item.date_60_money || 0) * 1
                if (this.type === 'asyncal') {
                  break
                }
              case 'date90':
                value += this.type === 'manager' && selectValue == 'date90' ? 0 : (item.date_90_money || 0) * 1
                if (this.type === 'asyncal') {
                  break
                }
              case 'date180':
                value += this.type === 'manager' && selectValue == 'date180' ? 0 : (item.date_180_money || 0) * 1
                if (this.type === 'asyncal') {
                  break
                }
              case 'date360':
                value += this.type === 'manager' && selectValue == 'date360' ? 0 : (item.date_360_money || 0) * 1
                if (this.type === 'asyncal') {
                  break
                }
              case 'datemore360':
                value += this.type === 'manager' && selectValue == 'datemore360' ? 0 : (item.date_360_more_money || 0) * 1
                break
            }
          })
        }
        series.data.push(value)
      })
      return series
    },
    handleData(data, dataType) {
      const dataLen = data.length || 0
      if (!dataLen) {
        return {}
      }
      const firstItem = data[0] || {}
      const lastItem = data[dataLen - 1] || {}
      const monthArr = [firstItem.month, lastItem.month].sort()
      const firstMonth = monthArr[0]
      const lastMonth = monthArr[1]
      const monthGroupData = {}
      data.map((item) => {
        const month = item.month
        if (!(month in monthGroupData)) {
          monthGroupData[month] = []
        }
        monthGroupData[month].push(item)
      })

      const actionTimes = 50
      let groupMonth = [firstMonth]
      const date = new Date(firstMonth)
      for (let i = 0; i < actionTimes; i++) {
        date.setMonth(date.getMonth() + 1)
        const dateMonth = this.$dateFormatTimeFnc(date, 'YYYY-MM-DD')
        groupMonth.push(dateMonth)
        if (dateMonth >= lastMonth) {
          //日期到头
          break
        }
      }

      const step = 12 //月份计算长度
      const groupMonthArr = []
      for (let j = 0; j < actionTimes; j++) {
        const monthSlice = groupMonth.slice(j, step + j)
        if (monthSlice.length === step) {
          groupMonthArr.push(monthSlice)
        } else {
          break
        }
      }

      const monthRangeName = function (groupMonthItems) {
        const _monthStart = (groupMonthItems[0] || '').substr(5, 2)
        const _monthEnd = (groupMonthItems[groupMonthItems.length - 1] || '').substr(5, 2)
        return [_monthStart, _monthEnd].join('~')
      }

      // console.log(monthGroupData,firstMonth,lastMonth,groupMonth,groupMonthArr)
      const groupObjMonth = {}
      groupMonthArr.map((groupMonthItems) => {
        // const useMonth = groupMonthItems[0]
        const useMonth = groupMonthItems[groupMonthItems.length - 1]

        if (this.AllMonth.indexOf(useMonth) === -1) {
          this.AllMonth.push(useMonth)
        }

        //组合月份数据
        let monthData = []
        groupMonthItems.map((monthItem) => {
          monthData = monthData.concat(monthGroupData[monthItem] || [])
        })
        const dataObj = this.handleClearData(monthData, dataType)
        groupObjMonth[useMonth] = {
          month: useMonth,
          monthRange: monthRangeName(groupMonthItems),
          dataType: dataType,
          data: Object.values(dataObj.groupData)
        }
      })
      return groupObjMonth
    },
    changeEchartFormState() {
      clearTimeout(this.formStateInter)
      this.formStateInter = setTimeout(() => {
        this.echart_series.map((item) => {
          item.label = Object.assign(item.label, {
            rotate: this.formState.rotate,
            align: this.formState.align,
            verticalAlign: this.formState.verticalAlign,
            position: this.formState.position,
            distance: this.formState.distance
          })
        })
        this.echartObj().echart.setOption({
          series: this.echart_series
        })
      }, 500)
    },
    drawEchart() {
      const option = {
        color: ['#67BDF5', '#7EE99C', '#FBD786', '#FCA192', '#81DFD9', '#0099ff'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          valueFormatter: (value) => numberToFixed(value, 2)
        },
        grid: this.isMobile
          ? {
              left: '2%',
              top: '4%',
              bottom: '4%', // 固定左边刻度宽度
              containLabel: true
            }
          : {
              left: '2%',
              bottom: '4%', // 固定左边刻度宽度
              containLabel: true
            },
        legend: {
          data: this.echart_series.map((item) => item.name)
        },
        toolbox: this.isMobile
          ? {}
          : {
              show: true,
              orient: 'vertical',
              left: 'right',
              top: 'center',
              feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ['line', 'bar', 'stack'] },
                myTool2: {
                  show: true,
                  title: 'restore',
                  icon: 'path://M512 170.666667a339.541333 339.541333 0 0 0-197.546667 62.933333 22.4 22.4 0 0 1-30.421333-3.669333l-26.837333-33.194667a20.522667 20.522667 0 0 1 3.498666-29.568A424.874667 424.874667 0 0 1 512 85.333333c235.648 0 426.666667 191.018667 426.666667 426.666667h-85.333334a341.333333 341.333333 0 0 0-341.333333-341.333333z m-341.333333 341.333333a341.333333 341.333333 0 0 0 538.88 278.4 22.4 22.4 0 0 1 30.421333 3.669333l26.837333 33.194667a20.522667 20.522667 0 0 1-3.498666 29.568A426.026667 426.026667 0 0 1 512 938.666667C276.352 938.666667 85.333333 747.648 85.333333 512h85.333334z M998.314667 512a21.333333 21.333333 0 0 1 16.042666 35.413333l-102.314666 116.906667a21.333333 21.333333 0 0 1-32.085334 0l-102.314666-116.906667a21.333333 21.333333 0 0 1 16.042666-35.413333h204.629334zM230.314667 512a21.333333 21.333333 0 0 0 16.042666-35.413333L144.042667 359.68a21.333333 21.333333 0 0 0-32.085334 0L9.642667 476.586667a21.333333 21.333333 0 0 0 16.042666 35.413333h204.629334z',
                  onclick: () => {
                    this.type = 'asyncal'
                    this.materialValue = 'date180'
                    this.stockValue = 'date180'
                    this.sendProductValue = 'date180'
                    this.selectChange()
                  }
                },
                saveAsImage: { show: true }
              }
            },

        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: this.AllMonth
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: this.echart_series
      }

      // 释放资源
      this.echartObj().echart.setOption(option)
    },

    getSelectDataRangeObjName(value) {
      let obj = {}
      const selectOptions = this.type == 'manager' ? this.materialOptions_manager : this.materialOptions
      console.log(value, selectOptions)
      for (let i = 0; i < selectOptions.length; i++) {
        const info = selectOptions[i]
        if (info.value === value) {
          obj = info
          break
        }
      }
      return obj.label || ''
    },
    getPrintInfo() {
      return {
        name: this.getSelectTypeName,
        materialName: this.usePlan.indexOf('step3-material') > -1 ? this.getTypeName('step3-material') : '',
        materialSelectName: this.getSelectDataRangeObjName(this.materialValue),
        stockName: this.usePlan.indexOf('step3-stock') > -1 ? this.getTypeName('step3-stock') : '',
        stockSelectName: this.getSelectDataRangeObjName(this.stockValue),
        sendProductName: this.usePlan.indexOf('step3-send-product') > -1 ? this.getTypeName('step3-send-product') : '',
        sendProductSelectName: this.getSelectDataRangeObjName(this.sendProductValue),
        img: this.echartObj().echart.getDataURL('png')
      }
    }
  }
}
</script>

<style lang="less">
.bar_echart {
  width: 100%;
  position: relative;
  .bar_echart_head {
    position: relative;
    font-size: 12px;
  }
  .echart-tools-bar-params {
    // width: 300px;
    position: absolute;
    right: 0px;
    top: 0px;
    .ant-btn {
      color: #fff;
      span {
        margin-right: 10px;
        font-size: 12px;
      }
      .icon {
        width: 1em;
        // font-size: 12px;
        fill: currentColor;
        color: #fff;
      }
    }
    .form-block {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 1000;
      min-width: 160px;
      padding: 10px 15px;
      margin: 2px 0 0;
      list-style: none;
      // font-size: 12px;
      // text-align: left;
      background-color: #fff;
      border: 1px solid #eee;
      border-radius: 3px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      width: 300px;
      .ant-form-item {
        margin-bottom: 5px;
        font-size: 12px;
        label {
          font-size: 12px;
        }
      }
    }
    .switch-block {
      width: 100%;
      background: #b2b2b2;
      color: #fff;
      text-align: center;
      cursor: pointer;
      // font-size: 12px;
    }
    .ant-btn-primary {
      background-color: var(--themeColor);
    }
  }
  .bar_echart_tabs {
    margin: 10px 0px;

    .item {
      // font-size: 12px;
      span {
        // font-size: 12px;
        display: block;
        // margin-bottom: 5px;
      }
    }
  }
  &.bar_echart_pc {
    .bar_echartId {
      height: 340px;
    }
  }
  &.bar_echart_mobile {
    .bar_echartId {
      height: calc(100vh - 380px - 24px - 12px - 24px - 20px - 30px - 14px - 30px);
    }

    .tablet-bar_echartId {
      height: calc(100vh / 2 - 70px - 30px - 10px);
    }

    .ant-radio-button-wrapper {
      font-size: 12px;
      height: 24px;
      line-height: 20px;
    }

    .ant-btn {
      font-size: 12px;
      height: 24px;
    }

    .bar_echart_tabs {
      margin: 5px 0px;

      .item {
        .ant-select-single .ant-select-selector {
          .ant-select-selection-item,
          .ant-select-selection-search-input {
            font-size: 12px;
          }
        }
        span {
          font-size: 12px;
          display: block;
          // margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
