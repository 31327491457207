<script>
import mixinFnc from './mixin-fnc.js'
import { message } from 'ant-design-vue'
import { numberToFixed, debounce, fnc_excelData_yc_after, checkZero } from '@/utils/util'
import { mapGetters } from 'vuex'
import { htmlToPDF } from '@/utils/html2pdf'
import { vNodeType } from 'ant-design-vue/es/_util/type.js'

export default {
  mixins: [mixinFnc],
  data() {
    return {
      params_tax_per: 0,
      params_sold_per: 0,
      params_cost_per: 0,
      params_pass_per: 0,
      initDataState: '',

      downLoading: false,
      planType: 'base',
      detail: {},
      listQuery: {},

      tableList: [],

      tableList_material_origin: [], //原始数据
      tableList_material: [],
      materialStartMonth: '', //开始月份
      materialAfterMonth: '', //结束月份
      materialMonth: 0, //总共有多少个月份

      tableList_stock_origin: [], //原始数据
      tableList_stock: [],
      stockStartMonth: '',
      stockAfterMonth: '',
      stockMonth: 0, //总共有多少个月份

      tableList_send_product_origin: [], //原始数据
      tableList_send_product: [],
      sendProductStartMonth: '',
      sendProductAfterMonth: '',
      sendProductMonth: 0, //总共有多少个月份

      onChangeParamsTimer: 0,
      hasActualityData: true,
      isPrint: false,

      remark_base: '',
      remark_extra: '',
      remark_base_time: '',
      remark_base_mobile: '',
      remark_extra_mobile: '',
      remark_extra_time: '',

      lastMonth: '',

      //使用的方案
      usePlan: [],

      lastMonth: '',

      auth_share_state: 0,
      accountType: '',

      authTools: []
    }
  },
  created() {
    this.listQuery.id = this.$route.query.id || 0
    this.planType = this.$route.query.planType == 'extra' ? 'extra' : 'base'
    this.getPlanDetail()
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    }
  },

  methods: {
    getPlanDetail() {
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const pageParams = detail.page_params || {}
          this.detail = detail
          this.remark_base = this.detail.remark_base
          this.remark_extra = this.detail.remark_extra
          this.accountType = data.vip_type

          this.auth_share_state = data.auth_share_state || 0

          this.authTools = data.authTools || []

          this.$nextTick(() => {
            this.getData()
            //加载表格数据
            this.$refs.solutionAnalysis && this.$refs.solutionAnalysis.setParams(pageParams.solutionAnalysis || {})

            //2.0 饼图
            // this.$refs.solutionPie && this.$refs.solutionPie.setPageParams(pageParams.solutionPie || {}, true)

            //3.0 周转分析
            this.$refs.SolutionPlan && this.$refs.SolutionPlan.setPageParams(pageParams.SolutionPlan || {}, true)
            //4.0 高级功能
            this.$refs.barEchart && this.$refs.barEchart.setPageParams(pageParams.barEchart || {}, true)
          })
        })
        .catch((err) => {
          // console.error(err)
        })
    },
    getData() {
      Promise.all([
        //库存
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step3-material' }),
        //成品
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step3-stock' }),
        //发出商品
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step3-send-product' })
      ]).then((res) => {
        const [
          {
            data: { list: step3Material_data }
          },
          {
            data: { list: step3Stock_data }
          },
          {
            data: { list: step3SendProduct_data }
          }
        ] = res

        // step3Material_data.itemStyle.color = '#cccccc'

        //材料清单
        this.tableList_material_origin = JSON.parse(JSON.stringify(step3Material_data || []))

        const materialObj = this.handleClearData(step3Material_data, 'step3-material')
        const step3MaterialList = materialObj.groupData || {}
        if (materialObj.hasDataType) this.usePlan.push('step3-material')

        //成品清单
        this.tableList_stock_origin = JSON.parse(JSON.stringify(step3Stock_data || []))
        const stockObj = this.handleClearData(step3Stock_data, 'step3-stock')
        const step3StockList = stockObj.groupData || {}
        if (stockObj.hasDataType) this.usePlan.push('step3-stock')

        //发出商品
        this.tableList_send_product_origin = JSON.parse(JSON.stringify(step3SendProduct_data || []))
        const sendProductObj = this.handleClearData(step3SendProduct_data, 'step3-send-product')
        const step3SendProductList = sendProductObj.groupData || {}
        if (sendProductObj.hasDataType) this.usePlan.push('step3-send-product')

        const step3Group_material = Object.values(step3MaterialList)
        const step3Group_stock = Object.values(step3StockList)
        const step3Group_sendProduct = Object.values(step3SendProductList)

        this.materialStartMonth = (step3Group_material[step3Group_material.length - 1] || {}).month || ''
        this.materialAfterMonth = (step3Group_material[0] || {}).month || ''
        this.materialMonth = this.$diffMonth(this.materialStartMonth, this.materialAfterMonth)

        this.stockStartMonth = (step3Group_stock[step3Group_stock.length - 1] || {}).month || ''
        this.stockAfterMonth = (step3Group_stock[0] || {}).month || ''
        this.stockMonth = this.$diffMonth(this.stockStartMonth, this.stockAfterMonth)

        this.sendProductStartMonth = (step3Group_sendProduct[step3Group_sendProduct.length - 1] || {}).month || ''
        this.sendProductAfterMonth = (step3Group_sendProduct[0] || {}).month || ''
        this.sendProductMonth = this.$diffMonth(this.sendProductStartMonth, this.sendProductAfterMonth)

        this.tableList_material = step3Group_material
        this.tableList_stock = step3Group_stock
        this.tableList_send_product = step3Group_sendProduct

        this.$nextTick(() => {
          this.initDataState = 'complete'
        })
      })
    },
    onChangeParams(e) {
      if (this.detail.id) {
        clearTimeout(this.onChangeParamsTimer)
        this.onChangeParamsTimer = setTimeout(() => {
          this.fncSettingOptions({
            params_tax_per: this.params_tax_per || 0,
            params_sold_per: this.params_sold_per || 0,
            params_cost_per: this.params_cost_per || 0,
            params_pass_per: this.params_pass_per || 0
          })
        }, 300)
      }
    },
    fncSettingOptions(params, returnData = false) {},
    handleShare() {
      message.error(`保存后才能进行分享`)
      this.handleSave(() => {
        this.$refs.savePlan.close()
        this.$nextTick(() => {
          this.$refs.shareLink.open()
        })
      })
    },
    handleBack() {
      this.$confirm({
        content: '是否保存方案',
        okText: '是',
        cancelText: '否',
        onOk: () => {
          this.handleSave(() => {
            this.$router.back()
          })
        },
        onCancel: () => {
          this.$router.back()
        }
      })
    },
    handleSave(callback) {
      this.$refs.savePlan.open(
        this.listQuery.id,
        {
          name: this.detail.name || '',
          remark_base: this.remark_base,
          params: {
            params_tax_per: this.params_tax_per,
            params_sold_per: this.params_sold_per,
            params_cost_per: this.params_cost_per,
            params_pass_per: this.params_pass_per,

            solutionAnalysis: this.$refs.solutionAnalysis.getSaveData(),

            solutionPie: this.$refs.solutionPie.getPageParams(),
            SolutionPlan: this.$refs.SolutionPlan.getPageParams(),
            barEchart: this.$refs.barEchart.getPageParams()
          }
        },
        () => {
          this.detail.is_save = 1
          if (callback) {
            callback()
          } else {
            this.$router.replace({ path: '/history' })
          }
        }
      )
    },

    async downPDF() {
      this.downLoading = true
      this.isPrint = true
      this.$refs.pageLoading.open('正在进行方案下载')
      //增加打印次数
      this.handlePrint()

      setTimeout(() => {
        this.$nextTick(async () => {
          this.$refs.pageLoading.text('方案构件中...')

          let elId = 'solutionTemplatePDF'
          let pdfName = '基础方案'
          if (this.planType == 'extra') {
            pdfName = '扩展方案'
            elId = 'solutionTemplatePDFExtra'
            await this.$refs.solutionTemplatePDFExtra.downPDF(this)
          } else {
            await this.$refs.solutionTemplatePDF.downPDF(this)
          }
          this.$refs.pageLoading.text('正在生成PDF文件')
          await htmlToPDF(elId, pdfName, 'PDF-foot')
          this.$refs.pageLoading.text('下载成功')
          setTimeout(() => {
            location.reload()
          }, 800)
        })
      }, 200)

      return
    },
    handlePrint() {
      this.$network('/api/tools/planPrintTimes', { id: this.listQuery.id, tool_type: 'two' })
    }
  }
}
</script>
