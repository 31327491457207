<template>
  <div class="pie_cycle_echart" :class="isMobile ? 'pie_cycle_echart_mobile' : 'pie_cycle_echart_pc'">
    <div class="col">
      <div class="text">{{ getPlanTypeName }}</div>
      <div :class="platform === 'tablet' ? 'tablet-pie' : 'pie'" :id="'echart-dataRange-' + planType"></div>
    </div>
    <div class="col">
      <a-select v-model:value="selectDataRange" :options="dateOptions" @change="onShowFieldChange('type', $event)"></a-select>
      <div :class="platform === 'tablet' ? 'tablet-pie' : 'pie'" :id="'echart-type-' + planType"></div>
      <!-- <div class="pie yang-pie"></div> -->
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { numberToFixed, checkZero, debounce, echartGraphic, getUsePlatform } from '@/utils/util'

const defaultEchartData = []

export default {
  name: 'SolutionPieCycle',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isPrint: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    planType: {
      type: String,
      default: ''
    },
    planTypeSpecial: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    },
    initDataState: {
      //数据初始化状态
      type: String,
      default: ''
    }
  },
  components: {},
  data() {
    return {
      bodyWidth: document.body.offsetWidth,
      allTotal: 0,
      echartData: [],
      groupData: {},
      typeData: {},
      tableData: [],
      showField: 'dataRange',
      selectDataRange: 'more360',
      dateOptions: [
        { label: '＜30天占比', value: 'date30' },
        { label: '＜60天占比', value: 'date60' },
        { label: '＜90天占比', value: 'date90' },
        { label: '＜180天占比', value: 'date180' },
        { label: '＜360天占比', value: 'date360' },
        { label: '>=360天占比', value: 'more360' }
      ],
      echartObj: () => {
        return {}
      },
      platform: ''
    }
  },
  computed: {
    getPlanTypeName() {
      let name = ''
      switch (this.planType) {
        case 'step3-material':
          name = '材料'
          break

        case 'step3-stock':
          name = '成品'
          break

        case 'step3-send-product':
          name = '发出商品'
          break
      }
      return name
    },
    getPlanTypeSubText() {
      let name = ''
      switch (this.showField) {
        case 'dataRange':
          // name = ''
          break
        case 'type':
          // name = '类型'
          break
      }
      return name
    },
    getSelectDataRangeObj() {
      let obj = {}
      for (let i = 0; i < this.dateOptions.length; i++) {
        const info = this.dateOptions[i]
        if (info.value === this.selectDataRange) {
          obj = info
          break
        }
      }
      return obj
    },
    getSelectDataRangeName() {
      return this.getSelectDataRangeObj.label || ''
    }
  },
  watch: {
    list: {
      immediate: true,
      handler(value) {
        this.tableData = value
        this.calDataType()
        this.calData()
        this.onShowFieldChange('type')
        this.onShowFieldChange('dataRange')
      }
    },

    planTypeSpecial(value) {
      if (value == 'base') {
        this.$nextTick(() => {
          setTimeout(() => {
            this.echartObj()['echarttype'].resize()
            this.echartObj()['echartdataRange'].resize()
          }, 200)
        })
      }
    }
  },
  mounted() {
    this.platform = getUsePlatform()
    // window.addEventListener('resize', this.handleResize)
    this.echartObj = () => {
      return {
        echartdataRange: echarts.init(document.getElementById('echart-dataRange-' + this.planType), null, {
          renderer: 'canvas',
          useDirtyRect: false
        }),
        echarttype: echarts.init(document.getElementById('echart-type-' + this.planType), null, {
          renderer: 'canvas',
          useDirtyRect: false
        })
      }
    }
  },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.handleResize)
  // },
  methods: {
    // handleResize() {
    //   this.bodyWidth = document.body.offsetWidth
    //   this.onShowFieldChange()
    // },
    setPageParams(field, value) {
      this[field] = value || 'more360'
      this.onShowFieldChange('type')
    },
    getPageParams(field) {
      return this[field]
    },
    onShowFieldChange(showField = 'dataRange') {
      let echartData = []
      const typeColorMap = {
        '0AT-01': '#fd0100',
        '0AT-02': '#fc7d02',
        '0AT-03': '#fbdb0f',
        default: '#1890ff'
      }
      if (showField === 'type') {
        const groupData = []

        Object.keys(this.typeData).map((type) => {
          const info = this.typeData[type] || {}
          const infoValue = this.selectDataRange ? info[this.selectDataRange] || {} : info
          const num = infoValue.num || 0
          const money = infoValue.money || 0
          const gruopItem = {
            name: type,
            value: money,
            num: num,
            money: money,
            itemStyle: {}
          }
          //
          if (money == 0) {
            gruopItem.itemStyle.color = '#cccccc'
          }
          groupData.push(gruopItem)
        })

        echartData = groupData
      } else {
        echartData = Object.values(this.groupData)
      }
      //绑定参数变化
      this.$emit('change')

      this.$nextTick(() => {
        this.drawEchart(showField, this.echartObj()['echart' + showField], echartData)
      })
    },
    calData() {
      const groupData = {
        date30: { name: '<30天', value: 0, money: 0, num: 0, itemStyle: { color: '#93ce07' } },
        date60: { name: '<60天', value: 0, money: 0, num: 0, itemStyle: { color: '#3ca272' } },
        date90: { name: '<90天', value: 0, money: 0, num: 0, itemStyle: { color: '#1890ff' } },
        date180: { name: '<180天', value: 0, money: 0, num: 0, itemStyle: { color: '#fbdb0f' } },
        date360: { name: '<360天', value: 0, money: 0, num: 0, itemStyle: { color: '#fc7d02' } },
        more360: { name: '>=360天', value: 0, money: 0, num: 0, itemStyle: { color: "'#fd0100'" } }
      }

      const showType = 'money'
      let total = 0
      this.tableData.map((item) => {
        let date30Field = 'date_30_num'
        let date60Field = 'date_60_num'
        let date90Field = 'date_90_num'
        let date180Field = 'date_180_num'
        let date360Field = 'date_360_num'
        let more360Field = 'date_360_more_num'
        if (showType == 'num') {
          //数量
        } else if (showType === 'money') {
          //金额

          date30Field = 'date_30_money'
          date60Field = 'date_60_money'
          date90Field = 'date_90_money'
          date180Field = 'date_180_money'
          date360Field = 'date_360_money'
          more360Field = 'date_360_more_money'
        }

        total += item[date30Field] * 1 || 0
        total += item[date60Field] * 1 || 0
        total += item[date90Field] * 1 || 0
        total += item[date180Field] * 1 || 0
        total += item[date360Field] * 1 || 0
        total += item[more360Field] * 1 || 0

        const num_30 = item.date_30_num * 1 || 0
        const money_30 = item.date_30_money * 1 || 0

        const num_60 = item.date_60_num * 1 || 0
        const money_60 = item.date_60_money * 1 || 0

        const num_90 = item.date_90_num * 1 || 0
        const money_90 = item.date_90_money * 1 || 0

        const num_180 = item.date_180_num * 1 || 0
        const money_180 = item.date_180_money * 1 || 0

        const num_360 = item.date_360_num * 1 || 0
        const money_360 = item.date_360_money * 1 || 0

        const num_more_360 = item.date_360_more_num * 1 || 0
        const money_more_360 = item.date_360_more_money * 1 || 0

        groupData.date30.value += item[date30Field] * 1 || 0
        groupData.date30.num += num_30
        groupData.date30.money += money_30

        groupData.date60.value += item[date60Field] * 1 || 0
        groupData.date60.num += num_60
        groupData.date60.money += money_60

        groupData.date90.value += item[date90Field] * 1 || 0
        groupData.date90.num += num_90
        groupData.date90.money += money_90

        groupData.date180.value += item[date180Field] * 1 || 0
        groupData.date180.num += num_180
        groupData.date180.money += money_180

        groupData.date360.value += item[date360Field] * 1 || 0
        groupData.date360.num += num_360
        groupData.date360.money += money_360

        groupData.more360.value += item[more360Field] * 1 || 0
        groupData.more360.num += num_more_360
        groupData.more360.money += money_more_360
      })

      this.allTotal = total
      this.groupData = groupData
    },
    calDataType() {
      const typeData = {}
      this.tableData.map((item) => {
        const type = item.type || ''
        if (!(type in typeData)) {
          typeData[type] = {
            date30: { num: 0, money: 0 },
            date60: { num: 0, money: 0 },
            date90: { num: 0, money: 0 },
            date180: { num: 0, money: 0 },
            date360: { num: 0, money: 0 },
            more360: { num: 0, money: 0 },

            num: 0,
            money: 0
          }
        }

        const num_30 = item.date_30_num * 1 || 0
        const money_30 = item.date_30_money * 1 || 0

        const num_60 = item.date_60_num * 1 || 0
        const money_60 = item.date_60_money * 1 || 0

        const num_90 = item.date_90_num * 1 || 0
        const money_90 = item.date_90_money * 1 || 0

        const num_180 = item.date_180_num * 1 || 0
        const money_180 = item.date_180_money * 1 || 0

        const num_360 = item.date_360_num * 1 || 0
        const money_360 = item.date_360_money * 1 || 0

        const num_more_360 = item.date_360_more_num * 1 || 0
        const money_more_360 = item.date_360_more_money * 1 || 0

        typeData[type].num += num_30 * 1 + num_60 * 1 + num_90 * 1 + num_180 * 1 + num_360 * 1 + num_more_360 * 1
        typeData[type].money += money_30 * 1 + money_60 * 1 + money_90 * 1 + money_180 * 1 + money_360 * 1 + money_more_360 * 1

        typeData[type].date30.num += num_30
        typeData[type].date30.money += money_30

        typeData[type].date60.num += num_60
        typeData[type].date60.money += money_60

        typeData[type].date90.num += num_90
        typeData[type].date90.money += money_90

        typeData[type].date180.num += num_180
        typeData[type].date180.money += money_180

        typeData[type].date360.num += num_360
        typeData[type].date360.money += money_360

        typeData[type].more360.num += num_more_360
        typeData[type].more360.money += money_more_360
      })
      this.typeData = typeData
    },
    drawEchart(type, echartObj, echartData) {
      const labelFormater = (params, dot = 0) => {
        const data = params.data || {}
        const value = params.value
        const money = data.money || '0.00'
        const per = !this.allTotal ? 0 : (value / this.allTotal) * 100
        return params.name + ' (' + per.toFixed(dot) + '%)'
      }
      // const pieRadius
      // if(this.platform === 'tablet')
      const option = {
        title: {
          // text: type=='type'?'类型':'',
          // subtext: this.getPlanTypeSubText,
          left: 'center'
        },
        color: ['#7BD5CD', '#62B1ED', '#76D890', '#F6C97D', '#F69487', '#0099ff'],
        tooltip: this.isMobile
          ? {
              trigger: 'item',
              position: [10, 10], // 相对于容器右侧和底部的偏移量
              formatter: (params, ticket, callback) => {
                const data = params.data || {}
                return `<div>
                        ${params.name}:<br/>
                            数量：${numberToFixed(data.num, 2)}<br/>
                            金额：${numberToFixed(data.money, 2)}<br/>
                        </div>`
              }
            }
          : {
              trigger: 'item',
              formatter: (params, ticket, callback) => {
                const data = params.data || {}
                return `<div>
                        ${params.name}:<br/>
                            数量：${numberToFixed(data.num, 2)}<br/>
                            金额：${numberToFixed(data.money, 2)}<br/>
                        </div>`
              }
            },
        // legend: {
        //     orient: 'vertical',
        //     left: 'left'
        // },
        series: [
          {
            name: this.getPlanTypeName,
            left: -18,
            type: 'pie',
            // radius: '35%',
            radius: this.platform === 'tablet' ? '50%' : this.bodyWidth < 1441 ? '35%' : this.bodyWidth > 1441 && this.bodyWidth < 1919 ? '45%' : '55%',
            data: echartData,
            label: {
              //内文字
              // position: 'inner',
              fontSize: 10
            },
            labelLine: {
              normal: {
                show: true,
                length: 15, // 线条长度
                length2: 5, // 线条长度2，从扇形边缘到文字的距离
                smooth: 0.2 // 线条平滑度
                // 其他 labelLine 配置
              }
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      // if(type==='dataRange'){
      //   option.series[0].itemStyle={
      //     normal:{
      //       color:function(colors){
      //         console.log(colors)
      //         const colorList = ['#93CE07','#3CA272','#1890FF','#FBDB0F', '#FC7D02','#FD0100'];
      //         return colorList[colors.dataIndex]||colors.color;
      //       }
      //     }
      //   }
      // }else{
      //   option.series[0].itemStyle={
      //     normal:{
      //       color:function(colors){
      //         return colors.color;
      //       }
      //     }
      //   }
      // }
      // 释放资源
      echartObj.setOption(option)
    },
    getPrintInfo() {
      return {
        name: this.getPlanTypeName,
        selectName: this.getSelectDataRangeName,
        echartdataRange: this.echartObj().echartdataRange.getDataURL('png'),
        echarttype: this.echartObj().echarttype.getDataURL('png')
      }
    }
  }
}
</script>

<style lang="less" >
.pie_cycle_echart {
  .text {
    height: 14px;
    line-height: 14px;
    font-size: 12px;
  }
  &.pie_cycle_echart_mobile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    .ant-select {
      .ant-select-selector {
        height: 24px !important;
        .ant-select-selection-item {
          line-height: 22px !important;
          font-size: 12px !important;
        }
      }
    }
    .col {
      height: 100%;
      width: 100%;
      .text {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
      }
      .pie {
        // min-height: 55px;
        height: calc((100vh - 380px - 22px - 12px - 10px - 40px) / 3 - 24px);
        width: 100%;
      }
      .tablet-pie {
        height: calc((100vh / 2 - 20px) / 3 - 24px);
        // height: calc((100vh - (100vh / 2 - 70px - 20px) - 20px) / 3 - 24px);
        width: 100%;
      }
    }
  }
  .yang-pie {
    width: 100px !important;
    height: 100px !important;
    border: 1px solid #e0e6f1;
    border-radius: 50%;
    &::before {
      display: flex;
      align-items: center;
      content: '无数据';
      font-size: 10px;
      text-align: center;
    }
  }
}
</style>
