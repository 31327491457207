<template>
  <a-modal :title="title" :width="640" v-model:open="visible" :confirmLoading="loading" @ok="handleSubmit" @cancel="close">
    <a-form ref="formAuth" :model="form" layout="vertical">
      <a-form-item label="方案名称">
        <a-input v-model:value.trim="form.name"></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { message } from 'ant-design-vue'
export default {
  name: 'SavePlanCompare',
  props: {
    id: {
      type: [String, Number],
      default: 0
    },
    title: {
      type: String,
      default: '对比方案保存'
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        id: 0,
        name: '',
        is_save: 1
      },
      callback: null
    }
  },
  methods: {
    open(params, callback) {
      this.form.name = params.name + '(基准)' + ' VS ' + params.compareName || ''
      this.form.remark = params.remark || ''
      this.form.plan1 = params.plan1 || ''
      this.form.plan2 = params.plan2 || ''
      this.form.plan_extra = params.plan_extra
      this.form.tool_type = params.tool_type || 'one'

      this.visible = true
      this.callback = callback
    },
    close() {
      this.visible = false
    },
    handleSubmit() {
      if (!this.form.name) {
        message.error('请输入名称')
        return
      }
      this.$network('/api/tools/planCompareSave', this.form).then((res) => {
        message.success('保存成功')
        setTimeout(() => {
          this.callback()
        }, 800)
      })
    }
  }
}
</script>

<style>
</style>