<template></template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

import {
  numberToFixed,
  roundToDotNumber,
  checkZero,
  tableDefautlLine,
  tableSortFnc,
  tableDefaultPage,
  fnc_excelData_yc_line,
  fnc_excelData_yc_before,
  fnc_excelData_yc_after,
  dealThousands
} from '@/utils/util'
import { mapGetters } from 'vuex'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'

import SliderInputBlock from '@/components/SliderInputBlock.vue'
import SvpLine from './SvpLine.vue'
import SvpScatter from './SvpScatter.vue'
import calMixIn from '../calMixIn.vue'

const columns = [
  // { width: 90, title: '项目', dataIndex: 'type_name', ellipsis: true, align: 'left', fixed: 'left' },
  // { width: 90, title: '销量', dataIndex: 'sold_num', align: 'right', ...tableSortFnc('sold_num') },
  // { width: 90, title: '收入', dataIndex: 'price_come_in', align: 'right', ...tableSortFnc('price_come_in') },
  // { width: 90, title: '变动成本', dataIndex: 'material_cost_money', align: 'right', ...tableSortFnc('material_cost_money') },
  // { width: 90, title: '边际收益', dataIndex: 'slid_money', align: 'right', ...tableSortFnc('slid_money') }
  // { title: '纬度', dataIndex: 'name', align: 'center', resizable: true, ellipsis: true },
  { width: 160, title: '项', dataIndex: 'type_name', align: 'left', resizable: true, ellipsis: true },
  { title: '销量', dataIndex: 'sold_num', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('sold_num') },
  { title: '收入', dataIndex: 'price_come_in', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('price_come_in') },
  { title: '变动成本', dataIndex: 'material_cost_money', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('material_cost_money') },
  { title: '边际收益', dataIndex: 'slid_money', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('slid_money') }
]

export default {
  components: {
    SliderInputBlock,
    SvpLine,
    SvpScatter
  },
  data() {
    return {
      pagination: tableDefaultPage(),

      planType: 0,

      listQuery: {
        id: ''
      },
      listQuery2: {
        id: '',
        id1: '',
        type: ''
      },
      //方案详情
      detail: {},

      xAxisNum: 1,
      //整体加成参数设置
      params_tax_per: 0,
      params_sold_per: 0,
      params_cost_per: 0,
      params_pass_per: 0,

      pageDataInitState: false,

      submitLoading: false,

      //备注-基础
      remark_base: '',
      remark_base_mobile: '',
      //备注-扩展
      remark_extra: '',
      remark_extra_mobile: '',

      columns: columns,

      excelDataChange: [],
      tableData_step_fixed: [],
      tableData_step_mix: [],
      columns_step_mix: [],

      scatterTable: [],
      pageParams: {},
      scatterType: 'customer',
      topSumObj: {},

      mobileParams: {},
      initSettingState: false
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getWaterName() {
      return this.detail.water_name || ''
    },
    getMoneyUnit() {
      let moneyRange = [1, '元']
      if (this.getSumPriceComeIn * 1 > 100000) {
        moneyRange = [10000, '万元']
      }
      return moneyRange
    },
    getNumberFnc() {
      const w_1 = 10000
      const w_10 = 100000
      const div = this.getMoneyUnit[0]
      const soldUnit = this.getMoneyUnit[1]
      return (number, decimal = 2, bindUnit = false) => {
        let unit = soldUnit
        let str = dealThousands((number / div).toFixed(decimal), decimal)
        if (bindUnit) {
          //处理销量问题
          str = dealThousands((number / 1).toFixed(decimal), decimal)
          unit = ''
          if (number * 1 > w_10) {
            unit = '万'
            str = dealThousands((number / w_1).toFixed(decimal), decimal)
          }
        }
        if (bindUnit) {
          str += unit
        }
        return str
      }
    },
    excelData_ycOrigin() {
      const lineChangeData = fnc_excelData_yc_line(this.excelDataChange, {
        params_sold_per: this.params_sold_per * 1,
        params_pass_per: this.params_pass_per * 1,
        params_tax_per: this.params_tax_per * 1,
        params_cost_per: this.params_cost_per * 1
      })
      return lineChangeData
    },
    excelData_yc() {
      // return fnc_excelData_yc_after(this.excelData_ycOrigin, {})
      return fnc_excelData_yc_before(this.excelData_ycOrigin, {})
    },
    getCalData() {
      return this.handleData(this.excelData_yc, this.tableData_step_fixed, this.tableData_step_mix, this.columns_step_mix, {})
    },
    //总销量
    getSumSoldNum() {
      return this.getCalData.sum_sold_num
    },
    //总收入
    getSumPriceComeIn() {
      return this.getCalData.sum_price_come_in
    },
    //毛利
    getMaoli() {
      return this.getCalData.maoli
    },
    //总成本
    getSumMaterialCostMoney() {
      return this.getCalData.sum_material_cost_money
    },
    //毛利率
    getMaoliPer() {
      return checkZero(this.getSumPriceComeIn) ? '0.00%' : roundToDotNumber((this.getMaoli / this.getSumPriceComeIn) * 100) + '%'
    },
    //变动成本
    getSumMaterialCost() {
      return this.getCalData.sum_material_cost
    },
    //管理利润
    getManagerRate() {
      return this.getCalData.manager_rate
    },
    //边际贡献
    getSumSideMoney() {
      return this.getCalData.sum_side_money
    },
    //管理利润率
    getManagerRatePer() {
      return checkZero(this.getSumPriceComeIn) ? '0.00%' : roundToDotNumber((this.getManagerRate / this.getSumPriceComeIn) * 100) + '%'
    },
    //固定成本
    getSumFixedTotalMoney() {
      return this.getCalData.sum_fixed_total_money
    },
    //混合成本
    getSumMixTotalMoney() {
      return this.getCalData.sum_mix_total_money
    },

    //盈亏平衡销量
    getComeInpointSoldNum() {
      const obj = this.getCalData
      //合格率
      const productPer = checkZero(obj.sum_pass_sold_num) ? 0 : obj.sum_sold_num / obj.sum_pass_sold_num
      // console.log('合格率：', productPer);

      //不含税单价
      const noTaxPrice = checkZero(obj.sum_sold_num) ? 0 : obj.sum_price_come_in / obj.sum_sold_num
      // console.log('不含税单价:', noTaxPrice);

      //成本单价
      const costMoney = checkZero(obj.sum_pass_sold_num) ? 0 : obj.sum_material_cost_money / obj.sum_pass_sold_num
      // console.log('成本单价:', costMoney);

      //固定费用
      const fixMoney = obj.sum_fixed_total_money

      const valuePer = checkZero(productPer) ? 0 : costMoney / productPer
      // const soldNum = tableData_step_fixed *1 / ( noTaxPrice - valuePer );

      //销量*不含税单价 = 销量/合格率 * 成本单价 + 固定 + 对应销量混合范围的金额
      const soldNumFnc = (disNum) => {
        return checkZero(noTaxPrice - valuePer) ? 0 : (disNum * 1) / (noTaxPrice - valuePer)
      }

      //统计收入信息
      const staticsIn = (soldNum) => {
        //产量
        const productNum = checkZero(productPer) ? 0 : soldNum / (productPer / 100)

        return {
          //销量
          soldNum: roundToDotNumber(soldNum),
          //收入
          comeIn: roundToDotNumber(noTaxPrice * soldNum),
          //成本
          materialCost: roundToDotNumber(costMoney * productNum + fixMoney * 1 + this.tableData_step_mix_field('total', soldNum, this.tableData_step_mix, this.columns_step_mix) * 1),
          //管理利润
          profitManager: roundToDotNumber(
            noTaxPrice * soldNum -
              (costMoney * productNum +
                this.tableData_step_fixed_field('zhizao_sum', this.tableData_step_fixed) * 1 +
                this.tableData_step_mix_field('zhizao_sum', soldNum, this.tableData_step_mix, this.columns_step_mix) * 1)
          )
        }
      }
      var tableDataMixMoney = this.tableData_step_mix ? this.tableData_step_mix[this.tableData_step_mix.length - 1] || {} : {}
      const values = []
      let soldNum = 0
      if (this.columns_step_mix && this.columns_step_mix.length > 0) {
        this.columns_step_mix.map(function (item, index) {
          if (index > 0) {
            const range_min = item.range_min || 0
            const range_max = item.range_max || 0

            const mixMoney = tableDataMixMoney[item.dataIndex]
            soldNum = soldNumFnc(mixMoney * 1 + fixMoney * 1)
            // console.log(mixMoney*1,fixMoney*1)
            // console.log("--------------------")
            // console.log("销量区间范围:",range_min,range_max)
            // console.log("盈亏平衡点销量:",soldNum)
            // console.log(range_min,range_max,soldNum,mixMoney,item.dataIndex)
            if (soldNum >= range_min && soldNum <= range_max) {
              values.push(staticsIn(soldNum))
            }
          }
        })
      } else {
        soldNum = soldNumFnc(fixMoney * 1)
        if (soldNum > 0) {
          values.push(staticsIn(soldNum))
        }
      }
      return values
    },
    getScatterTableInfo() {
      let scatterInfo = {}
      for (let i = 0; i < this.scatterTable.length; i++) {
        const info = this.scatterTable[i]
        if (info.key === this.scatterType) {
          scatterInfo = info
          break
        }
      }
      return scatterInfo
    },
    getScatterTableData() {
      return this.getScatterTableInfo.data || []
    }
  },
  watch: {
    // excelDataChange(newVal, oldVal) {
    // }
  },
  created() {
    this.listQuery.id = this.$route.query.id || 0
    this.listQuery.id1 = this.$route.query.id1 || 0
    this.listQuery.type = this.$route.query.type || ''
  },
  mixins: [calMixIn],
  mounted() {
    //项目初始化主題色
    // let themeColor = (ls.get(SITE_SETTINGS) || {}).THEME_COLOR
    // if (themeColor) {
    // systemConfig.commit(THEME_COLOR, (ls.get(SITE_SETTINGS) || {}).THEME_COLOR)
    // }
    let themeColor = ''
    if (this.$route.query.themeColor && this.$route.query.themeColor !== '') {
      themeColor = '#' + this.$route.query.themeColor
      systemConfig.commit(THEME_COLOR, themeColor)
    }
    this.$nextTick(() => {
      this.getPlanDetail()
    })
    this.mySwiper = new Swiper('.swiper-container', {
      // noSwiping: true,
      // pagination: {
      //   el: '.swiper-pagination',
      //   clickable: true
      // },
      // observer: true,
      // observerOarebts: true,
      on: {
        slideChangeTransitionEnd: (e) => {
          // console.log(e)
          // setTimeout(()=>{
          this.planType = this.mySwiper.activeIndex
          // },100)
        }
      }
    })
  },
  beforeDestroy() {
    if (this.mySwiper) {
      this.mySwiper.destroy()
      this.mySwiper = null
    }
  },
  methods: {
    onParamsSettingChange(field, set = true) {
      if (!this.initSettingState) {
        return
      }

      if (set) {
        this.$store.state.app.cacheMobileParams[field] = this[field]
      } else {
        return this.$store.state.app.cacheMobileParams[field]
      }
    },

    initParamsSetting() {
      //初始化配置参数

      this.initSettingState = true
      let pageParams = this.mobileParams || {}
      if (!pageParams || !('params_tax_per' in pageParams)) {
        pageParams = this.pageParams || {}
      }

      let params_tax_per = pageParams.params_tax_per || 0
      let params_sold_per = pageParams.params_sold_per || 0
      let params_cost_per = pageParams.params_cost_per || 0
      let params_pass_per = pageParams.params_pass_per || 0

      const cache_params_tax_per = this.onParamsSettingChange('params_tax_per', false)
      if (cache_params_tax_per != null) {
        params_tax_per = cache_params_tax_per
      }

      const cache_params_sold_per = this.onParamsSettingChange('params_sold_per', false)
      // console.log("params_sold_per:"+params_sold_per,this.$store.state.app.cacheMobileParams)
      if (cache_params_sold_per != null) {
        params_sold_per = cache_params_sold_per
      }

      const cache_params_cost_per = this.onParamsSettingChange('params_cost_per', false)
      if (cache_params_cost_per != null) {
        params_cost_per = cache_params_cost_per
      }

      const cache_params_pass_per = this.onParamsSettingChange('params_pass_per', false)
      if (cache_params_pass_per != null) {
        params_pass_per = cache_params_pass_per
      }

      this.params_tax_per = params_tax_per || 0
      this.params_sold_per = params_sold_per || 0
      this.params_cost_per = params_cost_per || 0
      this.params_pass_per = params_pass_per || 0
    },
    changeSettingParams() {
      this.$network('/api/tools/reulstUpdateMobileParams', {
        id: this.listQuery.id,
        mobile_params: {
          params_tax_per: this.params_tax_per,
          params_sold_per: this.params_sold_per,
          params_cost_per: this.params_cost_per,
          params_pass_per: this.params_pass_per
        }
      })
        .then((res) => {
          this.$message.success('已更新')
        })
        .catch((err) => {})
    },
    onScatterTypeChange(e) {
      var obj = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
      var selected = {}
      for (let key in obj) {
        selected[obj[key]] = this.scatterType == key
      }
      this.$refs.svpCatterAfter.setEchart({
        legend: { selected: selected }
      })
    },
    showMoreTableList(item) {
      const selectKey = item.key || ''
      // const selectBlock = this.topSumObj[selectKey]||{}

      if (this.topSumObj[selectKey].pagination === false) {
        this.topSumObj[selectKey].pagination = tableDefaultPage()
        this.calOtherSum(item, this.topSumObj[selectKey].upSortData, false)
      } else {
        this.topSumObj[selectKey].pagination = false
      }
    },
    //计算其它加个
    calOtherSum(item, currentDataSource, recordUpData = true) {
      const selectKey = item.key || ''
      const selectBlock = this.topSumObj[selectKey] || {}
      const totalObj = {}
      for (let key in selectBlock) {
        if (key == 'upSortData') continue

        const pageSize = this.pagination.pageSize || 0
        const field = key.replace('sum_', '')
        const topSumObj_field = key
        totalObj[topSumObj_field] = 0
        let total = 0
        for (let i = 0; i < currentDataSource.length; i++) {
          const info = currentDataSource[i]

          if (i >= tableDefautlLine) {
            break
          }
          totalObj[topSumObj_field] = totalObj[topSumObj_field] + info[field] * 1
        }
        totalObj[topSumObj_field] = totalObj[topSumObj_field]
      }

      if (recordUpData) {
        totalObj.upSortData = currentDataSource
      } else {
        totalObj.upSortData = selectBlock.upSortData
      }
      totalObj.pagination = tableDefaultPage()
      this.topSumObj[selectKey] = totalObj
    },
    onTableChange(item, pagination, filters, sorter, { currentDataSource, action }) {
      // console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
      if (action == 'sort') {
        return this.calOtherSum(item, currentDataSource)
      }
    },
    handleParams(type) {
      if (type == 'zero') {
        this.params_tax_per = 0
        this.params_sold_per = 0
        this.params_cost_per = 0
        this.params_pass_per = 0
      } else {
        this.params_tax_per = this.pageParams.params_tax_per || 0
        this.params_sold_per = this.pageParams.params_sold_per || 0
        this.params_cost_per = this.pageParams.params_cost_per || 0
        this.params_pass_per = this.pageParams.params_pass_per || 0
      }
    },
    onScatterData({ tableDataList, topSumObj }) {
      this.scatterTable = tableDataList
      this.topSumObj = topSumObj
      // console.log('>>>>>>>>>> onScatterData <<<<<<<<<<')
      // console.log(tableDataList)
      this.$nextTick(() => {
        tableDataList.map((item) => {
          this.calOtherSum(item, item.data)
        })
      })
    },
    //方案详情
    getPlanDetail() {
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const vip_type = data.vip_type || ''
          //vip类型
          this.$store.commit('updateVipType', { vip_type: vip_type })

          const pageParams = detail.page_params || {}
          this.mobileParams = detail.mobile_params || {}
          this.pageParams = pageParams

          this.detail = detail
          this.remark_base = detail.remark_base || ''
          this.remark_base_mobile = detail.remark_base_mobile || ''
          this.remark_extra = detail.remark_extra || ''
          this.remark_extra_mobile = detail.remark_extra_mobile || ''
          this.$nextTick(() => {
            // this.params_tax_per = pageParams.params_tax_per || 0
            // this.params_sold_per = pageParams.params_sold_per || 0
            // this.params_cost_per = pageParams.params_cost_per || 0
            // this.params_pass_per = pageParams.params_pass_per || 0

            //初始化配置参数
            this.initParamsSetting()

            this.$nextTick(() => {
              this.getData(pageParams.solutionBaseTable || {})
            })
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // getPlanCompareDetail() {
    //   this.$network('/api/tools/planCompare', { id: this.listQuery.id, compare_id: this.listQuery.id1 })
    //     .then((res) => {
    //       const data = res.data || {}
    //       const detail = data.detail || {}
    //       const detailCompare = data.detail_compare || {}
    //       console.log('======================detail', detail)
    //       console.log('======================detailCompare', detailCompare)

    //       this.detail = detail
    //       this.detailCompare = detailCompare
    //     })
    //     .catch((err) => {
    //       console.error(err)
    //     })
    // },
    //处理数据
    getData(noPer = {}) {
      Promise.all([
        //销售预测表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step-yc' }),
        //固定
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step-fixed' }),
        //混合
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step-mix' })
      ]).then((res) => {
        const [
          {
            data: { list: stepYC_data }
          },
          {
            data: { list: stepFixed_data }
          },
          {
            data: { list: stepMix_data, columns: columns_step_mix }
          }
        ] = res
        const excelDataChange = []
        stepYC_data.map(function (item) {
          const no = item.no || ''
          const noPerInfo = noPer[no] || {}
          item.params_line_tax_per = noPerInfo.params_line_tax_per || 0
          item.params_line_sold_per = noPerInfo.params_line_sold_per || 0
          item.params_line_cost_per = noPerInfo.params_line_cost_per || 0
          item.params_line_pass_per = noPerInfo.params_line_pass_per || 0
          const afterItem = { ...item }
          for (let key in item) {
            afterItem['__' + key] = item[key]
          }
          excelDataChange.push(afterItem)
        })
        this.excelDataChange = excelDataChange
        this.tableData_step_fixed = stepFixed_data
        this.tableData_step_mix = stepMix_data
        this.columns_step_mix = columns_step_mix
      })
    },

    onTableChange(item, pagination, filters, sorter, { currentDataSource, action }) {
      // console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
      if (action == 'sort') {
        return this.calOtherSum(item, currentDataSource)
      }
    },
    handleSubmit() {
      const obj = { id: this.detail.id }
      if (this.planType) {
        obj.remark_extra_mobile = this.remark_extra_mobile
      } else {
        obj.remark_base_mobile = this.remark_base_mobile
      }
      this.submitLoading = true
      this.$network('/api/tools/planHistoryEdit', obj)
        .then((res) => {
          this.$message.success('保存成功')
          this.submitLoading = false
        })
        .catch(() => {
          this.submitLoading = false
        })
    }
  }
}
</script>

<style>
</style>