<script>
import { message } from 'ant-design-vue'
import { numberToFixed, debounce, fnc_excelData_yc_line, fnc_excelData_yc_after, fnc_excelData_yc_before, checkZero, tableSortFnc, roundToDotNumber, tableDefaultPage } from '@/utils/util'

import { mapGetters } from 'vuex'
import CVPCalMixIn from '../../cloud/calMixIn.vue'
import ToolStepCal from '../../components/toolStepCal.js'
import EchartEnergy from './EchartEnergy.vue'
import EchartEnergyMobile from './EchartEnergyMobile.vue'
export default {
  components: {
    EchartEnergy,
    EchartEnergyMobile
  },
  data() {
    return {
      listQuery: {
        id: ''
      },
      historyPath: '',
      detail: {}, //工具
      planDetail: {}, //用的某个跳转过来的方案
      hasSettingParams: false,

      params_cost_per: 0,
      params_pass_per: 0,
      params_sold_per: 0,
      params_tax_per: 0,

      noPer: {},
      tableData: [],
      excelDataChange: [],

      screenWidth: '',
      screenHeight: ''
    }
  },
  mixins: [ToolStepCal],
  created() {
    this.listQuery.id = this.$route.query.id || 0
    this.historyPath = this.$route.query.mobileNum || ''

    this.getData()
    this.handleResize()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },

  computed: {
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    getTitle() {
      return ''
    }
  },
  watch: {
    excelDataChange: {
      deep: true,
      handler: function (value) {
        if (!value || !value.length) {
          return
        }
        let lineChangeData = fnc_excelData_yc_line(value, {
          params_sold_per: this.params_sold_per,
          params_pass_per: this.params_pass_per,
          params_tax_per: this.params_tax_per,
          params_cost_per: this.params_cost_per
        })
        if (this.planDetail.tool_type === 'five') {
          //调整价格--重新计算一次
          lineChangeData = fnc_excelData_yc_line(this.handleCalData(lineChangeData), {})
        }

        this.tableData = fnc_excelData_yc_before(lineChangeData, {})
        // console.log(this.tableData)
      }
    }
  },
  methods: {
    handleResize() {
      // 根据窗口修改元素的大小
      this.screenWidth = window.innerWidth - 20
      this.screenHeight = window.innerHeight * 0.9
    },
    getData() {
      this.$network('/api/tools/typeDetail', { type: 'eight' }).then((res) => {
        const data = res.data || {}
        this.detail = data.detail || {}
        this.$nextTick(() => {
          this.getPlanDetail(this.listQuery.id)
        })
      })
    },
    getPlanDetail(id) {
      this.$network('/api/tools/planDetail', { id: id })
        .then((res) => {
          console.log(res)
          const data = res.data || {}
          const detail = data.detail || {}
          const pageParams = detail.page_params || {}

          if (!this.planDetail.id) {
            this.planDetail = detail
          }

          switch (detail.tool_type) {
            case 'one':
              if (!this.hasSettingParams) {
                this.params_tax_per = pageParams.params_tax_per || 0
                this.params_sold_per = pageParams.params_sold_per || 0
                this.params_cost_per = pageParams.params_cost_per || 0
                this.params_pass_per = pageParams.params_pass_per || 0
                this.noPer = pageParams.solutionBaseTable || {}
                this.hasSettingParams = true
              }
              this.getYcData(id)
              break
            case 'five':
              //方案基础类型
              this.$store.commit('updateToolsState', { field: 'plan_base', value: detail.base || '' })
              if (!this.hasSettingParams) {
                this.params_tax_per = pageParams.params_tax_per || 0
                this.params_sold_per = pageParams.params_sold_per || 0
                this.params_cost_per = pageParams.params_cost_per || 0
                this.params_pass_per = pageParams.params_pass_per || 0
                this.noPer = pageParams.solutionBaseTable || {}
                this.hasSettingParams = true
              }
              // this.getPlanDetail(detail.step5_cvp)
              this.getLadderDetail(id, detail.step5_cvp)
              break
            case 'six':
              if (!this.hasSettingParams) {
                this.params_tax_per = pageParams.params_tax_per || 0
                this.params_sold_per = pageParams.params_sold_per || 0
                this.params_cost_per = pageParams.params_cost_per || 0
                this.params_pass_per = pageParams.params_pass_per || 0
                this.noPer = pageParams.solutionBaseTable || {}
                this.hasSettingParams = true
              }
              this.getPlanDetail(detail.step6_cvp)
              break
          }
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getYcData(planId) {
      const noPer = this.noPer || {}
      // console.log(noPer)
      // console.log('-params_tax_per-',this.params_tax_per)
      // console.log('-params_sold_per-',this.params_sold_per)
      // console.log('-params_cost_per-',this.params_cost_per)
      // console.log('-params_pass_per-',this.params_pass_per)

      return this.$network('/api/tools/planResultData', {
        id: planId,
        type: 'step-yc'
      })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []
          const excelData = []
          const excelDataChange = []
          list.map(function (item) {
            const no = item.no || ''
            const noPerInfo = noPer[no] || {}
            //保留基础的合格率
            item.__pass_per = item.pass_per || 0
            item.params_line_tax_per = noPerInfo.params_line_tax_per || 0
            item.params_line_sold_per = noPerInfo.params_line_sold_per || 0
            item.params_line_cost_per = noPerInfo.params_line_cost_per || 0
            item.params_line_pass_per = noPerInfo.params_line_pass_per || 0
            excelData.push({ ...item })
            const afterItem = { ...item }
            for (let key in item) {
              afterItem['__' + key] = item[key]
            }
            excelDataChange.push(afterItem)
          })

          this.excelDataChange = excelDataChange
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getLadderDetail(id, planId) {
      //阶梯价格
      this.$network('/api/tools/planResultData', { id: id, type: 'step5-ladder' }).then((res) => {
        const data = res.data || {}
        const list = data.list || []

        // this.ladderTableList = list;
        this.$store.commit('updateToolsState', { field: 'ycPrice', value: this.$priceLadderSort(list) })

        this.$nextTick(() => {
          this.getPlanDetail(planId).finally(() => {})
        })
      })
    },
    handleBack() {
      this.$router.back()
      return
      this.$confirm({
        content: '是否保存方案',
        okText: '是',
        cancelText: '否',
        onOk: () => {
          this.handleSave(() => {
            this.$router.back()
          })
        },
        onCancel: () => {
          this.$router.back()
        }
      })
    }
  }
}
</script>
