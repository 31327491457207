<script>
import mixinFnc from '../mixin-fnc.js'
import { message } from 'ant-design-vue'
import { numberToFixed, debounce, fnc_excelData_yc_after, checkZero } from '@/utils/util'
import { mapGetters } from 'vuex'

import SliderInputBlock from '@/components/SliderInputBlock.vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import SolutionTable from './SolutionTable.vue'
import SolutionPie from './SolutionPie.vue'
import SolutionPlan from './SolutionPlan.vue'
import SolutionAnalysis from './SolutionAnalysis.vue'
import BarEchart from './BarEchart.vue'

import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
import { systemConfig } from '@/store/reactiveState'

import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

export default {
  mixins: [mixinFnc],
  components: {
    SliderInputBlock,
    ToolsItemIntro,
    SolutionTable,
    SolutionPie,
    SolutionPlan,
    SolutionAnalysis,
    BarEchart
  },
  data() {
    return {
      params_tax_per: 0,
      params_sold_per: 0,
      params_cost_per: 0,
      params_pass_per: 0,
      initDataState: '',

      downLoading: false,
      // planType:'base',
      planType: 0,
      detail: {},
      listQuery: {},

      tableList: [],

      tableList_material_origin: [], //原始数据
      tableList_material: [],
      materialStartMonth: '', //开始月份
      materialAfterMonth: '', //结束月份
      materialMonth: 0, //总共有多少个月份

      tableList_stock_origin: [], //原始数据
      tableList_stock: [],
      stockStartMonth: '',
      stockAfterMonth: '',
      stockMonth: 0, //总共有多少个月份

      tableList_send_product_origin: [], //原始数据
      tableList_send_product: [],
      sendProductStartMonth: '',
      sendProductAfterMonth: '',
      sendProductMonth: 0, //总共有多少个月份

      onChangeParamsTimer: 0,
      hasActualityData: true,
      isPrint: false,

      remark_base: '',
      remark_extra: '',
      remark_base_time: '',
      remark_base_mobile: '',
      remark_extra_mobile: '',
      remark_extra_time: '',

      lastMonth: '',

      //使用的方案
      usePlan: [],

      lastMonth: '',
      solutionAnalysisData: {},

      submitLoading: false,
      pageParams: {},
      mobileParams: {},
      initSettingState: false
    }
  },
  created() {
    this.listQuery.id = this.$route.query.id || 0
    // this.planType = this.$route.query.planType == 'extra' ? 'extra' : 'base'

    this.getPlanDetail()
  },
  mounted() {
    let themeColor = ''
    if (this.$route.query.themeColor && this.$route.query.themeColor !== '') {
      themeColor = '#' + this.$route.query.themeColor
      systemConfig.commit(THEME_COLOR, themeColor)
    }
    this.mySwiper = new Swiper('.swiper-container', {
      on: {
        slideChangeTransitionEnd: (e) => {
          this.planType = this.mySwiper.activeIndex
        }
      }
    })
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getDetailName() {
      return this.detail.name || ''
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.plan_name || ''
    },
    getPlanName_en() {
      return this.detail.plan_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },
    getIsSave() {
      return this.detail.is_save || 0
    },
    getPlanLogo() {
      return this.detail.plan_logo || ''
    }
  },

  methods: {
    onParamsSettingChange(field, set = true) {
      if (!this.initSettingState) {
        return
      }
      console.log(field, set)
      if (set) {
        this.$store.state.app.cacheMobileParams[field] = this.$refs[field].getPageParams()
      } else {
        return this.$store.state.app.cacheMobileParams[field]
      }
    },

    initParamsSetting() {
      //初始化配置参数

      this.initSettingState = true
      let pageParams = this.mobileParams || {}
      if (!pageParams || !('SolutionPlan' in pageParams)) {
        pageParams = this.pageParams || {}
      }

      let solutionPie = pageParams.solutionPie || {}
      let SolutionPlan = pageParams.SolutionPlan || {}
      let barEchart = pageParams.barEchart || {}

      const cache_solutionPie = this.onParamsSettingChange('solutionPie', false)
      if (cache_solutionPie != null) {
        solutionPie = cache_solutionPie
      }

      const cache_SolutionPlan = this.onParamsSettingChange('SolutionPlan', false)
      if (cache_SolutionPlan != null) {
        SolutionPlan = cache_SolutionPlan
      }

      const cache_barEchart = this.onParamsSettingChange('barEchart', false)
      if (cache_barEchart != null) {
        barEchart = cache_barEchart
      }

      //2.0 饼图
      solutionPie && this.$refs.solutionPie && this.$refs.solutionPie.setPageParams(solutionPie || {}, true)
      //3.0 周转分析
      SolutionPlan && this.$refs.SolutionPlan && this.$refs.SolutionPlan.setPageParams(SolutionPlan || {}, true)
      //4.0 高级功能
      barEchart && this.$refs.barEchart && this.$refs.barEchart.setPageParams(barEchart || {}, true)

      // this.$refs.moblie.$refs.solutionAnalysis.setParams(pageParams.solutionAnalysis || {})
    },
    changeSettingParams() {
      this.$network('/api/tools/reulstUpdateMobileParams', {
        id: this.listQuery.id,
        mobile_params: {
          solutionPie: this.$refs.solutionPie.getPageParams(),
          SolutionPlan: this.$refs.SolutionPlan.getPageParams(),
          barEchart: this.$refs.barEchart.getPageParams()
        }
      })
        .then((res) => {
          this.$message.success('已更新')
        })
        .catch((err) => {})
    },

    getPlanDetail() {
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const pageParams = detail.page_params || {}
          this.mobileParams = detail.mobile_params || {}
          this.pageParams = pageParams
          this.remark_base = detail.remark_base || ''
          this.remark_extra = detail.remark_extra || ''
          this.remark_base_mobile = detail.remark_base_mobile || ''
          this.remark_extra_mobile = detail.remark_extra_mobile || ''
          this.detail = detail

          this.$nextTick(() => {
            this.getData()

            // console.log('------------------------')
            // console.log(this.$refs)

            // //2.0 饼图
            // this.$refs.solutionPie && this.$refs.solutionPie.setPageParams(pageParams.solutionPie || {}, true)

            // //3.0 周转分析
            // this.$refs.SolutionPlan && this.$refs.SolutionPlan.setPageParams(pageParams.SolutionPlan || {}, true)
            // //4.0 高级功能
            // this.$refs.barEchart && this.$refs.barEchart.setPageParams(pageParams.barEchart || {}, true)

            //  this.$refs.moblie.$refs.solutionAnalysis.setParams(pageParams.solutionAnalysis || {})

            this.initParamsSetting()
          })
        })
        .catch((err) => {
          // console.error(err)
        })
    },
    getData() {
      Promise.all([
        //库存
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step3-material' }),
        //成品
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step3-stock' }),
        //发出商品
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step3-send-product' })
      ]).then((res) => {
        const [
          {
            data: { list: step3Material_data }
          },
          {
            data: { list: step3Stock_data }
          },
          {
            data: { list: step3SendProduct_data }
          }
        ] = res

        //材料清单
        this.tableList_material_origin = JSON.parse(JSON.stringify(step3Material_data || []))
        const materialObj = this.handleClearData(step3Material_data, 'step3-material')
        const step3MaterialList = materialObj.groupData || {}
        if (materialObj.hasDataType) this.usePlan.push('step3-material')

        //成品清单
        this.tableList_stock_origin = JSON.parse(JSON.stringify(step3Stock_data || []))
        const stockObj = this.handleClearData(step3Stock_data, 'step3-stock')
        const step3StockList = stockObj.groupData || {}
        if (stockObj.hasDataType) this.usePlan.push('step3-stock')

        //发出商品
        this.tableList_send_product_origin = JSON.parse(JSON.stringify(step3SendProduct_data || []))
        const sendProductObj = this.handleClearData(step3SendProduct_data, 'step3-send-product')
        const step3SendProductList = sendProductObj.groupData || {}
        if (sendProductObj.hasDataType) this.usePlan.push('step3-send-product')

        const step3Group_material = Object.values(step3MaterialList)
        const step3Group_stock = Object.values(step3StockList)
        const step3Group_sendProduct = Object.values(step3SendProductList)

        this.materialStartMonth = (step3Group_material[step3Group_material.length - 1] || {}).month || ''
        this.materialAfterMonth = (step3Group_material[0] || {}).month || ''
        this.materialMonth = this.$diffMonth(this.materialStartMonth, this.materialAfterMonth)

        this.stockStartMonth = (step3Group_stock[step3Group_stock.length - 1] || {}).month || ''
        this.stockAfterMonth = (step3Group_stock[0] || {}).month || ''
        this.stockMonth = this.$diffMonth(this.stockStartMonth, this.stockAfterMonth)

        this.sendProductStartMonth = (step3Group_sendProduct[step3Group_sendProduct.length - 1] || {}).month || ''
        this.sendProductAfterMonth = (step3Group_sendProduct[0] || {}).month || ''
        this.sendProductMonth = this.$diffMonth(this.sendProductStartMonth, this.sendProductAfterMonth)

        this.tableList_material = step3Group_material
        this.tableList_stock = step3Group_stock
        this.tableList_send_product = step3Group_sendProduct

        this.$nextTick(() => {
          this.initDataState = 'complete'
        })
      })
    },
    onChangeParams(e) {
      if (this.detail.id) {
        clearTimeout(this.onChangeParamsTimer)
        this.onChangeParamsTimer = setTimeout(() => {
          this.fncSettingOptions({
            params_tax_per: this.params_tax_per || 0,
            params_sold_per: this.params_sold_per || 0,
            params_cost_per: this.params_cost_per || 0,
            params_pass_per: this.params_pass_per || 0
          })
        }, 300)
      }
    },
    fncSettingOptions(params, returnData = false) {
      console.log(params)
    },
    handleShare() {
      message.error(`保存后才能进行分享`)
      this.handleSave(() => {
        this.$refs.savePlan.close()
        this.$nextTick(() => {
          this.$refs.shareLink.open()
        })
      })
    },
    handleBack() {
      this.$confirm({
        content: '是否保存方案',
        okText: '是',
        cancelText: '否',
        onOk: () => {
          this.handleSave(() => {
            this.$router.back()
          })
        },
        onCancel: () => {
          this.$router.back()
        }
      })
    },
    handleSave(callback) {
      this.$refs.savePlan.open(
        this.listQuery.id,
        {
          name: this.detail.name || '',
          remark_base: this.remark_base,
          params: {
            params_tax_per: this.params_tax_per,
            params_sold_per: this.params_sold_per,
            params_cost_per: this.params_cost_per,
            params_pass_per: this.params_pass_per,

            solutionAnalysis: this.$refs.solutionAnalysis.getSaveData()
          }
        },
        () => {
          this.detail.is_save = 1
          if (callback) {
            callback()
          } else {
            this.$router.replace({ path: '/history' })
          }
        }
      )
    },

    handleSubmit() {
      const obj = { id: this.detail.id }
      if (this.planType) {
        obj.remark_extra_mobile = this.remark_extra_mobile
      } else {
        obj.remark_base_mobile = this.remark_base_mobile
      }
      this.submitLoading = true
      this.$network('/api/tools/planHistoryEdit', obj)
        .then((res) => {
          this.$message.success('保存成功')
          this.submitLoading = false
        })
        .catch(() => {
          this.submitLoading = false
        })
    },
    handlePrint() {
      this.$network('/api/tools/planPrintTimes', { id: this.listQuery.id, tool_type: 'two' })
    }
  }
}
</script>
