<template>
  <div class="solution-children">
    <div class="thead">基本表</div>

    <tools-item-intro type="six" field="intro2" />

    <div class="tabs-group" v-show="getHasToolAuth('tools1_table_before') && getHasToolAuth('tools1_table_after')">
      <a-radio-group v-model:value="showWay" name="radioGroup" optionType="button" button-style="solid">
        <a-radio value="1">全部</a-radio>
        <a-radio value="2">只看加成后</a-radio>
        <a-radio value="3">只看加成前</a-radio>
      </a-radio-group>
    </div>

    <!-- <div class="row" v-show="['1', '3'].indexOf(showWay) > -1">
        <div class="table-children">
            <div class="subtitle">
              <text>原导入：</text>
              <a-cascader
                multiple
                :fieldNames="{ label: 'title', value: 'dataIndex', children: 'children' }"
                placeholder="Please select"
                v-model:value="columns_step_yc_filter"
                :options="columns_step_yc_select" 
                @change="onTableFieldChange($event,'columns_step_yc')"
              >
                <a href="#">选择列</a>
              </a-cascader>
            </div>
            <a-table class="cross-table"  :customCell="tableCustomCell" :columns="columns_step_yc" :data-source="tableData_step_yc" :pagination="pagination" size="small" bordered  :scroll="{ x: 1000,y: 650 }" @resizeColumn="(w, col)=>{ col.width = w}">
                <template #bodyCell="{ column, text, record, index }">
                    <template v-if="column.dataIndex === 'sold_num'">
                        {{ $dealThousands(record.sold_num) }}
                    </template>
                    <template v-if="column.dataIndex === 'price_add_tax'">
                        {{ $dealThousands(record.price_add_tax) }}
                    </template>
                    <template v-if="column.dataIndex === 'price_come_in'">
                        {{ $dealThousands(record.price_come_in) }}
                    </template>
                    <template v-if="column.dataIndex === 'pass_per'">
                        {{ $dealThousands(record.pass_per) }}
                    </template>
                    <template v-if="column.dataIndex === 'pass_sold_num'">
                        {{ $dealThousands(record.pass_sold_num) }}
                    </template>
                    <template v-if="column.dataIndex === 'material_cost'">
                        {{ $dealThousands(record.material_cost) }}
                    </template>
                    <template v-if="column.dataIndex === 'material_cost_money'">
                        {{ $dealThousands(record.material_cost_money) }}
                    </template>
                    <template v-if="column.dataIndex === 'side_unit_money'">
                        {{ $dealThousands(record.side_unit_money) }}
                    </template>
                    <template v-if="column.dataIndex === 'side_money'">
                        {{ $dealThousands(record.side_money) }}
                    </template>
                </template>
                <template #summary>
                    <a-table-summary fixed>
                      <a-table-summary-row>
                          <a-table-summary-cell align="center" v-if="columns_step_yc_filter_items.indexOf('no')>-1">合计</a-table-summary-cell>
                          <a-table-summary-cell align="center" v-if="columns_step_yc_filter_items.indexOf('attr')>-1"> / </a-table-summary-cell>
                          <a-table-summary-cell align="center" v-if="columns_step_yc_filter_items.indexOf('unit')>-1"> / </a-table-summary-cell>
                          <a-table-summary-cell align="right"  v-if="columns_step_yc_filter_items.indexOf('sold_num')>-1"> {{ $dealThousands(sum_sold_num) }} </a-table-summary-cell>
                          <a-table-summary-cell align="right"  v-if="columns_step_yc_filter_items.indexOf('price_add_tax')>-1"> {{ $dealThousands(sum_price_add_tax_per_sum_sold_num) }} </a-table-summary-cell>
                          <a-table-summary-cell align="right"  v-if="columns_step_yc_filter_items.indexOf('price_come_in')>-1"> {{ $dealThousands(sum_price_come_in) }} </a-table-summary-cell>
                          <a-table-summary-cell align="right"  v-if="columns_step_yc_filter_items.indexOf('pass_per')>-1"> {{ $dealThousands(sum_pass_per) }} </a-table-summary-cell>
                          <a-table-summary-cell align="right"  v-if="columns_step_yc_filter_items.indexOf('pass_sold_num')>-1"> {{ $dealThousands(sum_pass_sold_num) }} </a-table-summary-cell>
                          <a-table-summary-cell align="right"  v-if="columns_step_yc_filter_items.indexOf('material_cost')>-1"> {{ $dealThousands(sum_pass_sold_num_price) }} </a-table-summary-cell>
                          <a-table-summary-cell align="right"  v-if="columns_step_yc_filter_items.indexOf('material_cost_money')>-1"> {{ $dealThousands(sum_material_cost_money) }} </a-table-summary-cell>
                          <a-table-summary-cell align="right"  v-if="columns_step_yc_filter_items.indexOf('side_unit_money')>-1"> {{ $dealThousands(sum_side_unit_money) }} </a-table-summary-cell>
                          <a-table-summary-cell align="right"  v-if="columns_step_yc_filter_items.indexOf('side_money')>-1"> {{ $dealThousands(sum_side_money) }} </a-table-summary-cell>
                      </a-table-summary-row>
                    </a-table-summary>
                </template>
            </a-table>
        </div>
    </div> -->
    <div class="row">
      <div class="table-children">
        <div class="subtitle">
          <text>行参数：</text>
          <a-cascader
            multiple
            :fieldNames="{ label: 'title', value: 'dataIndex', children: 'children' }"
            placeholder="Please select"
            v-model:value="columns_after_filter"
            :options="columns_after_select"
            @change="onTableFieldChange($event, 'columns_after')"
          >
            <a href="#">选择列</a>
          </a-cascader>
        </div>

        <a-table
          ref="solutionBaseTable"
          class="cross-table"
          :columns="columns_after"
          :data-source="tableData_changeData"
          :pagination="pagination"
          size="small"
          bordered
          @resizeColumn="(w, col) => (col.width = w)"
          :scroll="isPrint ? { y: '100%' } : { y: 540 }"
          @change="onTableChange"
        >
          <template #bodyCell="{ column, text, record, index }">
            <template v-if="column.dataIndex === 'params_line_tax_per'">
              <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                <template #title>
                  <SliderInputBlock v-model:value="record.params_line_tax_per" :order="0" @change="onChange_slider($event, record, 'params_line_tax_per')" />
                </template>

                <a-input-number
                  class="a-button-input"
                  v-model:value="record.params_line_tax_per"
                  :min="-100"
                  :max="300"
                  :step="1"
                  @change="debounceInputChange($event, record, 'params_line_tax_per')"
                />
              </a-tooltip>
            </template>

            <template v-else-if="column.dataIndex === 'params_line_sold_per'">
              <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                <template #title>
                  <SliderInputBlock v-model:value="record.params_line_sold_per" :order="0" @change="onChange_slider($event, record, 'params_line_sold_per')" />
                </template>
                <a-input-number
                  class="a-button-input"
                  v-model:value="record.params_line_sold_per"
                  :min="-100"
                  :max="300"
                  :step="1"
                  @change="debounceInputChange($event, record, 'params_line_sold_per')"
                />
              </a-tooltip>
            </template>

            <template v-else-if="column.dataIndex === 'params_line_cost_per'">
              <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                <template #title>
                  <SliderInputBlock v-model:value="record.params_line_cost_per" @change="onChange_slider($event, record, 'params_line_cost_per')" />
                </template>

                <a-input-number
                  class="a-button-input"
                  v-model:value="record.params_line_cost_per"
                  :min="-100"
                  :max="300"
                  :step="1"
                  @change="debounceInputChange($event, record, 'params_line_cost_per')"
                />
              </a-tooltip>
            </template>

            <template v-else-if="column.dataIndex === 'params_line_pass_per'">
              <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                <template #title>
                  <SliderInputBlock
                    v-model:value="record.params_line_pass_per"
                    :max="100"
                    :step="0.5"
                    :merMin="0"
                    checkBase
                    :baseValue="params_pass_per"
                    :fixedValue="record.__pass_per"
                    @change="onChange_slider($event, record, 'params_line_pass_per')"
                  />
                </template>
                <a-input-number class="a-button-input" v-model:value="record.params_line_pass_per" :max="100" :step="0.5" @change="debounceInputChange($event, record, 'params_line_pass_per')" />
              </a-tooltip>
            </template>

            <!-- 边际收益 -->
            <template v-else-if="column.dataIndex === 'side_money' || column.dataIndex == '__side_money'">
              <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                <text :class="{ 'text-red': text * 1 < 0 }">{{ $dealThousands(text) }}</text>
              </a-tooltip>
            </template>

            <template v-else-if="'pass_per' === column.dataIndex">
              <text :class="{ 'text-red': text * 1 >= 100 }">{{ $dealThousands(text) }}</text>
            </template>

            <template v-else-if="['no', 'attr', 'unit'].indexOf(column.dataIndex) === -1">
              <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                <text>{{ $dealThousands(text) }}</text>
              </a-tooltip>
            </template>
          </template>

          <template #summary>
            <a-table-summary fixed>
              <a-table-summary-row class="gray-bg-def-deep">
                <a-table-summary-cell :index="0" align="center" v-if="columns_after_filter_items.indexOf('no') > -1">
                  <a-tooltip @click="showMoreTableList">
                    <template #title>{{ pagination ? '点击查看全部' : '点击收起' }}</template>
                    其它
                    <template v-if="pagination">
                      <CaretDownOutlined />
                    </template>
                    <template v-else>
                      <CaretUpOutlined />
                    </template>
                  </a-tooltip>
                </a-table-summary-cell>

                <a-table-summary-cell :index="1" align="center" v-if="columns_after_filter_items.indexOf('attr') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="2" align="center" v-if="columns_after_filter_items.indexOf('unit') > -1"> / </a-table-summary-cell>

                <a-table-summary-cell :index="3" align="center" v-if="getHasToolAuth('tools1_table_line_mod') && columns_after_filter_items.indexOf('params_line_tax_per') > -1">
                  /
                </a-table-summary-cell>
                <a-table-summary-cell :index="4" align="center" v-if="getHasToolAuth('tools1_table_line_mod') && columns_after_filter_items.indexOf('params_line_sold_per') > -1">
                  /
                </a-table-summary-cell>
                <a-table-summary-cell :index="5" align="center" v-if="getHasToolAuth('tools1_table_line_mod') && columns_after_filter_items.indexOf('params_line_cost_per') > -1">
                  /
                </a-table-summary-cell>
                <a-table-summary-cell :index="6" align="center" v-if="getHasToolAuth('tools1_table_line_mod') && columns_after_filter_items.indexOf('params_line_pass_per') > -1">
                  /
                </a-table-summary-cell>

                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__sold_num') > -1">
                  {{ $dealThousands(sum_sold_num - topSumObj.sum___sold_num) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__sold_num__per') > -1">
                  {{ $dealThousands(100 - topSumObj.sum___sold_num__per) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__price_come_in') > -1">
                  {{ $dealThousands(sum_price_come_in - topSumObj.sum___price_come_in) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__price_come_in__per') > -1">
                  {{ $dealThousands(100 - topSumObj.sum___price_come_in__per) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__material_cost_money') > -1">
                  {{ $dealThousands(sum_material_cost_money - topSumObj.sum___material_cost_money) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__material_cost_money__per') > -1">
                  {{ $dealThousands(100 - topSumObj.sum___material_cost_money__per) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__side_money') > -1">
                  <text :class="{ 'text-red': (sum_side_money - topSumObj.sum___side_money || 0).toFixed(2) * 1 < 0 }">{{ $dealThousands(sum_side_money - topSumObj.sum___side_money) }} </text>
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__side_money__per') > -1">
                  {{ $dealThousands(100 - topSumObj.sum___side_money__per) }}
                </a-table-summary-cell>

                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('sold_num') > -1">
                  {{ $dealThousands(sum_change_sold_num - topSumObj.sum_sold_num) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('sold_num__per') > -1">
                  {{ $dealThousands(100 - topSumObj.sum_sold_num__per) }}
                </a-table-summary-cell>

                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('price_add_tax') > -1">
                  {{ $dealThousands(sum_change_price_add_tax_per_sum_sold_num - topSumObj.sum_price_add_tax) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('price_come_in') > -1">
                  {{ $dealThousands(sum_change_price_come_in - topSumObj.sum_price_come_in) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('price_come_in__per') > -1">
                  {{ $dealThousands(100 - topSumObj.sum_price_come_in__per) }}
                </a-table-summary-cell>

                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('pass_per') > -1">
                  <!-- {{ $dealThousands(pagination===false ? sum_change_pass_per : sum_change_pass_per) }}  -->
                  <text :class="{ 'text-red': sum_change_pass_per * 1 >= 100 }"> {{ $dealThousands(sum_change_pass_per) }} </text>
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('pass_sold_num') > -1">
                  {{ $dealThousands(sum_change_pass_sold_num - topSumObj.sum_pass_sold_num) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('material_cost') > -1">
                  {{ $dealThousands(pagination === false ? sum_change_pass_sold_num_price : sum_change_pass_sold_num_price) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('material_cost_money') > -1">
                  {{ $dealThousands(sum_change_material_cost_money - topSumObj.sum_material_cost_money) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('material_cost_money__per') > -1">
                  {{ $dealThousands(100 - topSumObj.sum_material_cost_money__per) }}
                </a-table-summary-cell>

                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('side_unit_money') > -1">
                  {{ $dealThousands(pagination === false ? sum_change_side_unit_money : sum_change_side_unit_money) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('side_money') > -1">
                  <text :class="{ 'text-red': sum_change_side_money - topSumObj.sum_side_money * 1 < 0 }"> {{ $dealThousands(sum_change_side_money - topSumObj.sum_side_money) }} </text>
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('side_money__per') > -1">
                  {{ $dealThousands(100 - topSumObj.sum_side_money__per) }}
                </a-table-summary-cell>
              </a-table-summary-row>
            </a-table-summary>
            <a-table-summary fixed>
              <a-table-summary-row class="gray-bg-def-deep">
                <a-table-summary-cell :index="0" align="center" v-if="columns_after_filter_items.indexOf('no') > -1"> 总计 </a-table-summary-cell>

                <a-table-summary-cell :index="1" align="center" v-if="columns_after_filter_items.indexOf('attr') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell :index="2" align="center" v-if="columns_after_filter_items.indexOf('unit') > -1"> / </a-table-summary-cell>

                <a-table-summary-cell :index="3" align="center" v-if="getHasToolAuth('tools1_table_line_mod') && columns_after_filter_items.indexOf('params_line_tax_per') > -1">
                  /
                </a-table-summary-cell>
                <a-table-summary-cell :index="4" align="center" v-if="getHasToolAuth('tools1_table_line_mod') && columns_after_filter_items.indexOf('params_line_sold_per') > -1">
                  /
                </a-table-summary-cell>
                <a-table-summary-cell :index="5" align="center" v-if="getHasToolAuth('tools1_table_line_mod') && columns_after_filter_items.indexOf('params_line_cost_per') > -1">
                  /
                </a-table-summary-cell>
                <a-table-summary-cell :index="6" align="center" v-if="getHasToolAuth('tools1_table_line_mod') && columns_after_filter_items.indexOf('params_line_pass_per') > -1">
                  /
                </a-table-summary-cell>

                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__sold_num') > -1">
                  {{ $dealThousands(sum_sold_num) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__sold_num__per') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__price_come_in') > -1">
                  {{ $dealThousands(sum_price_come_in) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__price_come_in__per') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__material_cost_money') > -1">
                  {{ $dealThousands(sum_material_cost_money) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__material_cost_money__per') > -1"> / </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__side_money') > -1">
                  {{ $dealThousands(sum_side_money) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-if="getHasToolAuth('tools1_table_before') && columns_after_filter_items.indexOf('__side_money__per') > -1"> / </a-table-summary-cell>

                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('sold_num') > -1">
                  {{ $dealThousands(sum_change_sold_num) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('sold_num__per') > -1"> / </a-table-summary-cell>

                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('price_add_tax') > -1">
                  {{ $dealThousands(sum_change_price_add_tax_per_sum_sold_num) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('price_come_in') > -1">
                  {{ $dealThousands(sum_change_price_come_in) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('price_come_in__per') > -1"> / </a-table-summary-cell>

                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('pass_per') > -1">
                  <text :class="{ 'text-red': sum_change_pass_per * 1 >= 100 }"> {{ $dealThousands(sum_change_pass_per) }} </text>
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('pass_sold_num') > -1">
                  {{ $dealThousands(sum_change_pass_sold_num) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('material_cost') > -1">
                  {{ $dealThousands(sum_change_pass_sold_num_price) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('material_cost_money') > -1">
                  {{ $dealThousands(sum_change_material_cost_money) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('material_cost_money__per') > -1"> / </a-table-summary-cell>

                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('side_unit_money') > -1">
                  {{ $dealThousands(sum_change_side_unit_money) }}
                </a-table-summary-cell>
                <a-table-summary-cell align="right" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('side_money') > -1">
                  <text :class="{ 'text-red': sum_change_side_money * 1 < 0 }"> {{ $dealThousands(sum_change_side_money) }} </text>
                </a-table-summary-cell>
                <a-table-summary-cell align="center" v-if="getHasToolAuth('tools1_table_after') && columns_after_filter_items.indexOf('side_money__per') > -1"> / </a-table-summary-cell>
              </a-table-summary-row>
            </a-table-summary>
          </template>
        </a-table>
      </div>
    </div>
    <!-- <a-collapse v-model:activeKey="collapseActiveKey">
     
      <a-collapse-panel key="1" header="点击查看收入占比" >
        <div>收入占比（加成前）</div>
        <div>
          <a-descriptions :column="4" size="small" bordered :labelStyle="{ width: '240px' }">
            <a-descriptions-item label="销量占比">{{$numberToFixed(sum_coll_number)<=0 ? 0 : $numberToFixed((sum_sold_num/sum_coll_number)*100)}} %</a-descriptions-item>
            <a-descriptions-item label="收入占比">{{$numberToFixed(sum_coll_number)<=0 ? 0 : $numberToFixed((sum_price_come_in/sum_coll_number)*100)}}</a-descriptions-item>
            <a-descriptions-item label="成本占比">{{$numberToFixed(sum_coll_number)<=0 ? 0 : $numberToFixed((sum_material_cost_money/sum_coll_number)*100)}}</a-descriptions-item>
            <a-descriptions-item label="边际贡献占比">{{$numberToFixed(sum_coll_number)<=0 ? 0 : $numberToFixed((sum_side_money/sum_coll_number)*100)}}</a-descriptions-item>
          </a-descriptions>
        </div>
        <div>收入占比（加成后）</div>
        <div>
          <a-descriptions :column="4" size="small" bordered :labelStyle="{ width: '240px' }">
            <a-descriptions-item label="销量占比">{{$numberToFixed(sum_coll_number_change)<=0 ? 0 : $numberToFixed((sum_change_sold_num/sum_coll_number_change)*100)}} %</a-descriptions-item>
            <a-descriptions-item label="收入占比">{{$numberToFixed(sum_coll_number_change)<=0 ? 0 : $numberToFixed((sum_change_price_come_in/sum_coll_number_change)*100)}}</a-descriptions-item>
            <a-descriptions-item label="成本占比">{{$numberToFixed(sum_coll_number_change)<=0 ? 0 : $numberToFixed((sum_change_material_cost_money/sum_coll_number_change)*100)}}</a-descriptions-item>
            <a-descriptions-item label="边际贡献占比">{{$numberToFixed(sum_coll_number_change)<=0 ? 0 : $numberToFixed((sum_change_side_money/sum_coll_number_change)*100)}}</a-descriptions-item>
          </a-descriptions>
        </div>
      </a-collapse-panel>
    </a-collapse> -->
  </div>
</template>

<script>
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue'
import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import {
  numberToFixed,
  fnc_excelData_yc_before,
  fnc_excelData_yc_after,
  fnc_excelData_yc_line_test,
  fnc_excelData_yc_line,
  dealThousands,
  checkZero,
  debounce,
  tableSortFnc,
  tableDefaultPage,
  tableDefautlLine
} from '@/utils/util'
import SliderInputBlock from '@/components/SliderInputBlock.vue'
import { mapGetters } from 'vuex'

const columns_step_yc_select = [
  { width: 100, title: '产品编码ID', dataIndex: 'no', align: 'center', resizable: true, disabled: true },
  { title: '产品规格/描述', dataIndex: 'attr', align: 'left', resizable: true, ellipsis: true, disabled: true },
  { width: 100, title: '计量单位', dataIndex: 'unit', align: 'center', resizable: true },
  { width: 150, title: '原销量', dataIndex: 'sold_num', align: 'right', resizable: true, disabled: true },
  { width: 150, title: '原平均含税售价', dataIndex: 'price_add_tax', align: 'right', resizable: true },
  { width: 150, title: '原销售收入', dataIndex: 'price_come_in', align: 'right', resizable: true, disabled: true },
  { width: 150, title: '原产品平均合格率(%)', dataIndex: 'pass_per', align: 'right', resizable: true },
  { width: 150, title: '原产量', dataIndex: 'pass_sold_num', align: 'right', resizable: true },
  { width: 150, title: '原单位变动成本', dataIndex: 'material_cost', align: 'right', resizable: true },
  { width: 150, title: '原总变动成本', dataIndex: 'material_cost_money', align: 'right', resizable: true },
  { width: 150, title: '原单位边际贡献', dataIndex: 'side_unit_money', align: 'right', resizable: true },
  { width: 150, title: '原边际贡献', dataIndex: 'side_money', align: 'right', resizable: true }
]

const columns_after_select = [
  { width: 100, title: '产品编码ID', dataIndex: 'no', align: 'center', fixed: 'left', resizable: true, ellipsis: true, disabled: false, ...tableSortFnc('no', 'string') },
  { width: 80, title: '产品规格/描述', dataIndex: 'attr', align: 'center', fixed: 'left', resizable: true, ellipsis: true, disabled: false },
  { width: 100, title: '计量单位', dataIndex: 'unit', align: 'center', fixed: 'left', resizable: true, ellipsis: true, disabled: false },

  { width: 80, title: '含税售价加成(%)', dataIndex: 'params_line_tax_per', fixed: 'left', align: 'center', resizable: true, ellipsis: true, disabled: false },
  { width: 80, title: '销量加成(%)', dataIndex: 'params_line_sold_per', fixed: 'left', align: 'center', resizable: true, ellipsis: true, disabled: false },
  { width: 100, title: '单位变动成本加成(%)', dataIndex: 'params_line_cost_per', fixed: 'left', align: 'center', resizable: true, ellipsis: true, disabled: false },
  { width: 80, title: '产品合格率加成(%)', dataIndex: 'params_line_pass_per', fixed: 'left', align: 'center', resizable: true, ellipsis: true, disabled: false },

  { width: 150, title: '加成前销量', dataIndex: '__sold_num', align: 'right', resizable: true, ellipsis: true, className: 'cell-before', ...tableSortFnc('__sold_num') },
  { width: 150, title: '销量占比', dataIndex: '__sold_num__per', align: 'right', resizable: true, ellipsis: true, className: 'cell-before', ...tableSortFnc('__sold_num__per') },

  { width: 150, title: '加成前销售收入', dataIndex: '__price_come_in', align: 'right', resizable: true, ellipsis: true, className: 'cell-before', ...tableSortFnc('__price_come_in') },
  { width: 150, title: '收入占比', dataIndex: '__price_come_in__per', align: 'right', resizable: true, ellipsis: true, className: 'cell-before', ...tableSortFnc('__price_come_in__per') },

  { width: 150, title: '加成前总变动成本', dataIndex: '__material_cost_money', align: 'right', resizable: true, ellipsis: true, className: 'cell-before', ...tableSortFnc('__material_cost_money') },
  { width: 150, title: '成本占比', dataIndex: '__material_cost_money__per', align: 'right', resizable: true, ellipsis: true, className: 'cell-before', ...tableSortFnc('__material_cost_money__per') },

  { width: 150, title: '加成前边际贡献', dataIndex: '__side_money', align: 'right', resizable: true, ellipsis: true, className: 'cell-before', ...tableSortFnc('__side_money') },
  { width: 150, title: '边际贡献占比', dataIndex: '__side_money__per', align: 'right', resizable: true, ellipsis: true, className: 'cell-before', ...tableSortFnc('__side_money__per') },

  { width: 150, title: '加成后销量', dataIndex: 'sold_num', align: 'right', resizable: true, ellipsis: true, disabled: false, ...tableSortFnc('sold_num') },
  { width: 150, title: '销量占比', dataIndex: 'sold_num__per', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('sold_num__per') },

  // {width:150, title: '加成后平均含税售价', dataIndex: 'price_add_tax', align: 'right',resizable: true,ellipsis: true , ...tableSortFnc('price_add_tax')},
  { width: 150, title: '加成后销售收入', dataIndex: 'price_come_in', align: 'right', resizable: true, ellipsis: true, disabled: false, ...tableSortFnc('price_come_in') },
  { width: 150, title: '收入占比', dataIndex: 'price_come_in__per', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('price_come_in__per') },

  // {width:150, title: '加成后平均产品合格率%', dataIndex: 'pass_per', align: 'right',resizable: true,ellipsis: true , ...tableSortFnc('pass_per')},
  // {width:150, title: '加成后产量', dataIndex: 'pass_sold_num', align: 'right' ,resizable: true,ellipsis: true, ...tableSortFnc('pass_sold_num')},
  // {width:150, title: '加成后单位变动成本', dataIndex: 'material_cost', align: 'right',resizable: true,ellipsis: true , disabled: false, ...tableSortFnc('material_cost')},
  { width: 150, title: '加成后总变动成本', dataIndex: 'material_cost_money', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('material_cost_money') },
  { width: 150, title: '成本占比', dataIndex: 'material_cost_money__per', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('material_cost_money__per') },

  // {width:150, title: '加成后单位边际贡献', dataIndex: 'side_unit_money', align: 'right',resizable: true,ellipsis: true , ...tableSortFnc('material_cost_money')},

  { width: 150, title: '加成后边际贡献', dataIndex: 'side_money', align: 'right', resizable: true, ellipsis: true, disabled: false, ...tableSortFnc('side_money') },
  { width: 150, title: '边际贡献占比', dataIndex: 'side_money__per', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('side_money__per') }
]

export default {
  name: 'SolutionBaseTable',
  components: {
    SliderInputBlock,
    ToolsItemIntro,
    CaretDownOutlined,
    CaretUpOutlined
  },
  props: {
    isPrint: {
      type: Boolean,
      default: false
    },
    planId: {
      type: [Number, String],
      default: 0
    },
    // tableCustomCell: {
    //   type: Function,
    //   default: (record, rowIndex, column)=>{}
    // },
    params_tax_per: {
      type: Number,
      default: 0
    },
    params_sold_per: {
      type: Number,
      default: 0
    },
    params_cost_per: {
      type: Number,
      default: 0
    },
    params_pass_per: {
      type: Number,
      default: 0
    },
    // pagination: {
    //   type: [Boolean, Object],
    //   default: false
    // },

    min: { type: Number, default: -100 },
    max: { type: Number, default: 300 },
    step: { type: Number, default: 1 }
  },
  data() {
    return {
      collapseActiveKey: '',
      pagination: tableDefaultPage(),
      //excelData
      //变化前
      excelData: [],
      //变化后
      excelDataChange: [],
      //显示table方式
      showWay: '1',
      //加成前
      columns_step_yc_filter: [['no'], ['attr'], ['unit'], ['sold_num'], ['price_come_in'], ['material_cost_money'], ['side_money']],
      columns_step_yc_select: columns_step_yc_select,
      columns_step_yc: [],

      columns_after_filter: [
        ['no'],
        ['attr'],
        ['unit'],
        ['sold_num'],
        ['price_come_in'],
        ['material_cost_money'],
        ['side_money'],
        ['params_line_tax_per'],
        ['params_line_sold_per'],
        ['params_line_cost_per'],
        ['params_line_pass_per'],
        ['__sold_num'],
        ['__price_come_in'],
        ['__material_cost_money'],
        ['__side_money']
      ],
      columns_after_select: columns_after_select,
      columns_after: [],
      // tableData_step_yc: [],

      // // //加成后
      // tableData_changeData: [],

      paramsChangedInt: 0,
      debounceInputChangeTimer: {},
      //表格显示更多行数
      tableShowMore: false,
      topSumObj: {
        sum___sold_num: 0,
        sum___price_come_in: 0,
        sum___material_cost_money: 0,
        sum___side_money: 0,

        sum___sold_num__per: 0,
        sum___price_come_in__per: 0,
        sum___material_cost_money__per: 0,
        sum___side_money__per: 0,

        sum_sold_num: 0,
        sum_price_add_tax: 0,
        sum_price_come_in: 0,
        // sum_pass_per:0,
        sum_pass_sold_num: 0,
        // sum_material_cost:0,
        sum_material_cost_money: 0,
        // sum_side_unit_money:0,
        sum_side_money: 0,

        sum_sold_num__per: 0,
        sum_price_come_in__per: 0,
        sum_material_cost_money__per: 0,
        sum_side_money__per: 0,

        upSortData: [],

        tableDataFncTimer: 0,
        tableDataInitSort: false,

        calTableData: [],
        //条件变化
        condParamsTableData: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'getHasToolAuth',
      'calYcTableData_fumField',
      'tableData_changeData_field',
      'sum_sold_num',
      'sum_price_add_tax',
      'sum_price_add_tax_per_sum_sold_num',
      'sum_sold_come_in',
      'sum_price_come_in',
      'sum_pass_per',
      'sum_pass_sold_num',
      'sum_pass_sold_num_price',
      'sum_material_cost',
      'sum_material_cost_money',
      'sum_side_unit_money',
      'sum_side_money',
      'sum_change_price_come_in',
      'sum_change_price_add_tax_per_sum_sold_num',
      'price_change_come_in_unit_price',
      'sum_change_sold_num',
      'sum_change_price_add_tax',
      'sum_change_sold_come_in',
      'sum_change_pass_per',
      'sum_change_pass_sold_num',
      'sum_change_pass_sold_num_price',
      'sum_change_material_cost',
      'sum_change_material_cost_money',
      'sum_change_side_unit_money',
      'sum_change_side_money'
    ]),
    tableData_step_yc() {
      return this.$store.state.tools.tableData_step_yc
    },
    tableData_changeData() {
      return this.$store.state.tools.tableData_changeData
    },

    columns_step_yc_filter_items() {
      return this.columns_step_yc_filter.map((item) => {
        return item[0]
      })
    },
    columns_after_filter_items() {
      return this.columns_after_filter.map((item) => {
        return item[0]
      })
    },
    //合计
    sum_coll_number() {
      return this.sum_sold_num * 1 + this.sum_price_come_in * 1 + this.sum_material_cost_money * 1 + this.sum_side_money * 1
    },
    //合计
    sum_coll_number_change() {
      return this.sum_change_sold_num * 1 + this.sum_change_price_come_in * 1 + this.sum_change_material_cost_money * 1 + this.sum_change_side_money * 1
    }

    // columns_step_yc(){
    //   const columns = [];

    //   columns_step_yc_select.map((item)=>{
    //     if(this.columns_step_yc_filter_items.indexOf(item.dataIndex)>-1){
    //       columns.push(item)
    //     }
    //   })
    //   console.log(columns)
    //   return columns
    // },
    //加成后表头
    // columns_changeData() {

    //   const columns = [
    //     {width:150, title: '产品编码ID', dataIndex: 'no', align: 'center',resizable: true },
    //     {width:150, title: '产品规格/描述', dataIndex: 'attr', align: 'center',resizable: true },
    //     {width:150, title: '计量单位', dataIndex: 'unit', align: 'center',resizable: true },

    //     {width:150, title: '含税售价加成(%)', dataIndex: 'params_line_tax_per', align: 'center',resizable: true  },
    //     {width:150, title: '销量加成(%)', dataIndex: 'params_line_sold_per', align: 'center',resizable: true },
    //     {width:150, title: '单位变动成本加成(%)', dataIndex: 'params_line_cost_per', align: 'center',resizable: true },
    //     {width:150, title: '产品合格率加成(%)', dataIndex: 'params_line_pass_per', align: 'center',resizable: true},
    //     {width:150, title: '加成后销量', dataIndex: 'sold_num', align: 'right',resizable: true },
    //     {width:150, title: '加成后平均含税售价', dataIndex: 'price_add_tax', align: 'right',resizable: true },
    //     {width:150, title: '加成后销售收入', dataIndex: 'price_come_in', align: 'right',resizable: true },

    //     {width:150, title: '加成后平均产品合格率%', dataIndex: 'pass_per', align: 'right',resizable: true },
    //     {width:150, title: '加成后产量', dataIndex: 'pass_sold_num', align: 'right' ,resizable: true},
    //     {width:150, title: '加成后单位变动成本', dataIndex: 'material_cost', align: 'right',resizable: true },
    //     {width:150, title: '加成后总变动成本', dataIndex: 'material_cost_money', align: 'right',resizable: true },

    //     {width:150, title: '加成后单位边际贡献', dataIndex: 'side_unit_money', align: 'right',resizable: true },
    //     {width:150, title: '加成后边际贡献', dataIndex: 'side_money', align: 'right',resizable: true }
    //   ]

    //   return  columns
    // },
  },
  created() {},
  mounted() {
    console.log(this.$refs.baseTable)
  },
  watch: {
    showWay(value) {
      // const defaultNo = ['no','attr','unit','__sold_num','__price_come_in','__material_cost_money','__side_money',];
      // const defaultNo = ['no','attr','unit'];
      if (value == '2') {
        //只看加成后
        // const values = []
        // defaultNo.map((item)=>{
        //   if(this.columns_after_filter_items.indexOf(item)===-1){
        //     values.push([item])
        //   }
        // })
        // this.columns_after_filter = this.columns_after_filter.concat(values)
        this.columns_after_filter = [
          ['no'],
          ['attr'],
          ['unit'],
          ['params_line_tax_per'],
          ['params_line_sold_per'],
          ['params_line_cost_per'],
          ['params_line_pass_per'],
          ['sold_num'],
          ['price_come_in'],
          ['material_cost_money'],
          ['side_money']
        ]
      } else if (value == '3') {
        //只看加成前
        this.columns_after_filter = [
          ['no'],
          ['attr'],
          ['unit'],
          ['params_line_tax_per'],
          ['params_line_sold_per'],
          ['params_line_cost_per'],
          ['params_line_pass_per'],
          ['__sold_num'],
          ['__price_come_in'],
          ['__material_cost_money'],
          ['__side_money']
        ]
      } else {
        this.columns_after_filter = [
          ['no'],
          ['attr'],
          ['unit'],
          ['params_line_tax_per'],
          ['params_line_sold_per'],
          ['params_line_cost_per'],
          ['params_line_pass_per'],
          ['__sold_num'],
          ['__price_come_in'],
          ['__material_cost_money'],
          ['__side_money'],
          ['sold_num'],
          ['price_come_in'],
          ['material_cost_money'],
          ['side_money']
        ]
        // const afterColumns = this.columns_after_filter.concat([])
        // defaultNo.map((item)=>{
        //   const index = this.columns_after_filter_items.indexOf(item);
        //   if(index>-1){
        //     afterColumns.splice(index,1)
        //   }
        // })
        // this.columns_after_filter = afterColumns
      }
    },
    columns_step_yc_filter: {
      immediate: true, //首次加载的时候执行函数
      // deep:true, // 深入观察,监听数组值,对象属性值的变化
      handler(value) {
        const columnsSelected = value.map((item) => item[0])
        const columns = []
        columns_step_yc_select.map((item, columnIndex) => {
          if (columnsSelected.indexOf(item.dataIndex) > -1) {
            columns.push(item)
          }
        })
        this.columns_step_yc = columns
      }
    },
    columns_after_filter: {
      immediate: true, //首次加载的时候执行函数
      // deep:true, // 深入观察,监听数组值,对象属性值的变化
      handler(value) {
        const columnsSelected = value.map((item) => item[0])
        const columns = []

        columns_after_select.map((item, columnIndex) => {
          if (columnsSelected.indexOf(item.dataIndex) > -1) {
            if (['params_line_tax_per', 'params_line_sold_per', 'params_line_cost_per', 'params_line_pass_per'].indexOf(item.dataIndex) > -1) {
              if (this.getHasToolAuth('tools1_table_line_mod')) {
                columns.push(item)
              }
            } else if (
              ['__sold_num', '__sold_num__per', '__price_come_in', '__price_come_in__per', '__material_cost_money', '__material_cost_money__per', '__side_money', '__side_money__per'].indexOf(
                item.dataIndex
              ) > -1
            ) {
              if (this.getHasToolAuth('tools1_table_before')) {
                columns.push(item)
              }
            } else if (
              [
                'sold_num',
                'sold_num__per',
                'price_add_tax',
                'price_come_in',
                'price_come_in__per',
                'pass_per',
                'pass_sold_num',
                'material_cost',
                'material_cost_money',
                'material_cost_money__per',
                'side_unit_money',
                'side_money',
                'side_money__per'
              ].indexOf(item.dataIndex) > -1
            ) {
              if (this.getHasToolAuth('tools1_table_after')) {
                columns.push(item)
              }
            } else {
              // item.customCell = (e,rowIndex,column,colIndex)=>this.tableCustomCell(e,rowIndex,column,columnIndex)
              columns.push(item)
            }
          }
        })

        this.columns_after = columns
      }
    },
    excelData(value) {
      const newData = fnc_excelData_yc_before(value, {})
      // this.tableData_step_yc = newData
      this.$store.commit('updateToolsState', { field: 'tableData_step_yc', value: newData })

      this.$store.commit('updateToolsState', {
        field: 'excelData_ycOrigin',
        value: fnc_excelData_yc_line(value, {
          addLine: false //是否增加行记录
        })
      })
    },
    excelDataChange: {
      deep: true,
      handler: function (value) {
        if (!value || !value.length) {
          return
        }

        const lineChangeData = fnc_excelData_yc_line(value, {
          params_sold_per: this.params_sold_per,
          params_pass_per: this.params_pass_per,
          params_tax_per: this.params_tax_per,
          params_cost_per: this.params_cost_per
        })
        //计算数据
        this.calTableData = JSON.parse(JSON.stringify(lineChangeData))
        //重新赋值数据
        this.setCondTableData(this.condParamsTableData)

        //   console.log('>>>>>>>>>>>>>>>>>> excelDataChange <<<<<<<<<<<<<<<<<')
        //   const lineData = fnc_excelData_yc_line_test(lineChangeData,{ })
        // console.log(lineData)

        // this.$store.commit('updateToolsState',{field:'excelData_ycChange',value: lineChangeData})

        // const newData = fnc_excelData_yc_before(lineChangeData,{ })
        // // this.tableData_changeData = JSON.parse(JSON.stringify(newData))

        // const newSort = newData;//newData.sort(tableSortFnc("side_money").sorter.compare)
        // // console.log(newSort)
        // this.$store.commit('updateToolsState',{field:'tableData_changeData',value: newSort})
        //计算其它加个
        // if(!this.tableDataInitSort){
        // this.calOtherSum(newSort)
        // this.tableDataInitSort = true;
        // }
      }
    },
    params_tax_per() {
      this.updateExcelDataChange()
    },
    params_sold_per() {
      this.updateExcelDataChange()
    },
    params_cost_per() {
      this.updateExcelDataChange()
    },
    params_pass_per() {
      this.updateExcelDataChange()
    }
  },
  methods: {
    //组合数据-可能存在重复的数据产品编码问题
    handleCalBefore() {
      const excelData_ycChange = JSON.parse(JSON.stringify(this.calTableData || []))
      this.condParamsTableData.map((tableItem) => {
        excelData_ycChange.map((originItem) => {
          const dataItem = JSON.parse(JSON.stringify(originItem))
          const isEq = Object.keys(tableItem.group).every((groupKey) => {
            return dataItem[groupKey] === tableItem.group[groupKey]
          })
          if (isEq) {
            if (!('settingParamItem' in originItem)) {
              originItem.settingParamItem = {
                price_per: 0,
                sold_num_per: 0,
                material_cost_per: 0,
                pass_per: 0
              }
            }
            originItem.settingParamItem.price_per += tableItem.price_per * 1
            originItem.settingParamItem.sold_num_per += tableItem.sold_num_per * 1
            originItem.settingParamItem.material_cost_per += tableItem.material_cost_per * 1
            originItem.settingParamItem.pass_per += tableItem.pass_per * 1
          }
        })
      })

      return excelData_ycChange
    },
    handleCalData(excelData_ycChange) {
      //处理数据
      // console.log('---------------setCondTableData----------------')
      // console.log(JSON.stringify(excelData_ycChange))

      //计算加成
      // const beforeData = JSON.parse(JSON.stringify(excelData_ycChange))
      const excelDataChange = []
      excelData_ycChange.map(function (item) {
        const afterItem = { ...item }
        for (let key in item) {
          const prefix = key.substring(0, 2)
          if (prefix === '__') continue
          afterItem['__' + key] = item[key]
        }
        excelDataChange.push(afterItem)
      })

      this.$store.commit('updateToolsState', { field: 'excelData_yc', value: excelDataChange })
      //--------重新赋值
      // 变更加成前为加成后的数据
      this.$store.commit('updateToolsState', {
        field: 'tableData_step_yc',
        value: fnc_excelData_yc_before(excelDataChange, {
          addLine: false //是否增加行记录
        })
      })
      this.$store.commit('updateToolsState', {
        field: 'excelData_ycOrigin',
        value: fnc_excelData_yc_line(excelDataChange, {
          addLine: false //是否增加行记录
        })
      })

      //重新赋值单行加成记录
      excelDataChange.map((item) => {
        const settingParamItem = item.settingParamItem || {}
        item.params_line_tax_per_extra = settingParamItem.price_per || 0
        item.params_line_sold_per_extra = settingParamItem.sold_num_per || 0
        item.params_line_cost_per_extra = settingParamItem.material_cost_per || 0
        item.params_line_pass_per_extra = settingParamItem.pass_per || 0
      })
      //只增加行加成问题
      const lineChangeData = fnc_excelData_yc_line(excelDataChange, {
        addLine: false //是否增加行记录
      })

      this.$store.commit('updateToolsState', { field: 'excelData_ycChange', value: lineChangeData })

      const newData = fnc_excelData_yc_before(lineChangeData, {})

      const newSort = newData
      this.$store.commit('updateToolsState', { field: 'tableData_changeData', value: newSort })
      this.calOtherSum(newSort)
    },
    setCondTableData(tableData) {
      //参数设置变量
      this.condParamsTableData = JSON.parse(JSON.stringify(tableData || []))
      //行参数变化
      const excelData_ycChange = this.handleCalBefore()

      //计算数据
      this.handleCalData(excelData_ycChange)

      return
      console.log('setCondTableData', tableData)
    },
    showMoreTableList() {
      if (this.pagination === false) {
        this.pagination = tableDefaultPage()
        this.calOtherSum(this.topSumObj.upSortData, false)
      } else {
        this.pagination = false
      }
    },
    //计算其它加个
    calOtherSum(currentDataSource, recordUpData = true) {
      const totalObj = {}
      for (let key in this.topSumObj) {
        if (key == 'upSortData') continue

        const pageSize = tableDefautlLine //this.pagination.pageSize||0
        const field = key.replace('sum_', '')
        const topSumObj_field = key
        totalObj[topSumObj_field] = 0
        let total = 0
        for (let i = 0; i < currentDataSource.length; i++) {
          const info = currentDataSource[i]

          if (i >= pageSize) {
            break
          }
          totalObj[topSumObj_field] = totalObj[topSumObj_field] + info[field] * 1
        }
      }
      if (recordUpData) {
        totalObj.upSortData = currentDataSource
      } else {
        totalObj.upSortData = this.topSumObj.upSortData
      }
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>totalobj')
      console.log(totalObj)
      this.topSumObj = totalObj
    },
    onTableChange(pagination, filters, sorter, { currentDataSource, action }) {
      // console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
      if (action == 'sort') {
        return this.calOtherSum(currentDataSource)
      }
    },
    setParams(noPer) {
      this.getDetail(noPer)
    },
    getSaveData() {
      const params = {}
      this.tableData_changeData.map((item) => {
        const no = item.no || ''
        const params_line_tax_per = item.params_line_tax_per || 0
        const params_line_sold_per = item.params_line_sold_per || 0
        const params_line_cost_per = item.params_line_cost_per || 0
        const params_line_pass_per = item.params_line_pass_per || 0

        const info = {}

        if (!checkZero(params_line_tax_per)) {
          info.params_line_tax_per = params_line_tax_per
        }

        if (!checkZero(params_line_sold_per)) {
          info.params_line_sold_per = params_line_sold_per
        }

        if (!checkZero(params_line_cost_per)) {
          info.params_line_cost_per = params_line_cost_per
        }

        if (!checkZero(params_line_pass_per)) {
          info.params_line_pass_per = params_line_pass_per
        }
        if (Object.keys(info).length) {
          params[no] = info
        }
      })
      return params
    },
    // tableCustomCell(e,index,index2){
    //   console.log(e,index,index2)
    //   return {
    //     onMouseenter: (event) => {
    //       console.log('.................',event)
    //     },  // 鼠标移入行
    //   onMouseleave: (event) => {
    //     console.log('.................',event)
    //   }
    //   }
    // },
    getDetail(noPer = {}) {
      return this.$network('/api/tools/planResultData', {
        id: this.planId,
        type: 'step-yc'
      })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []
          const excelData = []
          const excelDataChange = []
          list.map(function (item) {
            const no = item.no || ''
            const noPerInfo = noPer[no] || {}
            //保留基础的合格率
            item.__pass_per = item.pass_per || 0
            item.params_line_tax_per = noPerInfo.params_line_tax_per || 0
            item.params_line_sold_per = noPerInfo.params_line_sold_per || 0
            item.params_line_cost_per = noPerInfo.params_line_cost_per || 0
            item.params_line_pass_per = noPerInfo.params_line_pass_per || 0

            item.params_line_tax_per_extra = 0
            item.params_line_sold_per_extra = 0
            item.params_line_cost_per_extra = 0
            item.params_line_pass_per_extra = 0

            excelData.push({ ...item })
            const afterItem = { ...item }
            for (let key in item) {
              afterItem['__' + key] = item[key]
            }
            excelDataChange.push(afterItem)
          })

          this.$store.commit('updateToolsState', { field: 'excelData_yc', value: list })

          this.excelData = excelData

          this.excelDataChange = excelDataChange
        })
        .catch((err) => {
          console.error(err)
        })
    },
    debounceInputChange(value, record, field) {
      clearTimeout(this.debounceInputChangeTimer[field])
      this.debounceInputChangeTimer[field] = setTimeout(() => {
        this.onChange_slider(value, record, field)
      }, 500)
    },
    onChange_slider(value, record, field) {
      this.excelDataChange.map((item) => {
        if (item.no === record.no) {
          item[field] = value
        }
      })
    },
    updateExcelDataChange(data = []) {
      this.excelDataChange = this.excelDataChange.concat(data)
    },
    onTableFieldChange(value, field) {
      this[field + '_filter'] = value
    }
  }
}
</script>
<style lang="less" >
.a-button-input {
  width: 100%;
}
</style>
