<template>
  <div class="cloud4-echart" :class="isMobile ? 'cloud4_echart_mobile' : 'cloud4_echart_pc'">
    <div class="svp_echart_form">
      <a-form v-if="!isMobile">
        <a-row :gutter="8">
          <a-col :xl="6" :md="9" :xs="9">
            <a-form-item label="类型">
              <a-select v-model:value="selectType" :options="typeOptions" @change="selectChange" />
            </a-form-item>
          </a-col>
          <a-col :xl="5" :md="9" :xs="9">
            <a-form-item label="方案">
              <a-select v-model:value="selectPlan" :options="planOptions" @change="selectChange" />
            </a-form-item>
          </a-col>
          <a-col :xl="5" :md="3" :xs="3">
            <a-form-item label="中心线X">
              <a-input type="number" min="0" v-model:value="minX" />
            </a-form-item>
          </a-col>
          <a-col :xl="5" :md="3" :xs="3">
            <a-form-item label="中心线Y">
              <a-input type="number" min="0" max="100" v-model:value="minY" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <a-form v-else>
        <a-form-item label="类型">
          <a-select v-model:value="selectType" :options="typeOptions" @change="selectChange" />
        </a-form-item>
        <a-form-item label="方案">
          <a-select v-model:value="selectPlan" :options="planOptions" @change="selectChange" />
        </a-form-item>
        <a-form-item label="中心线X">
          <a-input type="number" min="0" v-model:value="minX" />
        </a-form-item>
        <a-form-item label="中心线Y">
          <a-input type="number" min="0" max="100" v-model:value="minY" />
        </a-form-item>
      </a-form>
    </div>
    <div id="cloud4-echart-svp" class="echart_svp" :style="isMobile ? 'height:100%' : getBodyWidth"></div>
    <a-modal v-model:visible="openIntro" :title="introTitle" width="50%">
      <div v-html="introContent"></div>

      <template #footer> </template>
    </a-modal>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { mapGetters } from 'vuex'
import { numberToFixed, checkZero, debounce, echartGraphic, dealThousands, chartNowSize, getUseScreen } from '@/utils/util'

const defaultEchartData = []

export default {
  name: 'SolutionPieCycle',
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    isPrint: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: [Boolean, Object],
      default: false
    },
    planType: {
      type: String,
      default: ''
    },
    planBase: {
      //选择的基础方案
      type: String,
      default: ''
    },
    svp_tableData: {
      type: Array,
      default: () => []
    },
    ycTableList: {
      type: Array,
      default: () => []
    },
    analySisObject: {
      type: Object,
      default: () => {}
    },
    initDataState: {
      //数据初始化状态
      type: String,
      default: ''
    }
  },
  components: {},
  data() {
    return {
      selectType: 'sold_come_in',
      selectPlan: 'no',
      typeOptions: [
        { label: '销售额（含税）', value: 'sold_come_in' },
        { label: '销售收入（不含税）', value: 'price_come_in' },
        { label: '边际收益额', value: 'slid_money' }
      ],
      planOptions: [
        { label: '产品', value: 'no' },
        { label: '产品线', value: 'line' },
        { label: '产品类别', value: 'type' },
        { label: '客户', value: 'customer' },
        { label: '区域', value: 'area' },
        { label: '业务组', value: 'group' }
      ],
      echartObj: () => {
        return {}
      },
      //cvp对比结果
      svpTableDataGroup: {},
      //销售
      ycTableDataGroup: {},
      minX: 0,
      minY: 0,
      xRange: 0,
      sizeVaules: 0,
      initEchart: false,
      yMax: 100,
      areaMarkLine: [],

      labelPosition: 'inside',

      screen: getUseScreen(),

      isClock: false,
      openIntro: false,
      introTitle: '',
      introContent: ''
    }
  },
  computed: {
    getTypeName() {
      let name = ''
      for (let i = 0; i < this.typeOptions.length; i++) {
        const info = this.typeOptions[i]
        if (info.value === this.selectType) {
          name = info.label
          break
        }
      }
      return name
    },
    getPlanName() {
      let name = ''
      for (let i = 0; i < this.planOptions.length; i++) {
        const info = this.planOptions[i]
        if (info.value === this.selectPlan) {
          name = info.label
          break
        }
      }
      return name
    },
    getBodyWidth() {
      return document.body.clientWidth < 1441 ? 'height:400px' : document.body.clientWidth > 1441 && document.body.clientWidth < 1919 ? 'height:485px' : 'height:520px'
    }
  },
  watch: {
    initDataState: {
      immediate: true,
      handler(value) {
        if (value === 'complete') {
          //初始表格数据
          this.resetPageData()
        }
      }
    },
    minX(value) {
      if (!this.initEchart) {
        return
      }
      this.echartObj().echart.setOption({
        series: [
          {
            name: 'minX',
            markLine: {
              data: [{ name: '预测', xAxis: value }]
            }
          }
        ]
      })
      this.drawMarkLineAreaTitle()
    },
    minY(value) {
      if (!this.initEchart) {
        return
      }
      this.echartObj().echart.setOption({
        series: [
          {
            name: 'minY',
            markLine: {
              data: [{ name: '预测', yAxis: value }]
            }
          }
        ]
      })
      this.drawMarkLineAreaTitle()
    }
  },
  mounted() {
    this.echartObj = () => {
      return {
        echart: echarts.init(document.getElementById('cloud4-echart-svp'), null, {
          renderer: 'canvas',
          useDirtyRect: false
        })
      }
    }
    // window.addEventListener('resize', this.renderResize, false)
  },

  methods: {
    getPageParams() {
      return {
        selectType: this.selectType,
        selectPlan: this.selectPlan,
        minX: this.minX,
        minY: this.minY
      }
    },
    setPageParams(params) {
      const options = params || {}
      this.isClock = true
      this.selectType = options.selectType || 'sold_come_in'
      this.selectPlan = options.selectPlan || 'no'
      this.minX = options.minX || 0
      this.minY = options.minY || 0
      // console.log('--minX', this.minX)
      // console.log('--minY', this.minY)
      this.selectChange()
    },
    // renderResize() {
    //   let width = document.documentElement.clientWidth
    //   let height = document.documentElement.clientHeight
    //   if (width > height) {
    //     this.screen = 'landscape'
    //   } else {
    //     this.screen = 'vertical'
    //   }
    // },
    selectChange() {
      const groupData = []
      const svpTableInfo = this.svpTableDataGroup[this.selectPlan] || {}
      const ycTableInfo = this.ycTableDataGroup[this.selectPlan] || {}
      // console.log('--------selectChange-----------')
      // console.log(this.selectPlan)
      // console.log(this.svpTableDataGroup)
      // console.log(this.ycTableDataGroup)
      // console.log(svpTableInfo)
      // console.log(ycTableInfo)
      let yMax = 100
      Object.keys(svpTableInfo).map((groupKey) => {
        const svpInfo = svpTableInfo[groupKey]
        const ycInfo = ycTableInfo[groupKey] || {}
        const svpSoldMoney = (svpInfo.sold_come_in || 0) * 1 //基期
        const ycSoldMoney = (ycInfo.sold_come_in || 0) * 1 //
        //
        const slid_money_num = svpInfo.slid_money_num
        const diffSoldMoney = ycSoldMoney - svpSoldMoney
        // console.log(groupKey,diffSoldMoney,svpSoldMoney)
        //销售增长率=（销售预测表的销售额或者对比方案的销售额-基期方案的销售额）/基期方案的销售额
        const per = !svpSoldMoney ? 0 : (diffSoldMoney / svpSoldMoney) * 100
        // console.log(svpSoldMoney,ycSoldMoney,per,groupKey)
        //销售变化率（两期同维度对比）
        if (Math.abs(per) > yMax) {
          yMax = Math.abs(per)
        }
        groupData.push([
          //x
          slid_money_num,

          // (svpInfo[this.selectType] || 0) * 1,
          //销售变化率
          per,
          //类型
          groupKey,
          //对应金额
          (svpInfo[this.selectType] || 0) * 1
        ])
      })
      this.yMax = this.$numberCeilAbs(yMax)
      this.echartData = groupData
      this.calCenterData()
      this.$nextTick(() => {
        this.drawEchart()
        this.drawMarkLineAreaTitle()
      })
    },

    calCenterData() {
      let cycleMaxValue = 10

      const groupIndexs = []

      this.echartData.map((item, index) => {
        const value = Math.abs(parseInt(item[0]))
        const per = Math.abs(parseInt(item[1]))
        if (value > cycleMaxValue) {
          cycleMaxValue = value
        }
        if (item[0] > 0 && item[1] > 0) {
          groupIndexs.push(index)
        }
      })
      this.xRange = this.$numberCeilAbs(cycleMaxValue)

      let sizeVaules = 10
      this.echartData.map((item) => {
        const value = Math.abs(parseInt(item[3]))
        if (value > sizeVaules) {
          sizeVaules = value
        }
      })
      this.sizeVaules = this.$numberCeilAbs(sizeVaules)

      const echartDataLen = this.echartData.length
      const groupIndexsLen = groupIndexs.length
      let mindDiv = groupIndexsLen / 2
      let minX = 0
      let minY = 0
      if (groupIndexsLen > 0) {
        if (groupIndexsLen % 2 === 0) {
          const firstIndex = groupIndexs[mindDiv - 1]
          const secondIndex = groupIndexs[mindDiv]
          const firstEl = this.echartData[firstIndex]
          const secondEl = this.echartData[secondIndex]
          const x1 = firstEl[0]
          const x2 = secondEl[0]
          const y1 = firstEl[1]
          const y2 = secondEl[1]
          minX = numberToFixed((x1 + x2) / 2, 2)
          minY = numberToFixed((y1 + y2) / 2, 2)
        } else {
          const firstIndex = groupIndexs[Math.floor(mindDiv)]
          const mindEl = this.echartData[firstIndex]
          minX = numberToFixed(mindEl[0], 2)
          minY = numberToFixed(mindEl[1], 2)
        }
      }
      if (!this.isClock) {
        this.minX = minX
        this.minY = minY
      }
      // this.minX = minX
      // this.minY = minY
      // console.log('===============this.minX ', this.minX)
      // console.log('===============this.minY ', this.minY)
    },

    resetPageData() {
      this.svpTableDataGroup = this.excelData_svp(this.svp_tableData)
      this.ycTableDataGroup = this.excelData_yc(this.ycTableList)
      this.$nextTick(() => {
        this.selectChange()
      })
    },
    excelData_yc(value) {
      let groupObject = {}
      const groupTypeName = {}
      this.planOptions.map((item) => {
        groupTypeName[item.value] = item.label
      })

      value.map(function (item) {
        for (let key in groupTypeName) {
          const itemValue = item[key]
          const name = groupTypeName[key]
          if (!(key in groupObject)) {
            groupObject[key] = {}
          }
          if (!(itemValue in groupObject[key])) {
            groupObject[key][itemValue] = {
              name: name,
              key: key,
              type_name: itemValue,
              //不含税销售额
              price_come_in: 0,
              //含税销售额
              sold_come_in: 0,
              //记录组合的数据
              list: []
            }
          }

          groupObject[key][itemValue]['price_come_in'] += item.price_come_in * 1
          groupObject[key][itemValue]['sold_come_in'] += item.sold_come_in * 1
          groupObject[key][itemValue]['list'].push(item)
        }
      })

      return groupObject
    },
    excelData_svp(value) {
      let groupObject = {}
      const groupTypeName = {}
      this.planOptions.map((item) => {
        groupTypeName[item.value] = item.label
      })

      value.map(function (item) {
        for (let key in groupTypeName) {
          const itemValue = item[key]
          const name = groupTypeName[key]
          if (!(key in groupObject)) {
            groupObject[key] = {}
          }
          if (!(itemValue in groupObject[key])) {
            groupObject[key][itemValue] = {
              name: key,
              key: key,
              type_name: itemValue,
              //销售收入
              price_come_in: 0,
              //含税销售额
              sold_come_in: 0,
              //变动成本
              material_cost_money: 0,
              //销量
              sold_num: 0,
              //边际收益
              slid_money: 0,
              //记录组合的数据
              list: []
            }
          }
          const sold_num = item.sold_num * 1
          //产量
          const pass_sold_num = item.pass_sold_num * 1

          item.material_cost_money = numberToFixed(item.material_cost * 1 * pass_sold_num)

          groupObject[key][itemValue]['price_come_in'] += item.price_come_in * 1
          groupObject[key][itemValue]['sold_come_in'] += item.sold_come_in * 1
          groupObject[key][itemValue]['material_cost_money'] += item.material_cost_money * 1
          groupObject[key][itemValue]['sold_num'] += sold_num
          groupObject[key][itemValue]['list'].push(item)

          //边际贡献
          // const slid_money = numberToFixed(item.price_come_in - item.material_cost)
          // groupObject[name][itemValue]['slid_money'] +=  groupObject[name][itemValue]['price_come_in'] - groupObject[name][itemValue]['material_cost_money'];
        }
      })

      for (let _key in groupObject) {
        for (let _member in groupObject[_key]) {
          groupObject[_key][_member]['price_come_in'] = numberToFixed(groupObject[_key][_member]['price_come_in'])
          groupObject[_key][_member]['material_cost_money'] = numberToFixed(groupObject[_key][_member]['material_cost_money'])
          groupObject[_key][_member]['sold_num'] = numberToFixed(groupObject[_key][_member]['sold_num'])
          groupObject[_key][_member]['slid_money'] = numberToFixed(groupObject[_key][_member]['price_come_in'] - groupObject[_key][_member]['material_cost_money'])
          groupObject[_key][_member]['slid_money_num'] = groupObject[_key][_member]['slid_money'] / groupObject[_key][_member]['sold_num']
        }
      }

      return groupObject
    },

    drawMarkLineAreaTitle(funParams) {
      const _params = funParams || {}
      const screenOffset = 10
      const type = _params.type || 0
      const posX = _params.x || 0
      const posY = _params.y || 0
      //Ⅰ、Ⅱ、Ⅲ、Ⅳ、Ⅴ、Ⅵ、Ⅶ、Ⅷ、Ⅸ、Ⅹ、Ⅺ、Ⅻ
      const YMAX = this.yMax
      const minY = !this.minY ? 0 : Math.abs(this.minY)
      const minX = !this.minX ? 0 : Math.abs(this.minX)
      const areaSize = 100
      const markArea = []
      let analysis_type = ''
      if (this.minX > 0 && this.minY > 0) {
        analysis_type = 'setting_fore_x>0_y>0'
        markArea.push([
          { name: 'Ⅰ', coord: [0, minY], analysis_type: analysis_type, areaIndex: 1, areaXY: [0, 0] },
          { name: '', coord: [0, minY], areaXY: [this.minX, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅱ', coord: [minX, minY], analysis_type: analysis_type, areaIndex: 2, areaXY: [this.minX, 0] },
          { name: '', coord: [minX, minY], areaXY: [this.xRange, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅲ', coord: [minX, YMAX], analysis_type: analysis_type, areaIndex: 3, areaXY: [this.minX, this.minY] },
          { name: '', coord: [minX, YMAX], areaXY: [this.xRange, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅳ', coord: [0, YMAX], analysis_type: analysis_type, areaIndex: 4, areaXY: [0, this.minY] },
          { name: '', coord: [0, YMAX], areaXY: [this.minX, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅵ', coord: [this.xRange * -1 + screenOffset, YMAX], analysis_type: analysis_type, areaIndex: 6, areaXY: [0, this.minY] },
          { name: '', coord: [this.xRange * -1 + screenOffset, YMAX], areaXY: [this.xRange * -1, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅴ', coord: [this.xRange * -1 + screenOffset, minY], analysis_type: analysis_type, areaIndex: 5, areaXY: [0, this.minY] },
          { name: '', coord: [this.xRange * -1 + screenOffset, minY], areaXY: [this.xRange * -1, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅸ', coord: [this.xRange * -1 + screenOffset, 0 * -1], analysis_type: analysis_type, areaIndex: 9, areaXY: [0, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, 0 * -1], areaXY: [this.xRange * -1, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅶ', coord: [0, 0], analysis_type: analysis_type, areaIndex: 7, areaXY: [0, 0] },
          { name: '', coord: [0, 0], areaXY: [this.minX, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅷ', coord: [minX, 0], analysis_type: analysis_type, areaIndex: 8, areaXY: [this.minX, 0] },
          { name: '', coord: [minX, 0], areaXY: [this.xRange, YMAX * -1] }
        ])
      } else if (this.minX == 0 && this.minY > 0) {
        analysis_type = 'setting_fore_x=0_y>0'
        markArea.push([
          { name: 'Ⅰ', coord: [0, minY], analysis_type: analysis_type, areaIndex: 1, areaXY: [0, 0] },
          { name: '', coord: [0, minY], areaXY: [this.xRange, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅱ', coord: [0, YMAX], analysis_type: analysis_type, areaIndex: 2, areaXY: [0, this.minY] },
          { name: '', coord: [0, YMAX], areaXY: [this.xRange, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅲ', coord: [this.xRange * -1 + screenOffset, minY], analysis_type: analysis_type, areaIndex: 3, areaXY: [0, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, minY], areaXY: [this.xRange * -1, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅳ', coord: [this.xRange * -1 + screenOffset, YMAX], analysis_type: analysis_type, areaIndex: 4, areaXY: [0, this.minY] },
          { name: '', coord: [this.xRange * -1 + screenOffset, YMAX], areaXY: [this.xRange * -1, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅴ', coord: [0, 0], analysis_type: analysis_type, areaIndex: 5, areaXY: [0, 0] },
          { name: '', coord: [0, 0], areaXY: [this.xRange, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅵ', coord: [this.xRange * -1 + screenOffset, 0], analysis_type: analysis_type, areaIndex: 6, areaXY: [0, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, 0], areaXY: [this.xRange * -1, YMAX * -1] }
        ])
      } else if (this.minX < 0 && this.minY > 0) {
        analysis_type = 'setting_fore_x<0_y>0'
        markArea.push([
          { name: 'Ⅰ', coord: [0, minY], analysis_type: analysis_type, areaIndex: 1, areaXY: [0, 0] },
          { name: '', coord: [0, minY], areaXY: [this.xRange, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅱ', coord: [0, YMAX], analysis_type: analysis_type, areaIndex: 2, areaXY: [0, this.minY] },
          { name: '', coord: [0, YMAX], areaXY: [this.xRange, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅲ', coord: [this.minX, minY], analysis_type: analysis_type, areaIndex: 3, areaXY: [0, 0] },
          { name: '', coord: [this.minX, minY], areaXY: [this.minX, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅳ', coord: [this.minX, YMAX], analysis_type: analysis_type, areaIndex: 4, areaXY: [0, this.minY] },
          { name: '', coord: [this.minX, YMAX], areaXY: [this.minX, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅴ', coord: [this.xRange * -1 + screenOffset, YMAX], analysis_type: analysis_type, areaIndex: 5, areaXY: [this.minX, this.minY] },
          { name: '', coord: [this.xRange * -1 + screenOffset, YMAX], areaXY: [this.xRange * -1, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅵ', coord: [this.xRange * -1 + screenOffset, minY], analysis_type: analysis_type, areaIndex: 6, areaXY: [this.minX, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, minY], areaXY: [this.xRange * -1, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅶ', coord: [0, 0], analysis_type: analysis_type, areaIndex: 7, areaXY: [0, 0] },
          { name: '', coord: [0, 0], areaXY: [this.xRange, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅷ', coord: [this.minX, 0], analysis_type: analysis_type, areaIndex: 8, areaXY: [0, 0] },
          { name: '', coord: [this.minX, 0], areaXY: [this.minX, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅸ', coord: [this.xRange * -1 + screenOffset, 0], analysis_type: analysis_type, areaIndex: 9, areaXY: [this.minX, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, 0], areaXY: [this.xRange * -1, YMAX * -1] }
        ])
      } else if (this.minX > 0 && this.minY == 0) {
        analysis_type = 'setting_fore_x>0_y=0'
        markArea.push([
          { name: 'Ⅰ', coord: [0, YMAX], analysis_type: analysis_type, areaIndex: 1, areaXY: [0, 0] },
          { name: '', coord: [0, YMAX], areaXY: [this.minX, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅱ', coord: [this.minX, YMAX], analysis_type: analysis_type, areaIndex: 2, areaXY: [this.minX, 0] },
          { name: '', coord: [this.minX, YMAX], areaXY: [this.xRange, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅲ', coord: [this.xRange * -1 + screenOffset, YMAX], analysis_type: analysis_type, areaIndex: 3, areaXY: [0, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, YMAX], areaXY: [this.xRange * -1, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅳ', coord: [0, 0], analysis_type: analysis_type, areaIndex: 4, areaXY: [0, 0] },
          { name: '', coord: [0, 0], areaXY: [this.minX, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅴ', coord: [this.minX, 0], analysis_type: analysis_type, areaIndex: 5, areaXY: [this.minX, 0] },
          { name: '', coord: [this.minX, 0], areaXY: [this.xRange, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅵ', coord: [this.xRange * -1 + screenOffset, 0], analysis_type: analysis_type, areaIndex: 6, areaXY: [0, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, 0], areaXY: [this.xRange * -1, YMAX * -1] }
        ])
      } else if (this.minX < 0 && this.minY == 0) {
        analysis_type = 'setting_fore_x<0_y=0'
        markArea.push([
          { name: 'Ⅰ', coord: [0, YMAX], analysis_type: analysis_type, areaIndex: 1, areaXY: [0, 0] },
          { name: '', coord: [0, YMAX], areaXY: [this.xRange, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅱ', coord: [this.minX, YMAX], analysis_type: analysis_type, areaIndex: 2, areaXY: [0, 0] },
          { name: '', coord: [this.minX, YMAX], areaXY: [this.minX, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅲ', coord: [this.xRange * -1 + screenOffset, YMAX], analysis_type: analysis_type, areaIndex: 3, areaXY: [this.minX, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, YMAX], areaXY: [this.xRange * -1, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅳ', coord: [0, 0], analysis_type: analysis_type, areaIndex: 4, areaXY: [0, 0] },
          { name: '', coord: [0, 0], areaXY: [this.xRange, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅴ', coord: [this.minX, 0], analysis_type: analysis_type, areaIndex: 5, areaXY: [0, 0] },
          { name: '', coord: [this.minX, 0], areaXY: [this.minX, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅵ', coord: [this.xRange * -1 + screenOffset, 0], analysis_type: analysis_type, areaIndex: 6, areaXY: [this.minX, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, 0], areaXY: [this.xRange * -1, YMAX * -1] }
        ])
      } else if (this.minX > 0 && this.minY < 0) {
        analysis_type = 'setting_fore_x>0_y<0'
        markArea.push([
          { name: 'Ⅰ', coord: [0, YMAX], analysis_type: analysis_type, areaIndex: 1, areaXY: [0, 0] },
          { name: '', coord: [0, YMAX], areaXY: [this.minX, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅱ', coord: [this.minX, YMAX], analysis_type: analysis_type, areaIndex: 2, areaXY: [this.minX, 0] },
          { name: '', coord: [this.minX, YMAX], areaXY: [this.xRange, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅲ', coord: [this.xRange * -1 + screenOffset, YMAX], analysis_type: analysis_type, areaIndex: 3, areaXY: [0, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, YMAX], areaXY: [this.xRange * -1, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅳ', coord: [0, 0], analysis_type: analysis_type, areaIndex: 4, areaXY: [0, 0] },
          { name: '', coord: [0, 0], areaXY: [this.minX, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅴ', coord: [0, this.minY], analysis_type: analysis_type, areaIndex: 5, areaXY: [0, this.minY] },
          { name: '', coord: [0, this.minY], areaXY: [this.minX, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅵ', coord: [this.minX, this.minY], analysis_type: analysis_type, areaIndex: 6, areaXY: [this.minX, this.minY] },
          { name: '', coord: [this.minX, this.minY], areaXY: [this.xRange, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅶ', coord: [this.minX, 0], analysis_type: analysis_type, areaIndex: 7, areaXY: [this.minX, 0] },
          { name: '', coord: [this.minX, 0], areaXY: [this.xRange, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅷ', coord: [this.xRange * -1 + screenOffset, 0], analysis_type: analysis_type, areaIndex: 8, areaXY: [0, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, 0], areaXY: [this.xRange * -1, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅸ', coord: [this.xRange * -1 + screenOffset, this.minY], analysis_type: analysis_type, areaIndex: 9, areaXY: [0, this.minY] },
          { name: '', coord: [this.xRange * -1 + screenOffset, this.minY], areaXY: [this.xRange * -1, YMAX * -1] }
        ])
      } else if (this.minX == 0 && this.minY < 0) {
        analysis_type = 'setting_fore_x=0_y<0'
        markArea.push([
          { name: 'Ⅰ', coord: [0, YMAX], analysis_type: analysis_type, areaIndex: 1 },
          { name: '', coord: [0, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅱ', coord: [this.xRange * -1 + screenOffset, YMAX], analysis_type: analysis_type, areaIndex: 2 },
          { name: '', coord: [this.xRange * -1 + screenOffset, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅲ', coord: [0, 0], analysis_type: analysis_type, areaIndex: 3 },
          { name: '', coord: [0, 0] }
        ])
        markArea.push([
          { name: 'Ⅳ', coord: [0, this.minY], analysis_type: analysis_type, areaIndex: 4 },
          { name: '', coord: [0, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅴ', coord: [this.xRange * -1 + screenOffset, 0], analysis_type: analysis_type, areaIndex: 5 },
          { name: '', coord: [this.xRange * -1 + screenOffset, 0] }
        ])
        markArea.push([
          { name: 'Ⅵ', coord: [this.xRange * -1 + screenOffset, this.minY], analysis_type: analysis_type, areaIndex: 6 },
          { name: '', coord: [this.xRange * -1 + screenOffset, this.minY] }
        ])
      } else if (this.minX < 0 && this.minY < 0) {
        analysis_type = 'setting_fore_x<0_y<0'
        markArea.push([
          { name: 'Ⅰ', coord: [0, YMAX], analysis_type: analysis_type, areaIndex: 1 },
          { name: '', coord: [0, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅱ', coord: [this.minX, YMAX], analysis_type: analysis_type, areaIndex: 2 },
          { name: '', coord: [this.minX, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅲ', coord: [this.xRange * -1 + screenOffset, YMAX], analysis_type: analysis_type, areaIndex: 3 },
          { name: '', coord: [this.xRange * -1 + screenOffset, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅳ', coord: [0, 0], analysis_type: analysis_type, areaIndex: 4 },
          { name: '', coord: [0, 0] }
        ])
        markArea.push([
          { name: 'Ⅴ', coord: [0, this.minY], analysis_type: analysis_type, areaIndex: 5 },
          { name: '', coord: [0, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅵ', coord: [this.minX, 0], analysis_type: analysis_type, areaIndex: 6 },
          { name: '', coord: [this.minX, 0] }
        ])
        markArea.push([
          { name: 'Ⅶ', coord: [this.xRange * -1 + screenOffset, 0], analysis_type: analysis_type, areaIndex: 7 },
          { name: '', coord: [this.xRange * -1 + screenOffset, 0] }
        ])
        markArea.push([
          { name: 'Ⅷ', coord: [this.xRange * -1 + screenOffset, this.minY], analysis_type: analysis_type, areaIndex: 8 },
          { name: '', coord: [this.xRange * -1 + screenOffset, this.minY] }
        ])
        markArea.push([
          { name: 'Ⅸ', coord: [this.minX, this.minY], analysis_type: analysis_type, areaIndex: 9 },
          { name: '', coord: [this.minX, this.minY] }
        ])
      } else {
        analysis_type = 'setting_fore_x=0_y=0'
        markArea.push([
          { name: 'Ⅰ', coord: [0, YMAX], analysis_type: analysis_type, areaIndex: 1, areaXY: [0, 0] },
          { name: '', coord: [0, YMAX], areaXY: [this.xRange, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅱ', coord: [this.xRange * -1, YMAX], analysis_type: analysis_type, areaIndex: 2, areaXY: [0, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, YMAX], areaXY: [this.xRange * -1, YMAX] }
        ])
        markArea.push([
          { name: 'Ⅳ', coord: [this.xRange * -1, 0], analysis_type: analysis_type, areaIndex: 4, areaXY: [0, 0] },
          { name: '', coord: [this.xRange * -1 + screenOffset, 0], areaXY: [this.xRange * -1, YMAX * -1] }
        ])
        markArea.push([
          { name: 'Ⅲ', coord: [0, 0], analysis_type: analysis_type, areaIndex: 3, areaXY: [0, 0] },
          { name: '', coord: [0, 0], areaXY: [this.xRange, YMAX * -1] }
        ])
      }

      if (type) {
        let areaName = ''
        for (let i in markArea) {
          const areaItem = markArea[i]
          const areaItemFirst = areaItem[0] || {}
          const areaItemName = areaItemFirst.name || ''
          const areaItemFirstCorrd = areaItemFirst.areaXY || []
          const areaItemFirstCorrdX = areaItemFirstCorrd[0]
          const areaItemFirstCorrdY = areaItemFirstCorrd[1]

          const areaItemSecond = areaItem[1] || {}
          const areaItemSecondCorrd = areaItemSecond.areaXY || []
          const areaItemSecondCorrdX = areaItemSecondCorrd[0]
          const areaItemSecondCorrdY = areaItemSecondCorrd[1]

          if (
            ((posX >= 0 && posX >= areaItemFirstCorrdX && posX <= areaItemSecondCorrdX) || (posX <= 0 && posX <= areaItemFirstCorrdX && posX >= areaItemSecondCorrdX)) &&
            ((posY >= 0 && posY >= areaItemFirstCorrdY && posY <= areaItemSecondCorrdY) || (posY <= 0 && posY <= areaItemFirstCorrdY && posY >= areaItemSecondCorrdY))
          ) {
            areaName = areaItemName
            break
          }
        }
        return areaName
      }

      this.echartObj().echart.setOption({
        series: [
          {
            markArea: {
              label: { distance: 10, position: 'insideTopLeft', color: '#000', fontSize: 24 },
              data: markArea,
              emphasis: {
                label: { position: 'insideTopLeft', fontSize: 48 }
              }
            }
          }
        ]
      })
      //象限变化
      this.$emit('analysisTypeChange', analysis_type)
    },
    drawEchart() {
      const defAreaColor = '#1296db'
      const defAreaNameColor = {
        Ⅰ: '#37A2DA',
        Ⅱ: '#e06343',
        Ⅲ: '#37a354',
        Ⅳ: '#b55dba',
        Ⅴ: '#b5bd48',
        Ⅵ: '#8378EA',
        Ⅶ: '#96BFFF',
        Ⅷ: '#f4ea2a',
        Ⅸ: '#1afa29'
      }

      const option = {
        grid: this.isMobile
          ? { left: 30, top: 30 }
          : // { left: this.screen === 'landscape' ? '12%' : '10%', top: (this.screen = 'landscape' ? '9%' : '10%') }
            {
              left: '10%',
              top: '10%'
            },
        tooltip: {
          trigger: 'item',
          triggerOn: 'click',
          formatter: (params, ticket, callback) => {
            const componentType = params.componentType || ''
            if (componentType === 'markArea') {
              const paramsData = params.data || {}
              const analysis_type = paramsData.analysis_type || ''
              const areaIndex = (paramsData.areaIndex || 0) - 1
              const analysisList = this.analySisObject[analysis_type] || {}
              const info = analysisList[areaIndex] || {}
              this.introTitle = paramsData.name
              this.introContent = info.intro || ''
              this.openIntro = true
              return null
              // return `<div style="width:400px;max-height:200px;white-space:pre-wrap;overflow-y:auto">${info.intro || ''}</div>`
            } else {
              return null
            }
          },

          textStyle: {
            fontSize: this.isMobile ? 10 : chartNowSize(14)
          }
        },
        xAxis: {
          axisLabel: {
            textStyle: {
              fontSize: this.isMobile ? 10 : chartNowSize(14)
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },

          min: -this.xRange,
          max: this.xRange
        },
        yAxis: {
          axisLabel: {
            textStyle: {
              fontSize: this.isMobile ? 10 : chartNowSize(14)
            }
          },

          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
          min: this.yMax * -1,
          max: this.yMax
        },
        toolbox: {
          show: true,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          itemSize: this.isMobile ? 10 : chartNowSize(14),
          feature: {
            dataZoom: {},
            brush: {
              type: ['rect', 'polygon', 'clear']
            }
          }
        },

        dataZoom: [
          {
            type: 'slider'
          },
          {
            type: 'inside'
          }
        ],
        series: [
          {
            data: this.echartData,
            type: 'scatter',
            label: {
              textStyle: {
                fontSize: this.isMobile ? 10 : chartNowSize(14)
              }
            },
            symbolSize: (data) => {
              const size = !this.sizeVaules ? 0.05 : Math.abs(data[3]) / this.sizeVaules
              // return (size <= 0.1 ? 0.1 : size) * 100
              return this.isMobile ? (size <= 0.1 ? 0.1 : size) * 50 : (size <= 0.05 ? 0.05 : size) * 100
            },
            emphasis: {
              focus: 'series',
              label: {
                show: true,
                textStyle: {
                  fontSize: this.isMobile ? 10 : chartNowSize(14)
                },
                formatter: (param) => {
                  const data = param.data || []
                  const dataIndex = param.dataIndex

                  return `
                          ${this.getPlanName} : ${(this.echartData[dataIndex] || [])[2]}\n
                          ${this.getTypeName} : ${dealThousands(data[3])}\n
                         `

                  // return this.getTypeName + ':' + (this.echartData[dataIndex][2] || '') + '\r\n' + data[0]
                },
                position: 'top'
                // distance: 10,
                // align: 'left'
              }
            },
            itemStyle: {
              color: (params) => {
                // console.log(params)
                const value = params.value || []
                const x = value[0]
                const y = value[1]
                const areaName = this.drawMarkLineAreaTitle({ type: 'itemColor', x: x, y: y })
                // console.log('-++-', x, y, areaName)
                return defAreaNameColor[areaName] || defAreaColor
              }
              // shadowBlur: 10,
              // shadowColor: 'rgba(120, 36, 50, 0.5)',
              // shadowOffsetY: 5,
              // color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
              // {
              //     offset: 0,
              //     color: 'rgb(251, 118, 123)'
              // },
              // {
              //     offset: 1,
              //     color: 'rgb(204, 46, 72)'
              // }
              // ])
            }
          },
          {
            name: 'minX',
            type: 'line',
            nameTextStyle: {
              fontSize: this.isMobile ? 10 : chartNowSize(14)
            },
            label: {
              textStyle: {
                fontSize: this.isMobile ? 10 : chartNowSize(14)
              }
            },
            markLine: {
              lineStyle: { width: 1, color: '#ff0000' },
              yAxisIndex: 0,
              symbol: 'none', //去掉箭头
              data: [{ name: '预测', xAxis: this.minX }]
            }
          },
          {
            name: 'minY',
            type: 'line',
            nameTextStyle: {
              fontSize: this.isMobile ? 10 : chartNowSize(14)
            },
            label: {
              textStyle: {
                fontSize: this.isMobile ? 10 : chartNowSize(14)
              }
            },
            markLine: {
              lineStyle: { width: 1, color: '#ff0000' },
              yAxisIndex: 0,
              symbol: 'none', //去掉箭头
              data: [{ name: '预测', yAxis: this.minY }]
            }
          },
          {
            name: 'markArea',
            type: 'line',
            nameTextStyle: {
              fontSize: this.isMobile ? 10 : chartNowSize(14)
            },
            markArea: {
              label: { position: 'inside' },
              data: []
            },
            label: {
              textStyle: {
                fontSize: this.isMobile ? 10 : chartNowSize(14)
              }
            }
          }
        ]
      }

      // 释放资源
      this.echartObj().echart.setOption(option)
      this.initEchart = true
      // this.echartObj().echart.on('click', (params) => {
      //   // params 包含了点击事件的信息
      //   if (params.componentType === 'series') {
      //     // 确保点击的是数据点
      //     const dataIndex = params.dataIndex
      //     let value = params.value // 获取数据点的值，对于散点图，通常是一个包含x和y坐标的数组
      //     let x = value[0] // x坐标
      //     let y = value[1] // y坐标
      //     let labelAlign = 'center'
      //     let labelVerticalAlign = 'middle'
      //     let labelDistance = 10

      //     if (x > 0 && y > 0) {
      //       this.labelPosition = 'insideBottomRight'
      //       // this.labelPosition = 'right'
      //       labelAlign = 'right'
      //       labelVerticalAlign = 'bottom'
      //     } else if (x > 0 && y < 0) {
      //       this.labelPosition = 'insideTopRight'
      //       // this.labelPosition = 'right'
      //       labelAlign = 'right'
      //       labelVerticalAlign = 'top'
      //     } else if (x < 0 && y > 0) {
      //       this.labelPosition = 'insideBottomLeft'
      //       // this.labelPosition = 'left'
      //       labelAlign = 'left'
      //       labelVerticalAlign = 'bottom'
      //     } else {
      //       this.labelPosition = 'insideTopLeft'
      //       // this.labelPosition = 'left'
      //       labelAlign = 'left'
      //       labelVerticalAlign = 'top'
      //     }

      //     // 更新图表配置
      //     this.echartObj().echart.setOption({
      //       series: [
      //         {
      //           emphasis: {
      //             label: {
      //               position: this.labelPosition,
      //               align: labelAlign,
      //               distance: labelDistance
      //             }
      //           }
      //         }
      //       ]
      //     })
      //   }
      // })
    },
    getPrintInfo() {
      return {
        typeName: this.getTypeName,
        planName: this.getPlanName,
        img: this.echartObj().echart.getDataURL('png')
      }
    }
  }
}
</script>

<style lang="less">
.cloud4-echart {
  &.cloud4_echart_mobile {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .svp_echart_form {
      // height: 120px;
      width: 100%;
      // width: 25%;
      .ant-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .ant-form-item {
          // margin: 0 2px;
          flex: 0 0 48%; /* 或者使用calc(50% - 20px)来为间隔留出空间 */
          margin-bottom: 5px;
          .ant-form-item-label {
            padding-bottom: 0px;
            width: 50px;
            label {
              font-size: 12px;
              height: 20px;
            }
          }
          .ant-select .ant-select-selector {
            .ant-select-selection-item,
            .ant-select-selection-search-input {
              font-size: 12px;
            }
          }
        }
      }
    }

    .echart_svp {
      flex: 1;
    }
  }
  &.cloud4_echart_pc {
    .echart_svp {
      height: 395px;
    }
  }
}
</style>