<template>
  <div class="import scroll">
    <div class="body">
      <div class="vabTabsBox">
        <div class="title">{{ detail.name }}-导入文件</div>
      </div>
      <div class="children">
        <div>
          <a-radio-group v-model:value="way" button-style="solid">
            <a-radio-button value="stockProduct">产成品/库存商品结转</a-radio-button>
            <a-radio-button value="sendProduct">发出商品结转</a-radio-button>
          </a-radio-group>
        </div>
        <a-alert message="请下载 服务器导入模板（.xlsx）然后按照模板的要求依次顺序上传文件" type="info" />

        <div class="import-box">
          <a-row :gutter="48">
            <template v-for="(item, index) in stepList">
              <a-col class="gutter-row" :xs="24" :sm="12" :md="12" :lg="8" :xl="6" v-if="item.limitWay.indexOf(way) > -1">
                <ToolsImport
                  localParse
                  xlsxMoreSheet
                  type="three"
                  :item="item"
                  :index="index"
                  :uploadData="getUploadInfo"
                  @handleChange="handleChange($event, item)"
                  @handleHisFile="handleHisFile(item, index)"
                  :beforeUpload="(file, fileList) => onBeforeUpload(file, fileList, item)"
                />
              </a-col>
            </template>
          </a-row>
        </div>

        <div class="import-btns">
          <!-- <OpenVipBtn content="获取基础方案" v-if="!getHasToolAuth('tools3_base')" /> 
                    <a-button type="primary" :loading="createPlanLoading" @click="handleSavePlan('base','基础方案')" v-else> 获取基础方案 </a-button> -->

          <OperateLimit type="primary" :loading="createPlanLoading" @doClick="handleSavePlan('base', '基础方案')" useTool="chfxyyc"> 获取基础方案 </OperateLimit>

          <OperateLimit type="primary" :loading="createPlanLoading" @doClick="handleSavePlan('extra', '扩展方案')" useTool="chfxyyc"> 获取扩展方案 </OperateLimit>
        </div>
      </div>
    </div>

    <HistoryFiles ref="historyFiles" @selectItem="onSelectItem" />
  </div>
</template>

<script>
import { message, notification } from 'ant-design-vue'

import HistoryFiles from '../components/HistoryFiles.vue'
import ToolsImport from '@/components/ToolsImport.vue'
import OpenVipBtn from '@/components/OpenVipBtn.vue'
import { mapGetters } from 'vuex'

import { baseURL, download } from '@/utils/util'

export default {
  components: {
    HistoryFiles,
    ToolsImport,
    OpenVipBtn
  },
  data() {
    return {
      detail: {},
      stepList: [
        { name: '材料收发存报表', temppath: 'tools3/材料收发存报表.xlsx', temp: 'step3-material', file: {}, verifyIndex: -1, uploading: '', limitWay: ['stockProduct', 'sendProduct'] },
        { name: '成品/库存商品收发存报表', temppath: 'tools3/成品_库存商品收发存报表.xlsx', temp: 'step3-stock', file: {}, verifyIndex: -1, uploading: '', limitWay: ['stockProduct', 'sendProduct'] },
        { name: '发出商品收发存报表', temppath: 'tools3/发出商品收发存报表.xlsx', temp: 'step3-send-product', file: {}, verifyIndex: -1, uploading: '', limitWay: ['sendProduct'] }
      ],
      way: 'stockProduct',
      selectItemIndex: 0,

      createPlanLoading: false
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getUploadInfo() {
      let params = {}
      for (let i = 0; i < this.stepList.length; i++) {
        const info = this.stepList[i]
        if (!info.file || !('id' in info.file)) {
        } else {
          params[info.temp] = info.file.id
        }
      }
      return params
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$network('/api/tools/typeDetail', { type: 'three' }).then((res) => {
        const data = res.data || {}
        this.detail = data.detail || {}
      })
    },
    onBeforeUpload(file, fileList, item) {
      const verifyIndex = item.verifyIndex
      if (verifyIndex > -1) {
        const verifyItem = this.stepList[verifyIndex]
        if (!verifyItem.file.id) {
          notification.error({ description: `请先上传方案【${verifyItem.name}】` })
          return Promise.reject()
        }
      }
    },
    handleChange(info, item) {
      const status = info.file.status
      item.uploading = status

      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        const response = info.file.response || {}
        const responseData = response.data || {}
        // console.log(response)
        // console.log(responseData)
        if (!response.code) {
          notification.error({ description: response.msg })
          // message.error(response.msg);
        } else {
          item.file = responseData.info || {}
          notification.success({ description: `${item.name} 上传成功.` })
          // message.success(`${info.file.name} file uploaded successfully.`);
        }
      } else if (status === 'error') {
        notification.error({ description: `${item.name} 上传失败.` })
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    //选中了哪个文件
    onSelectItem(file) {
      this.stepList[this.selectItemIndex].file = file
    },
    // 历史文件
    handleHisFile(item, index) {
      this.selectItemIndex = index
      this.$refs.historyFiles.open(item)
    },
    handleSavePlan(planType = 'base', planName) {
      const params = []
      for (let i = 0; i < this.stepList.length; i++) {
        const info = this.stepList[i]
        // if(info.limitWay.indexOf(this.way)>-1 ){
        //     if(!info.file || !('id' in info.file)){
        //         notification.error({  description:  `请上传方案【${info.name}】`});
        //         // message.error(`请上传方案【${info.name}】`);
        //         return;
        //     }
        // }
        if (info.file.id) {
          params.push({ type: info.temp, id: info.file.id })
        }
      }
      if (!params.length) {
        notification.error({ description: `至少上传一个方案` })
        return
      }

      this.createPlan(params, planType)
    },

    // handleSavePlan(planType = 'base') {
    //   const params = []
    //   for (let i = 0; i < this.stepList.length; i++) {
    //     const info = this.stepList[i]

    //     if (!info.file || !('id' in info.file)) {
    //       if (info.uploadDef) {
    //         notification.error({ description: `请上传方案【${info.name}】` })
    //         // message.error(`请上传方案【${info.name}】`);
    //         return
    //       }
    //     }
    //     params.push({ type: info.temp, id: info.file.id })
    //   }

    //   this.createPlan(params, planType)
    // },
    createPlan(params, planType) {
      this.createPlanLoading = true
      this.$network('/api/tools/createPlan', { way: this.way, plan: params, tool_type: 'three' })
        .then((res) => {
          const data = res.data || {}
          this.$router.push({ path: '/dashboard/cloud3/solution', query: { id: data.id, planType: planType } })
        })
        .catch((err) => {})
        .finally(() => {
          this.createPlanLoading = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
</style>
