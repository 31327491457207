<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ThemeColor',
})
</script>
<template>
  <SettingItem title="主体颜色">
    <div style="height: 20px">
      <a-tooltip class="setting-drawer-theme-color-colorBlock" v-for="(item, index) in colorList" :key="index">
        <template #title>{{ item.key }}</template>
        <a-tag :color="item.color" @click="changeColor(item.color)">
          <CheckOutlined v-if="item.color === themeColor" />
        </a-tag>
      </a-tooltip>

      <!-- 自定义颜色 -->
      <a-popover title="自定义" overlayClassName="themeColorCustomColor" placement="bottomRight">
        <template #content>
          <ColorPicker @change="changeColor" format="hex" disableHistory disableAlpha />
        </template>
        <a-tag :color="isCustomColor ? themeColor : ''" class="setting-drawer-theme-color-colorBlock">
          <CheckOutlined v-if="isCustomColor" />
        </a-tag>
      </a-popover>
    </div>
  </SettingItem>

  <SettingItem title="背景底色">
    <div style="height: 20px">
      <a-tooltip class="setting-drawer-theme-color-colorBlock" v-for="(item, index) in colorList" :key="index">
        <template #title>{{ item.key }}</template>
        <a-tag :color="item.color" @click="changebgColor(item.color)">
          <CheckOutlined v-if="item.color === themebgColor" />
        </a-tag>
      </a-tooltip>
      <a-popover title="自定义" overlayClassName="themeColorCustomColor" placement="bottomRight">
        <template #content>
          <ColorPicker @change="changebgColor" format="hex" disableHistory disableAlpha />
        </template>
        <a-tag :color="isCustombgColor ? themebgColor : ''" class="setting-drawer-theme-color-colorBlock">
          <CheckOutlined v-if="isCustombgColor" />
        </a-tag>
      </a-popover>
    </div>
  </SettingItem>
</template>
<script lang="ts" setup name="ThemeColor">
import { computed } from 'vue'
import { systemConfig } from '@/store/reactiveState'
import { THEME_COLOR, THEME_BG_COLOR } from '@/store/mutation-types'
import { CheckOutlined } from '@ant-design/icons-vue'
import { colorList } from '../settingConfig'
import { updateTheme } from '../updateTheme'
import useSiteSettings from '@/store/useSiteSettings'
import SettingItem from './SettingItem.vue'
import ColorPicker from '@/components/ColorPicker/index.vue'
import baseService from '@/utils/http/axios'
const { themeColor, themebgColor } = useSiteSettings()

const changeColor = (color) => {
  saveToolThemeColor(color)
  systemConfig.commit(THEME_COLOR, color)
  // console.log(themeColor.value, '==========================themeColor')
  updateTheme(color)
}
const changebgColor = (color) => {
  systemConfig.commit(THEME_BG_COLOR, color)
  updateTheme(color)
}

//保存工具主题颜色
const saveToolThemeColor = (color) => {
  baseService.post('/api/user/modThemeColor', { color: color }).then((res) => {})
}
const colorArr = colorList.map((item) => item.color)
const isCustomColor = computed(() => {
  return !colorArr.includes(systemConfig.state.themeColor + '')
})
const isCustombgColor = computed(() => {
  return !colorArr.includes(systemConfig.state.themebgColor + '')
})
</script>
<style lang="less" scoped>
.setting-drawer-theme-color-colorBlock {
  width: 20px;
  height: 20px;
  border-radius: 2px;
  float: left;
  cursor: pointer;
  margin-right: 8px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  color: #fff;
  font-weight: 700;

  i {
    font-size: 14px;
  }
}
</style>
