<template>
  <div :class="isMobile ? 'energy' : ''">
    <!-- <a-watermark :content="waterName"> -->
    <!-- <a-select v-model:value="selectGroup" :options="options" mode="multiple" placeholder="Please select" @change="handleChange"> </a-select> -->
    <ToolsMoveTouch :position="position" @changePosion="onChangePosion">
      <div class="legend">
        <div class="item" :class="{ active: selectGroup.includes(item.value) }" v-for="(item, index) in options" :key="index" @click="handleSelect(item, index)">
          <span class="line"></span>
          <span>{{ item.label }}</span>
        </div>
      </div>
    </ToolsMoveTouch>

    <!-- <div :id="echartId" :style="style"></div> -->
    <div :id="echartId" :style="style" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd"></div>
    <!-- </a-watermark> -->
  </div>
</template>
<script>
import { isMobile, isTablet, isDesktop } from '@/utils/device'
import * as echarts from 'echarts'
import { message } from 'ant-design-vue'
import ToolsMoveTouch from '@/components/ToolsMoveTouch.vue'
import { chartNowSize } from '@/utils/util'
export default {
  name: 'EchartEnergyMobile',
  components: {
    ToolsMoveTouch
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: '桑基图'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    },
    waterName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectGroup: ['area', 'group', 'customer'],
      options: [
        { label: '产品编码', value: 'no' },
        { label: '产品线', value: 'line' },
        { label: '产品类别', value: 'type' },
        { label: '客户', value: 'customer' },
        { label: '区域', value: 'area' },
        { label: '业务组', value: 'group' }
      ],
      visible: false,
      echartId: 'echart-' + new Date().getTime() + '-' + Math.ceil(Math.random() * 10),
      nodeType: 'customer',
      echart: undefined,
      echartInit: false,
      echartData: {
        nodes: [],
        links: []
      },

      isMobile: isMobile,
      isTablet: isTablet,
      position: {},

      translateX: 0, // 初始平移位置X
      translateY: 0, // 初始平移位置Y
      startX: 0, // 初始触摸位置X
      startY: 0, // 初始触摸位置Y
      zoomScale: 1, // 初始缩放比例
      initialDistance: 0, // 初始触摸点距离

      isScaling: false // 是否处于缩放状态
    }
  },

  watch: {
    selectGroup: {
      handler(value) {
        this.handleEchart()
      }
    },
    data: {
      handler(value) {
        this.handleEchart()
      }
    },
    width: {
      immediate: true, //首次加载的时候执行函数
      handler: function (value) {
        const position = {}
        if (this.isMobile) {
          position.top = '10px'
          // position.left = this.width - 120 + 'px'
          position.right = '40px'
          position.transform = 'rotate(90deg)'
          // position.marginTop = '44px'
        } else {
          position.top = '10px'
          position.left = '20px'
        }
        this.position = position
      }
    },
    isMobile: {
      handler(value) {
        window.location.reload()
      }
    }
  },
  mounted() {
    // console.log('===============================isMobile', this.isMobile)
    this.echart = echarts.init(document.getElementById(this.echartId), null, {
      renderer: 'canvas',
      useDirtyRect: false
    })

    this.handleData()

    const echartContainer = document.getElementById(this.echartId)

    const self = this // 保存 this 的引用

    // 全局监听 touchend 事件
    document.addEventListener('touchend', function (event) {
      // 检查点击事件的目标是否是 ECharts 容器或其子元素
      let isInsideChart = echartContainer.contains(event.target)

      // 如果点击事件发生在 ECharts 容器之外，则隐藏 tooltip
      // if (!isInsideChart) {
      // 检查当前是否已经有 tooltip 显示

      self.echart.dispatchAction({
        type: 'hideTip'
      })
      // 取消所有系列的高亮
      self.echart.dispatchAction({
        type: 'downplay'
      })
      // }
    })

    document.getElementById(this.echartId).addEventListener(
      'touchmove',
      function (event) {
        event.preventDefault() // 阻止默认的滚动行为
      },
      { passive: false }
    ) // passive 设置为 false 是必须的

    echartContainer.addEventListener('touchstart', this.handleTouchStart)
    echartContainer.addEventListener('touchmove', this.handleTouchMove)
    echartContainer.addEventListener('touchend', this.handleTouchEnd)

    echartContainer.addEventListener(
      'touchmove',
      function (event) {
        event.preventDefault() // 阻止默认的滚动行为
      },
      { passive: false }
    ) // passive 设置为 false 是必须的

    // this.lockToLandscape()
    // window.addEventListener('orientationchange', this.lockToLandscape)
  },
  computed: {
    marginLeft() {
      return this.width / 2
    },
    style() {
      const style = {}
      if (this.isMobile) {
        style.width = this.width + 'px'
        style.height = this.height + 'px'
      } else {
        style.width = this.width + 'px'
        style.height = this.height + 'px'
        // style.transformOrigin = `${this.touchCenterX}px${this.touchCenterY}px`
      }
      return style
    }
  },

  methods: {
    handleTouchEnd(event) {
      // 可以在这里进行一些清理工作，比如重置初始距离
      // this.translateX = 0
      // this.translateY = 0
      // this.startX = 0
      // this.startY = 0
      // this.zoomScale = 1
      // this.initialDistance = 0
      // this.isScaling = false
      // 检查点击事件的目标是否是 ECharts 容器或其子元素
      let isInsideChart = this.echart.containPixel('grid', [event.clientX, event.clientY])

      if (isInsideChart) {
        if (this.isTooltipShown) {
          // 如果 tooltip 已经显示，则隐藏 tooltip
          this.echart.dispatchAction({
            type: 'hideTip'
          })
          this.isTooltipShown = false
        } else {
          // 如果 tooltip 没有显示，则显示所选数据的 tooltip
          let pointInPixel = [event.clientX, event.clientY]
          let pointInGrid = this.echart.convertFromPixel('grid', pointInPixel)

          // 这里需要根据你的图表数据来确定 seriesIndex 和 dataIndex
          // 通常你需要根据 pointInGrid 来获取对应的 seriesIndex 和 dataIndex
          let seriesIndex = 0 // 示例，需要根据实际情况获取正确的系列索引
          let dataIndex = 0 // 示例，需要根据实际情况获取正确的数据索引

          this.echart.dispatchAction({
            type: 'showTip',
            seriesIndex: seriesIndex,
            dataIndex: dataIndex
          })
          this.isTooltipShown = true
        }
      } else {
        // 如果点击事件发生在 ECharts 容器之外，则隐藏 tooltip
        this.echart.dispatchAction({
          type: 'hideTip'
        })
        this.isTooltipShown = false
        // 取消所有系列的高亮
        this.echart.dispatchAction({
          type: 'downplay'
        })
      }
    },
    // lockToLandscape() {
    //   const isPortrait = window.screen.orientation.type.includes('portrait')
    //   if (isPortrait) {
    //     // 尝试锁定屏幕方向为横屏
    //     // window.screen.orientation.lock('landscape').catch((error) => {
    //     //   console.error('Could not lock orientation', error)
    //     // })
    //   }
    // },
    handleTouchStart(event) {
      if (event.touches.length >= 2) {
        this.isScaling = true
        const touch1 = event.touches[0]
        const touch2 = event.touches[1]
        this.initialDistance = Math.sqrt((touch2.pageX - touch1.pageX) ** 2 + (touch2.pageY - touch1.pageY) ** 2)
      } else if (event.touches.length === 1) {
        // 平移操作
        this.isScaling = false
        const touch = event.touches[0]
        this.startX = touch.pageX
        this.startY = touch.pageY
      }
      // 防止默认行为和事件冒泡
      // event.preventDefault()
    },
    handleTouchMove(event) {
      if (this.isScaling && event.touches.length >= 2) {
        event.preventDefault()
        const touch1 = event.touches[0]
        const touch2 = event.touches[1]
        const currentDistance = Math.sqrt((touch2.pageX - touch1.pageX) ** 2 + (touch2.pageY - touch1.pageY) ** 2)
        const scale = currentDistance / this.initialDistance
        this.zoomScale *= scale
        this.initialDistance = currentDistance
        this.applyZoom()
      } else if (!this.isScaling && event.touches.length === 1) {
        // 平移操作
        const touch = event.touches[0]
        const deltaX = touch.pageX - this.startX
        const deltaY = touch.pageY - this.startY
        this.translateX += deltaX
        this.translateY += deltaY
        this.startX = touch.pageX
        this.startY = touch.pageY
        this.applyTransform()
      }
    },
    applyTransform() {
      const echartContainer = document.getElementById(this.echartId)
      echartContainer.style.transform = `translate(${this.translateX}px,${this.translateY}px) scale(${this.zoomScale})`
    },
    applyZoom() {
      const echartContainer = document.getElementById(this.echartId)
      echartContainer.style.transform = `scale(${this.zoomScale})`
    },

    onChangePosion(e) {
      // console.log('>>>>>?', e)
      this.position.top = e.top
      this.position.left = e.left
    },
    handleChange(e) {
      // console.log('>>>>>?', e)
    },
    handleSelect(item, index) {
      if (this.isSelected(item, index)) {
        // 如果项目已选中，则取消选中
        const selectedIndex = this.selectGroup.indexOf(item.value)
        this.selectGroup.splice(selectedIndex, 1)
      } else {
        // 如果项目未选中，则添加到选中列表中
        this.selectGroup.push(item.value)
      }
      this.handleEchart()
    },
    isSelected(item) {
      // console.log('item>>>', item.value)
      return this.selectGroup.includes(item.value)
    },

    handleEchart() {
      const that = this

      try {
        that.handleData()
        that.drawEchart()
      } catch (e) {
        console.error(e)
      }
    },

    handleData() {
      this.echartData.nodes = []
      this.echartData.links = []
      const selectGroupLen = this.selectGroup.length
      if (selectGroupLen <= 1) {
        return
      }
      const addNodesFnc = (type, name) => {
        const nodeIndex = this.echartData.nodes.findIndex((nodeItem) => {
          return nodeItem.type == type && nodeItem.name == name
        })
        if (nodeIndex === -1) {
          this.echartData.nodes.push({
            name: name,
            type: type
          })
        }
      }

      const addLinksFnc = (source, target, val) => {
        const nodeIndex = this.echartData.links.findIndex((nodeItem) => {
          return nodeItem.source == source && nodeItem.target == target
        })
        // console.log(source,target,nodeIndex)
        // if(nodeIndex===-1){
        this.echartData.links.push({
          source: source,
          target: target,
          value: val
        })
        // }else{
        //     this.echartData.links[nodeIndex].value += val*1
        // }
      }
      this.data.map((item) => {
        const _value = item.price_come_in || '' //不含税总收入

        for (let i = 0; i < selectGroupLen; i++) {
          const groupItem = this.selectGroup[i]
          const groupItem_next = this.selectGroup[i + 1] || ''
          addNodesFnc(groupItem, item[groupItem])

          if (groupItem_next) {
            addLinksFnc(item[groupItem], item[groupItem_next], _value)
          } else if (selectGroupLen === 1) {
            addLinksFnc(item[groupItem], '', _value)
          }
        }
      })
      console.log(this.echartData)
    },
    drawEchart() {
      const that = this
      const s_left = this.isMobile ? 10 : 100
      const s_right = this.isMobile ? 10 : 250
      const s_top = this.isMobile ? 100 : 0
      const s_bottom = this.isMobile ? 100 : 20
      const s_orient = this.isMobile ? 'vertical' : 'horizontal'

      const option = {
        title: {
          text: ''
        },
        tooltip: this.isMobile
          ? {
              trigger: 'item',
              triggerOn: 'mousemove',
              formatter: function (params) {
                // 创建一个临时的元素来计算文本长度
                var tempDiv = document.createElement('div')
                tempDiv.style.position = 'absolute' // 防止影响页面布局
                tempDiv.style.visibility = 'hidden' // 隐藏元素
                tempDiv.style.whiteSpace = 'nowrap' // 防止文本换行
                tempDiv.style.transform = 'rotate(90deg)' // 应用旋转样式
                tempDiv.style.fontSize = '14px' // 设置与tooltip相同的字体大小
                tempDiv.textContent = params.name // 设置文本内容

                // 将临时元素添加到文档中
                document.body.appendChild(tempDiv)
                // 读取文本的宽度
                var textWidth = tempDiv.offsetWidth
                // 从文档中移除临时元素
                document.body.removeChild(tempDiv)

                // 使用计算出的文本宽度设置tooltip的样式
                return `
              <div style="display: flex;
                          align-items: center;
                          flex-direction: column;height:auto">
                <div class="text1" style="transform: rotate(90deg);height:auto;width:20px">${params.name}</div>
                <div style="writing-mode: vertical-lr;padding-top:${textWidth}px">${params.value}</div>
              </div>
            `
              }
            }
          : {
              trigger: 'item',
              triggerOn: 'mousemove'
            },
        series: [
          {
            // roam: true,
            type: 'sankey',
            orient: s_orient,
            draggable: false,
            silent: false,
            left: s_left,
            top: s_top,
            right: s_right,
            bottom: s_bottom,
            nodeWidth: 20,
            nodeGap: 6,
            data: this.echartData.nodes,
            links: this.echartData.links,
            label: {
              show: true,
              autoRotate: true,
              fontSize: this.isMobile ? chartNowSize(26) : chartNowSize(12),
              offset: this.isMobile ? [0, -3] : [0, 0],
              rotate: this.isMobile ? 270 : 0,
              position: this.isMobile ? 'bottom' : 'right',
              align: 'left'
            },
            emphasis: {
              focus: 'adjacency'
            },
            lineStyle: {
              color: 'gradient',
              curveness: 0.5
            },
            layoutIterations: 32 // 增加迭代次数
          }
        ]
        // // 配置工具箱
        // toolbox: {
        //   feature: {
        //     dataZoom: {
        //       yAxisIndex: 'none' // 表示不控制 y 轴
        //     },
        //     brush: {
        //       type: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear']
        //     }
        //   }
        // },
        // // 启用数据区域缩放组件
        // dataZoom: [
        //   {
        //     type: 'slider', // 滑块型数据区域缩放组件
        //     start: 0,
        //     end: 100
        //   },
        //   {
        //     type: 'inside', // 内置型数据区域缩放组件
        //     start: 0,
        //     end: 100
        //   }
        // ]
      }
      // 释放资源
      this.echart.setOption(option)
    }
  },
  beforeDestroy() {
    // 清理事件监听器
    const echartContainer = document.getElementById(this.echartId)
    echartContainer.removeEventListener('touchstart', this.handleTouchStart)
    echartContainer.removeEventListener('touchmove', this.handleTouchMove)
    echartContainer.removeEventListener('touchend', this.handleTouchEnd)

    // window.removeEventListener('orientationchange', this.lockToLandscape)
  }
}
</script>

<style lang="less" scoped>
.energy {
  // height: 100%;
  // width: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}
.echartBox {
  // position: absolute;
  // transform-origin: center center;
}
.legend {
  width: 100px;
  height: 120px;
  .item {
    display: flex;
    align-items: center;
    margin: 15px 0px;
    .line {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      &::after {
        content: '';
        display: block;
        width: 23px;
        height: 2px;
        background-color: #aaa;
      }
      &::before {
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 8px;
        background-color: #fff;
        border: 1px solid #aaa;
        position: absolute;
      }
    }
    span {
      color: #999;
      font-size: 12px;
    }
    &.active {
      .line {
        &::after {
          border: 1px solid #1e6dff;
        }
        &::before {
        }
      }
      span {
        color: #333;
      }
    }
  }
}
</style>
