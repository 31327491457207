<template>
  <div class="solution scroll">
    <!-- 设置主题色 -->
    <setting-drawer ref="settingDrawer"></setting-drawer>

    <div class="solution-foot" id="PDF-foot">
      <div class="solution-foot-icon"><img src="/logo.png" class="logo" alt="logo" /></div>
      <div class="solution-foot-text">
        {{ getWaterName }}
      </div>
    </div>
    <div v-show="isPrint">
      <SolutionTemplatePDFCompare ref="SolutionTemplatePDFCompare" :detail="detail" :detailCompare="detailCompare" :remark="remark" />
    </div>
    <div class="bodys">
      <a-watermark :content="getWaterName" id="solution-compare">
        <div class="solution-box">
          <div class="headBox">
            <div class="headBoxBg">
              <img src="@/assets/images/solution_img.png" />
              <div class="headBoxLogo"><img :src="getPlanLogo" class="logo" alt="logo" /></div>
            </div>
            <div class="headBoxContent">
              <div class="headTitle">
                <div class="title">{{ getPlanName }}</div>
                <div class="subtitle">{{ getPlanName_en }}</div>
              </div>
              <div class="author">
                <div class="author_name">{{ getTitle }}</div>
                <div class="author_info">
                  <span>编制人：{{ userInfo.name }}</span>
                  <span>编制时间：{{ detail.created_at }}</span>
                  <span>打印次数：{{ detail.times }}次</span>
                </div>
              </div>
            </div>
            <div class="a-setting-btn" v-show="!isPrint">
              <a-button type="primary" @click="$refs.settingDrawer.open()">设置主题色</a-button>
            </div>
          </div>

          <div class="solution-wrap">
            <div class="solution-children">
              <div class="head">1.0 基本方案对比</div>
              <tools-item-intro type="one" field="intro_compare1" />
              <a-row :gutter="100">
                <a-col :sm="12">
                  <div class="subtitle text-center">{{ detail.name }}</div>
                  <SvpLine
                    ref="svpLineBefore"
                    echartId="svp-line-compare"
                    :excelData_yc="svp_tableData"
                    :columns_step_mix="svp_tableData_mix_columns || []"
                    :tableData_step_fixed="svp_tableData_fixed || []"
                    :tableData_step_mix="svp_tableData_mix || []"
                  />
                </a-col>
                <a-col :sm="12">
                  <div class="subtitle text-center">{{ detailCompare.name }}</div>
                  <SvpLine
                    ref="svpLineAfter"
                    echartId="svp-line-compare-other"
                    :excelData_yc="svp_tableData_compare"
                    :columns_step_mix="svp_tableData_mix_columns_compare || []"
                    :tableData_step_fixed="svp_tableData_fixed_compare || []"
                    :tableData_step_mix="svp_tableData_mix_compare || []"
                  />
                </a-col>
              </a-row>
            </div>
            <div class="solution-children">
              <div class="head">1.1 方案对比差异</div>
              <tools-item-intro type="one" field="intro_compare2" />
              <a-row :gutter="80">
                <a-col :sm="7">
                  <a-descriptions :column="1" size="small" bordered :labelStyle="{ width: '165px', minWidth: '165px' }">
                    <a-descriptions-item label="总收入" :contentStyle="{ textAlign: 'right' }"> {{ $dealThousands(diff_sum_price_come_in) }} </a-descriptions-item>
                    <a-descriptions-item label="总成本" :contentStyle="{ textAlign: 'right' }"> {{ $dealThousands(diff_material_cost_money) }} </a-descriptions-item>
                    <a-descriptions-item label="变动成本" :contentStyle="{ textAlign: 'right' }"> {{ $dealThousands(diff_material_cost) }} </a-descriptions-item>
                    <a-descriptions-item label="总固定成本" :contentStyle="{ textAlign: 'right' }">{{ $dealThousands(diff_fixed_money) }}</a-descriptions-item>
                    <a-descriptions-item label="总边际贡献" :contentStyle="{ textAlign: 'right' }"> {{ $dealThousands(diff_side_money) }} </a-descriptions-item>
                    <a-descriptions-item label="毛利润" :contentStyle="{ textAlign: 'right' }">{{ $dealThousands(diff_maoli) }}</a-descriptions-item>
                    <a-descriptions-item label="管理利润" :contentStyle="{ textAlign: 'right' }">{{ $dealThousands(diff_manager_rate) }}</a-descriptions-item>
                    <a-descriptions-item label="预测" :contentStyle="{ textAlign: 'right' }">{{ $dealThousands(diff_sold_num) }}</a-descriptions-item>
                    <a-descriptions-item :contentStyle="{ textAlign: 'right' }">
                      <template #label>
                        <a-tooltip @click="showMixMenu = !showMixMenu">
                          总混合成本
                          <CaretDownOutlined v-show="showMixMenu" />
                          <CaretUpOutlined v-show="!showMixMenu" />
                        </a-tooltip>
                      </template>
                      {{ diff_mix_money }}
                    </a-descriptions-item>
                    <template v-for="(item, index) in showMixMenu ? diffMixGroup : []">
                      <a-descriptions-item :label="item.title" :contentStyle="{ textAlign: 'right' }">{{ $dealThousands(item.diffMoney) }}</a-descriptions-item>
                    </template>
                  </a-descriptions>
                </a-col>
                <a-col :sm="17">
                  <a-table
                    ref="baseTable"
                    class="cross-table"
                    :pagination="svpPagination"
                    :columns="svpColumns"
                    :data-source="svpTableData"
                    :scroll="{}"
                    centered
                    :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                    size="small"
                    bordered
                    @resizeColumn="
                      (w, col) => {
                        col.width = w
                      }
                    "
                    @change="(pagination, filters, sorter, e) => onTableChange(null, pagination, filters, sorter, e)"
                  >
                    <template #bodyCell="{ column, text, record, index }">
                      <!-- 边际收益 -->
                      <template v-if="column.dataIndex === 'slid_money_result'">
                        <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                          <text :class="{ 'text-red': text * 1 < 0 }">{{ $dealThousands(text) }}</text>
                        </a-tooltip>
                      </template>
                      <template v-else-if="column.dataIndex === 'no'">
                        <div @click="handleShowResultSVP(record)"><ZoomInOutlined />{{ text }}</div>
                      </template>
                      <template v-else-if="['no', 'attr', 'unit'].indexOf(column.dataIndex) === -1">
                        <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                          <text>{{ $dealThousands(text) }}</text>
                        </a-tooltip>
                      </template>
                      <template v-else>
                        <text>{{ text }}</text>
                      </template>
                    </template>

                    <template #summary>
                      <a-table-summary fixed>
                        <a-table-summary-row class="gray-bg-def-deep">
                          <a-table-summary-cell :index="0" align="center">
                            <a-tooltip @click="showMoreTableList()">
                              <template #title>{{ svpPagination ? '点击查看全部' : '点击收起' }}</template>
                              其它
                              <CaretDownOutlined v-show="svpPagination" />
                              <CaretUpOutlined v-show="!svpPagination" />
                            </a-tooltip>
                          </a-table-summary-cell>

                          <a-table-summary-cell align="center"> / </a-table-summary-cell>
                          <a-table-summary-cell align="center"> / </a-table-summary-cell>

                          <a-table-summary-cell align="right">
                            {{ $dealThousands(sum_svp_sold_num - topSumObj.sum_sold_num_result * 1) }}
                          </a-table-summary-cell>

                          <a-table-summary-cell align="right">
                            {{ $dealThousands(sum_svp_price_come_in - topSumObj.sum_price_come_in_result * 1) }}
                          </a-table-summary-cell>
                          <a-table-summary-cell align="right">
                            {{ $dealThousands(sum_svp_material_cost_money - topSumObj.sum_material_cost_money_result * 1) }}
                          </a-table-summary-cell>
                          <a-table-summary-cell align="right">
                            <text :class="{ 'text-red': sum_svp_slid_money - topSumObj.sum_slid_money_result * 1 < 0 }">{{
                              $dealThousands(sum_svp_slid_money - topSumObj.sum_slid_money_result * 1)
                            }}</text>
                          </a-table-summary-cell>
                        </a-table-summary-row>
                      </a-table-summary>

                      <a-table-summary fixed>
                        <a-table-summary-row class="gray-bg-def-deep">
                          <a-table-summary-cell :index="0" align="center"> 合计 </a-table-summary-cell>

                          <a-table-summary-cell align="center"> / </a-table-summary-cell>
                          <a-table-summary-cell align="center"> / </a-table-summary-cell>

                          <a-table-summary-cell align="right">
                            {{ $dealThousands(sum_svp_sold_num) }}
                          </a-table-summary-cell>

                          <a-table-summary-cell align="right">
                            {{ $dealThousands(sum_svp_price_come_in) }}
                          </a-table-summary-cell>
                          <a-table-summary-cell align="right">
                            {{ $dealThousands(sum_svp_material_cost_money) }}
                          </a-table-summary-cell>
                          <a-table-summary-cell align="right">
                            <text :class="{ 'text-red': sum_svp_slid_money * 1 < 0 }">{{ $dealThousands(sum_svp_slid_money) }}</text>
                          </a-table-summary-cell>
                        </a-table-summary-row>
                      </a-table-summary>
                    </template>
                  </a-table>
                </a-col>
              </a-row>
            </div>

            <div class="solution-children">
              <div class="head">2.0 扩展方案对比</div>
              <tools-item-intro type="one" field="intro_compare3" />
              <a-row :gutter="100">
                <a-col :span="12">
                  <div class="subtitle text-center">{{ detail.name }}</div>
                  <SvpScatter
                    ref="svpCatterBefore"
                    planType="planType"
                    echartId="svp-scatter-compare"
                    :excelData_yc="svp_tableData"
                    :getWaterName="getWaterName"
                    :logo="getPlanLogo"
                    title="本量利（基于BOM）方案对比"
                    :subTitle="getTitle"
                    subName="方案对比"
                  />
                </a-col>
                <a-col :span="12">
                  <div class="subtitle text-center">{{ detailCompare.name }}</div>
                  <SvpScatter
                    ref="svpCatterAfter"
                    planType="planType"
                    echartId="svp-scatter-compare-other"
                    :excelData_yc="svp_tableData_compare"
                    :getWaterName="getWaterName"
                    :logo="getPlanLogo"
                    title="本量利（基于BOM）方案对比"
                    :subTitle="getTitle"
                    subName="方案对比"
                  />
                </a-col>
              </a-row>
            </div>
            <div class="solution-children">
              <div class="head">2.1 扩展方案对比差异</div>
              <tools-item-intro type="one" field="intro_compare4" />
              <div class="table-children">
                <div class="subtitle">相关维度数据明细表请勾选</div>
                <div class="checkbox-group">
                  <a-checkbox-group v-model:value="checkedList" :options="checkedOptions" />
                </div>

                <div class="row" ref="scatterTableCompareDiff">
                  <a-row :gutter="100">
                    <a-col :span="18">
                      <template v-for="(item, key) in tableDataList">
                        <div class="table-children-box" v-show="checkedList.indexOf(item.key) > -1">
                          <a-table
                            class="cross-table"
                            :pagination="topSumObjScatter[item.key].pagination || false"
                            :columns="columns"
                            :data-source="item.list"
                            :rowClassName="(_record, index) => (['sum_line'].indexOf(_record.lineType) > -1 ? 'gray-bg-def' : null)"
                            size="small"
                            bordered
                            @resizeColumn="
                              (w, col) => {
                                col.width = w
                              }
                            "
                            :scroll="{}"
                            @change="(pagination, filters, sorter, e) => onTableChange(item, pagination, filters, sorter, e)"
                          >
                            <template #bodyCell="{ column, text, record, index }">
                              <template v-if="column.dataIndex === 'name' || column.dataIndex === 'type_name'">
                                <div @click="handleShowResult(record)"><ZoomInOutlined />{{ text }}</div>
                              </template>
                              <!-- 边际收益 -->
                              <template v-else-if="column.dataIndex === 'slid_money'">
                                <a-tooltip color="#ffffff" trigger="click" :overlayInnerStyle="{ width: '300px' }">
                                  <text :class="{ 'text-red': text * 1 < 0 }">{{ $dealThousands(text) }}</text>
                                </a-tooltip>
                              </template>
                              <template v-else>
                                <div>{{ $dealThousands(text) }}</div>
                              </template>
                            </template>
                            <template #summary>
                              <a-table-summary fixed>
                                <a-table-summary-row class="gray-bg-def-deep">
                                  <a-table-summary-cell :index="0" align="center">
                                    <a-tooltip @click="showMoreTableList(item)">
                                      <template #title>{{ topSumObjScatter[item.key].pagination ? '点击查看全部' : '点击收起' }}</template>
                                      其它
                                      <template v-if="topSumObjScatter[item.key].pagination">
                                        <CaretDownOutlined />
                                      </template>
                                      <template v-else>
                                        <CaretUpOutlined />
                                      </template>
                                    </a-tooltip>
                                  </a-table-summary-cell>

                                  <a-table-summary-cell align="center"> / </a-table-summary-cell>

                                  <a-table-summary-cell align="right">
                                    {{ $dealThousands(item.sold_num - topSumObjScatter[item.key].sum_sold_num) }}
                                  </a-table-summary-cell>
                                  <a-table-summary-cell align="right">
                                    {{ $dealThousands(item.price_come_in - topSumObjScatter[item.key].sum_price_come_in) }}
                                  </a-table-summary-cell>
                                  <a-table-summary-cell align="right">
                                    {{ $dealThousands(item.material_cost_money - topSumObjScatter[item.key].sum_material_cost_money) }}
                                  </a-table-summary-cell>
                                  <a-table-summary-cell align="right">
                                    <text :class="{ 'text-red': item.slid_money - topSumObjScatter[item.key].sum_slid_money < 0 }">
                                      {{ $dealThousands(item.slid_money - topSumObjScatter[item.key].sum_slid_money) }}
                                    </text>
                                  </a-table-summary-cell>
                                </a-table-summary-row>
                              </a-table-summary>

                              <a-table-summary fixed>
                                <a-table-summary-row class="gray-bg-def-deep">
                                  <a-table-summary-cell align="center"> 合计 </a-table-summary-cell>

                                  <a-table-summary-cell align="center"> / </a-table-summary-cell>

                                  <a-table-summary-cell align="right">
                                    {{ $dealThousands(item.sold_num) }}
                                  </a-table-summary-cell>
                                  <a-table-summary-cell align="right">
                                    {{ $dealThousands(item.price_come_in) }}
                                  </a-table-summary-cell>
                                  <a-table-summary-cell align="right">
                                    {{ $dealThousands(item.material_cost_money) }}
                                  </a-table-summary-cell>
                                  <a-table-summary-cell align="right">
                                    <text :class="{ 'text-red': item.slid_money * 1 < 0 }">
                                      {{ $dealThousands(item.slid_money) }}
                                    </text>
                                  </a-table-summary-cell>
                                </a-table-summary-row>
                              </a-table-summary>
                            </template>
                            <!-- 空数据问题处理 -->
                            <template #emptyText> </template>
                          </a-table>
                        </div>
                      </template>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </div>
            <!-- 编制备注 -->
            <div class="solution-children">
              <div class="head">3.0 编制备注</div>
              <tools-item-intro type="one" field="intro_compare5" />
              <div class="row">
                <a-textarea :maxlength="1000" v-model:value="remark" placeholder="备注" :auto-size="{ minRows: 8, maxRows: 16 }" />
              </div>
            </div>
          </div>
          <div class="button-group" v-show="!isPrint">
            <a-button type="primary" size="large" @click="handleSave()"> 保存 </a-button>
            <a-button type="primary" size="large" @click="downPDF"> 下载方案 </a-button>
          </div>
        </div>
      </a-watermark>
    </div>

    <SavePlanCompare ref="SavePlanCompare" />
    <DialogToolsSvpTableCompare ref="DialogToolsSvpTableCompare" :getWaterName="getWaterName" :logo="getPlanLogo" title="本量利（基于BOM）方案对比" :subTitle="getTitle" subName="方案对比" />
    <DialogToolsScatterTableCompare ref="DialogToolsScatterTableCompare" :getWaterName="getWaterName" :logo="getPlanLogo" title="本量利（基于BOM）方案对比" :subTitle="getTitle" subName="方案对比" />
    <page-loading ref="pageLoading" />
  </div>
</template>

<script>
// 打印模板
import SolutionTemplatePDFCompare from './components/SolutionTemplatePDFCompare.vue'
import { mapGetters } from 'vuex'

import { ZoomInOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons-vue'
import calMixIn from './calMixIn.vue'
import SettingDrawer from '@/components/SettingDrawer/SettingDrawer.vue'
import DialogToolsScatterTableCompare from '@/components/DialogTools/DialogToolsScatterTableCompare.vue'
import DialogToolsSvpTableCompare from '@/components/DialogTools/DialogToolsSvpTableCompare.vue'
import { numberToFixed, roundToDotNumber, debounce, fnc_excelData_yc_after, checkZero, tableSortFnc, tableDefaultPage, tableDefautlLine } from '@/utils/util'

import PageLoading from '@/components/PageLoading.vue'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'
import { htmlToPDF } from '@/utils/html2pdf'

import ToolsItemIntro from '@/components/ToolsItemIntro.vue'
import SvpLine from './components/SvpLine.vue'
import SvpScatter from './components/SvpScatter.vue'
import SavePlanCompare from '../components/SavePlanCompare.vue'

const groupTypeName = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }

export default {
  components: {
    SolutionTemplatePDFCompare,
    ToolsItemIntro,
    SettingDrawer,
    SvpLine,
    SvpScatter,
    ZoomInOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    SavePlanCompare,
    PageLoading,
    DialogToolsScatterTableCompare,
    DialogToolsSvpTableCompare
  },
  mixins: [calMixIn],
  data() {
    let checkedOptions = []
    for (let key in groupTypeName) {
      checkedOptions.push({ label: groupTypeName[key], value: key })
    }

    return {
      detail: {},
      checkedOptions: checkedOptions,
      checkedList: [],
      listQuery: {
        id: '',
        compare_id: ''
      },
      detail: {},
      detailCompare: {},

      tableDataList: [],
      columns: [
        { width: 90, title: '纬度', dataIndex: 'name', align: 'center', resizable: true, ellipsis: true },
        { width: 100, title: '项', dataIndex: 'type_name', align: 'center', resizable: true, ellipsis: true },
        { width: 120, title: '销量', dataIndex: 'sold_num', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('sold_num') },
        { width: 120, title: '收入', dataIndex: 'price_come_in', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('price_come_in') },
        { width: 120, title: '变动成本', dataIndex: 'material_cost_money', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('material_cost_money') },
        { width: 120, title: '边际收益', dataIndex: 'slid_money', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('slid_money') }
      ],
      svpPagination: tableDefaultPage(),
      svpTableData: [],
      svpColumns: [
        { width: 100, title: '产品编码', dataIndex: 'no', align: 'center', resizable: true, ellipsis: true, fixed: 'left', ...tableSortFnc('no', 'string') },
        { width: 140, title: '产品规格/描述', dataIndex: 'attr', align: 'center', resizable: true, ellipsis: true, fixed: 'left' },
        { width: 80, title: '计量单位', dataIndex: 'unit', align: 'center', resizable: true, ellipsis: true, fixed: 'left' },

        { width: 120, title: '销量', dataIndex: 'sold_num_result', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('sold_num_result') },
        { width: 150, title: '收入', dataIndex: 'price_come_in_result', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('price_come_in_result') },
        { width: 150, title: '变动成本', dataIndex: 'material_cost_money_result', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('material_cost_money_result') },
        { width: 150, title: '边际收益', dataIndex: 'slid_money_result', align: 'right', resizable: true, ellipsis: true, ...tableSortFnc('slid_money_result') }
      ],
      //总收入
      diff_sum_price_come_in: 0,
      //总成本
      diff_material_cost_money: 0,
      //变动成本
      diff_material_cost: 0,
      //边际贡献
      diff_side_money: 0,

      //预测
      diff_sold_num: 0,
      //固定成本
      diff_fixed_money: 0,
      //毛利
      diff_maoli: 0,
      //管理利润
      diff_manager_rate: 0,
      //固定
      diff_fixed_money: 0,
      //混合
      diff_mix_money: 0,
      //混合差异
      diffMixGroup: [],
      //显示混合成本块
      showMixMenu: false,

      svp_tableData: [],
      svp_tableData_compare: [],

      svp_tableData_fixed: [],
      svp_tableData_fixed_compare: [],

      svp_tableData_mix: [],
      svp_tableData_mix_compare: [],

      svp_tableData_mix_columns: [],
      svp_tableData_mix_columns_compare: [],

      scatter_tableData: [],
      scatter_tableData_compare: [],
      topSumObj: {
        sum_sold_num_result: 0,
        // sum_price_add_tax:0,
        sum_price_come_in_result: 0,
        sum_material_cost_money_result: 0,
        sum_slid_money_result: 0,
        upSortData: []
      },

      topSumObjScatter: {
        sum_sold_num: 0,
        sum_price_add_tax: 0,
        sum_price_come_in: 0,
        sum_material_cost_money: 0,
        sum_side_money: 0,
        upSortData: []
      },

      historyId: '',
      remark: '',
      isPrint: false,
      downLoading: false
    }
  },
  created() {
    this.historyId = this.$route.query.historyId || 0
    this.listQuery.id = this.$route.query.id || 0
    this.listQuery.compare_id = this.$route.query.compareId || 0
  },
  mounted() {
    //项目初始化主題色
    let themeColor = (ls.get(SITE_SETTINGS) || {}).THEME_COLOR
    if (themeColor) {
      systemConfig.commit(THEME_COLOR, (ls.get(SITE_SETTINGS) || {}).THEME_COLOR)
    }
    if (this.getHasToolAuth('tools1_compare')) {
      this.getCompareDetail()
      this.getData()
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getTitle() {
      return this.detail.name + '（基准） VS ' + this.detailCompare.name
    },
    getWaterName() {
      return this.detail.water_name || ''
    },
    getPlanName() {
      return this.detail.compare_name || ''
    },
    getPlanName_en() {
      return this.detail.compare_name_en || ''
    },
    userInfo() {
      return this.detail.userInfo || {}
    },

    getPlanLogo() {
      return this.detail.plan_logo || ''
    },
    sum_svp_sold_num() {
      return this.getSum_svp_tableData('sold_num_result')
    },
    sum_svp_price_come_in() {
      return this.getSum_svp_tableData('price_come_in_result')
    },
    sum_svp_material_cost_money() {
      return this.getSum_svp_tableData('material_cost_money_result')
    },
    sum_svp_slid_money() {
      return this.getSum_svp_tableData('slid_money_result')
    },
    conditionTableData() {
      return this.tableData.filter((e) => this.checkedList.indexOf(e.key) > -1)
    }
  },
  methods: {
    handleShowResult(item) {
      console.log(item)
      this.$refs.DialogToolsScatterTableCompare.showDialog(item)
    },
    handleShowResultSVP(item) {
      console.log(item)
      this.$refs.DialogToolsSvpTableCompare.showDialog(item)
    },
    getCompareDetail() {
      if (!this.historyId) {
        return
      }
      this.$network('/api/tools/planCompareHistoryDetail', { id: this.historyId }).then((res) => {
        const data = res.data || {}
        const detail = data.detail || ''
        this.remark = detail.remark
      })
    },
    handleSave(callback) {
      this.$refs.SavePlanCompare.open(
        {
          name: this.detail.name || '',
          remark: this.remark,
          plan1: this.listQuery.id,
          plan2: this.listQuery.compare_id,
          tool_type: 'one'
        },
        () => {
          if (callback) {
            callback()
          } else {
            this.$router.replace({ path: '/history/compare' })
          }
        }
      )
    },
    handlePrint() {
      this.$network('/api/tools/planComparePrintTimes', { id: this.historyId, plan1: this.listQuery.id, plan2: this.listQuery.compare_id, tool_type: 'one' })
    },
    async downPDF() {
      this.downLoading = true
      this.isPrint = true
      this.$refs.pageLoading.open('正在进行方案下载')
      //增加打印次数
      this.handlePrint()

      setTimeout(async () => {
        await this.$refs.SolutionTemplatePDFCompare.downPDF(this)

        this.$nextTick(async () => {
          this.$refs.pageLoading.text('方案构件中...')

          let elId = 'SolutionTemplatePDFCompare'
          let pdfName = '对比方案'

          this.$refs.pageLoading.text('正在生成PDF文件')
          await htmlToPDF(elId, pdfName, 'PDF-foot')
          this.$refs.pageLoading.text('下载成功')
          // this.$refs.pageLoading.close()
          // this.isPrint = false

          setTimeout(() => {
            location.reload()
          }, 800)
        })
      }, 200)
    },
    showMoreTableList(item) {
      if (item) {
        const selectKey = item.key || ''
        if (this.topSumObjScatter[selectKey].pagination === false) {
          this.topSumObjScatter[selectKey].pagination = tableDefaultPage()
          this.calOtherSum(item, this.topSumObjScatter[selectKey].upSortData, false)
        } else {
          this.topSumObjScatter[selectKey].pagination = false
        }
      } else {
        if (this.svpPagination === false) {
          this.svpPagination = tableDefaultPage()
          this.calOtherSum(item, this.topSumObj.upSortData, false)
        } else {
          this.svpPagination = false
        }
      }
    },
    //计算其它加个
    calOtherSum(item, currentDataSource, recordUpData = true) {
      let selectKey = ''
      let selectBlock = this.topSumObj

      if (item) {
        selectKey = item.key || ''
        selectBlock = this.topSumObjScatter[selectKey] || {}
      } else {
      }

      const totalObj = {}
      for (let key in selectBlock) {
        if (key == 'upSortData') continue

        const pageSize = tableDefautlLine //this.pagination.pageSize||0
        const field = key.replace('sum_', '')
        const topSumObj_field = key
        totalObj[topSumObj_field] = 0
        let total = 0
        for (let i = 0; i < currentDataSource.length; i++) {
          const info = currentDataSource[i]

          if (i >= tableDefautlLine) {
            break
          }
          totalObj[topSumObj_field] = totalObj[topSumObj_field] + info[field] * 1
        }
      }
      if (recordUpData) {
        totalObj.upSortData = currentDataSource
      } else {
        totalObj.upSortData = this.topSumObj.upSortData
      }
      totalObj.pagination = tableDefaultPage()
      // console.log(totalObj)
      if (item) {
        // console.log( this.topSumObjScatter)
        this.topSumObjScatter[selectKey] = totalObj
      } else {
        this.topSumObj = totalObj
      }
    },
    onTableChange(item, pagination, filters, sorter, { currentDataSource, action }) {
      // console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
      if (action == 'sort') {
        return this.calOtherSum(item, currentDataSource)
      }
    },
    handleShowData(type) {
      const svpTableData = this._handleTableDataCompare(type)
      const newSort = svpTableData.sort(tableSortFnc('side_money').sorter.compare)
      //计算其它加个
      this.calOtherSum(null, newSort)
      this.svpTableData = newSort
    },
    _handleTableDataCompare(type) {
      const keyFnc = (item) => {
        return item.no
      }
      const calTableData = JSON.parse(JSON.stringify(this.svp_tableData))

      const calTableData_compare = JSON.parse(JSON.stringify(this.svp_tableData_compare))
      const tableData_compare_obj = {}
      calTableData_compare.map((item) => {
        const key = keyFnc(item)
        tableData_compare_obj[key] = item
      })

      return calTableData.map((item) => {
        const key = keyFnc(item)
        const tableData_compare_objInfo = tableData_compare_obj[key] || {}
        const sold_num = tableData_compare_objInfo.sold_num || 0
        const price_come_in = tableData_compare_objInfo.price_come_in || 0
        const material_cost_money = tableData_compare_objInfo.material_cost_money || 0

        const material_cost = tableData_compare_objInfo.material_cost || 0

        //边际贡献
        const slid_money_compare = numberToFixed(price_come_in - material_cost_money)

        // item.slid_money = item.price_come_in - item.material_cost_money
        // item.sold_num = numberToFixed(item.sold_num - sold_num)
        // item.price_come_in = numberToFixed(item.price_come_in - price_come_in)
        // item.material_cost_money = numberToFixed(item.material_cost_money - material_cost_money)
        // item.slid_money = numberToFixed(item.slid_money - slid_money_compare)

        // item.material_cost = numberToFixed( material_cost)

        item.sold_num = numberToFixed(item.sold_num)
        item.price_come_in = numberToFixed(item.price_come_in)
        item.material_cost_money = numberToFixed(item.material_cost_money)
        item.slid_money = numberToFixed(item.price_come_in - item.material_cost_money)

        item.sold_num_compare = numberToFixed(sold_num)
        item.price_come_in_compare = numberToFixed(price_come_in)
        item.material_cost_money_compare = numberToFixed(material_cost_money)
        item.slid_money_compare = numberToFixed(slid_money_compare)

        item.sold_num_result = numberToFixed(sold_num - item.sold_num)
        item.price_come_in_result = numberToFixed(price_come_in - item.price_come_in)
        item.material_cost_money_result = numberToFixed(material_cost_money - item.material_cost_money)
        item.slid_money_result = numberToFixed(item.slid_money_compare - item.slid_money)
        // console.log(item.sold_num_result)

        // diff_material_cost_money += item.material_cost_money * 1
        //变动成本
        // diff_material_cost += item.material_cost * 1

        return item
      })
    },

    getData() {
      this.$network('/api/tools/planCompare', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const detailCompare = data.detail_compare || {}

          this.detail = detail
          this.detailCompare = detailCompare

          const mixColumnsFnc = (values) => {
            const data = []
            values.map(function (item, index) {
              if (index > 0) {
                item.diffMoney = 0
                data.push(item)
              }
            })
            return data
          }

          this.$nextTick(() => {
            let SolutionStuctTableData = null
            let SolutionStuctTableData_compare = null

            let tableData = null
            let tableData_compare = null

            let tableData_fixed = null
            let tableData_fixed_compare = null

            let tableData_mix = null
            let tableData_mix_compare = null

            let tableData_mix_columns = null
            let tableData_mix_columns_compare = null
            let hasMixData = false

            this.typePlanData_fixed(this.listQuery.id, (data) => {
              tableData_fixed = data.list || []
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })
            this.typePlanData_fixed(this.listQuery.compare_id, (data) => {
              tableData_fixed_compare = data.list || []
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })

            this.typePlanData_mix(this.listQuery.id, (data) => {
              hasMixData = true
              tableData_mix_columns = mixColumnsFnc(data.columns || [])
              tableData_mix = data.list || []
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })
            this.typePlanData_mix(this.listQuery.compare_id, (data) => {
              hasMixData = true
              tableData_mix_columns_compare = mixColumnsFnc(data.columns || [])
              tableData_mix_compare = data.list || []
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })

            this.typePlanData('', detail, (data) => {
              tableData = data
              SolutionStuctTableData = this.planSolutionStuct('', data)
              this.diffTableData(SolutionStuctTableData, SolutionStuctTableData_compare)
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })

            this.typePlanData('compare', detailCompare, (data) => {
              tableData_compare = data
              SolutionStuctTableData_compare = this.planSolutionStuct('compare', data)
              this.diffTableData(SolutionStuctTableData, SolutionStuctTableData_compare)
              this.diffTableSum(
                tableData,
                tableData_compare,
                tableData_fixed,
                tableData_fixed_compare,
                hasMixData,
                tableData_mix,
                tableData_mix_compare,
                tableData_mix_columns,
                tableData_mix_columns_compare
              )
            })
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },

    getSum_tableData(field) {
      let money = 0
      this.conditionTableData.map(function (item) {
        if (item.lineType !== 'sum_line') {
          money += +item[field]
        }
      })
      return numberToFixed(money)
    },

    getSum_svp_tableData(field) {
      let money = 0
      this.svpTableData.map(function (item) {
        money += +item[field]
      })
      return numberToFixed(money)
    },

    diffTableSum(tableData, tableData_compare, tableData_fixed, tableData_fixed_compare, hasMixData, tableData_mix, tableData_mix_compare, tableData_mix_columns, tableData_mix_columns_compare) {
      if (!tableData || !tableData_compare || !tableData_fixed || !tableData_fixed_compare || !hasMixData) {
        return
      }

      this.svp_tableData = tableData
      this.svp_tableData_compare = tableData_compare

      this.svp_tableData_fixed = tableData_fixed
      this.svp_tableData_fixed_compare = tableData_fixed_compare

      this.svp_hasMixData = hasMixData

      this.svp_tableData_mix = tableData_mix
      this.svp_tableData_mix_compare = tableData_mix_compare

      this.svp_tableData_mix_columns = tableData_mix_columns
      this.svp_tableData_mix_columns_compare = tableData_mix_columns_compare

      const keyFnc = (item) => {
        return item.no
      }

      const tableData_compare_obj = {}
      tableData_compare.map((item) => {
        const key = keyFnc(item)
        tableData_compare_obj[key] = item
      })
      //总成本
      // let diff_material_cost_money = 0
      //变动成本
      // let diff_material_cost = 0
      //总边际成本
      // let diff_side_unit_money = 0
      //预测
      // let diff_sold_num = 0
      // console.log(tableData_mix,tableData_mix_compare)
      const dataObj_compare = this.handleData(tableData, tableData_fixed, tableData_mix, tableData_mix_columns || [])
      const dataObj = this.handleData(tableData_compare, tableData_fixed_compare, tableData_mix_compare, tableData_mix_columns_compare || [])

      // const calTableData = tableData.concat([])

      // calTableData.map((item) => {
      //   const key = keyFnc(item)
      //   const tableData_compare_objInfo = tableData_compare_obj[key] || {}
      //   const sold_num = tableData_compare_objInfo.sold_num || 0
      //   const price_come_in = tableData_compare_objInfo.price_come_in || 0
      //   const material_cost_money = tableData_compare_objInfo.material_cost_money || 0

      //   const material_cost = tableData_compare_objInfo.material_cost || 0

      //   //边际贡献
      //   const slid_money_compare = numberToFixed(price_come_in - material_cost_money)

      //   item.slid_money = item.price_come_in - item.material_cost_money

      //   item.sold_num = numberToFixed(item.sold_num - sold_num)
      //   item.price_come_in = numberToFixed(item.price_come_in - price_come_in)
      //   item.material_cost_money = numberToFixed(item.material_cost_money - material_cost_money)
      //   item.slid_money = numberToFixed(item.slid_money - slid_money_compare)

      //   item.material_cost = numberToFixed( material_cost)

      //   // diff_material_cost_money += item.material_cost_money * 1
      //   //变动成本
      //   // diff_material_cost += item.material_cost * 1

      //   return item
      // })

      // console.log(dataObj)
      // console.log(diff_material_cost)
      //总收入
      // console.log(dataObj.sum_price_come_in*1 , dataObj_compare.sum_price_come_in*1)
      this.diff_sum_price_come_in = roundToDotNumber(dataObj.sum_price_come_in * 1 - dataObj_compare.sum_price_come_in * 1)
      //边际贡献
      this.diff_side_money = roundToDotNumber(dataObj.sum_side_money - dataObj_compare.sum_side_money)
      //预测
      this.diff_sold_num = roundToDotNumber(dataObj.sum_sold_num - dataObj_compare.sum_sold_num)
      //毛利
      this.diff_maoli = roundToDotNumber(dataObj.maoli - dataObj_compare.maoli)
      //管理利润
      this.diff_manager_rate = roundToDotNumber(dataObj.manager_rate - dataObj_compare.manager_rate)
      //固定
      this.diff_fixed_money = roundToDotNumber(dataObj.sum_fixed_total_money - dataObj_compare.sum_fixed_total_money)
      //混合
      this.diff_mix_money = roundToDotNumber(dataObj.sum_mix_total_money - dataObj_compare.sum_mix_total_money)
      // console.log(dataObj.sum_mix_total_money , dataObj_compare.sum_mix_total_money)
      const material_cost_money = dataObj.sum_material_cost_money * 1 + dataObj.sum_fixed_total_money * 1 + dataObj.sum_mix_total_money * 1
      const material_cost_money_compare = dataObj_compare.sum_material_cost_money * 1 + dataObj_compare.sum_fixed_total_money * 1 + dataObj_compare.sum_mix_total_money * 1

      // //比例
      // const productNumPer = checkZero(dataObj.sum_pass_sold_num) ? 0 : dataObj.sum_sold_num / dataObj.sum_pass_sold_num
      // const productNumPer_compare = checkZero(dataObj_compare.sum_pass_sold_num) ? 0 : dataObj_compare.sum_sold_num / dataObj_compare.sum_pass_sold_num
      // //产量
      // const productNum = checkZero(productNumPer) ? 0 : dataObj.sum_sold_num/productNumPer
      // const productNum_compare = checkZero(productNumPer_compare) ? 0 : dataObj.sum_sold_num/productNumPer_compare
      // //变动成本
      // // this.diff_material_cost = numberToFixed(dataObj.sum_material_cost - dataObj_compare.sum_material_cost)

      // //单位变动成本 = 总的变动成本/总产量
      // const material_cost_unit_money = checkZero(dataObj.sum_pass_sold_num) ? 0 : dataObj.sum_material_cost_money / dataObj.sum_pass_sold_num ;
      // const material_cost_unit_money_compare = checkZero(dataObj_compare.sum_pass_sold_num) ? 0 : dataObj_compare.sum_material_cost_money / dataObj_compare.sum_pass_sold_num ;
      //变动成本
      // console.log(productNumPer , productNumPer_compare)
      // console.log(productNum , productNum_compare)
      // console.log( material_cost_unit_money,  material_cost_unit_money_compare)
      // console.log( productNum*material_cost_unit_money,  productNum_compare*material_cost_unit_money_compare)
      // this.diff_material_cost = numberToFixed(productNum*material_cost_unit_money - productNum_compare*material_cost_unit_money_compare)
      this.diff_material_cost = roundToDotNumber(dataObj.sum_material_cost_money - dataObj_compare.sum_material_cost_money)

      this.diff_material_cost_money = roundToDotNumber(material_cost_money - material_cost_money_compare)
      // this.diff_material_cost_money = numberToFixed(diff_material_cost_money)
      // this.diff_material_cost = numberToFixed(diff_material_cost)

      // console.log(tableData_mix,tableData_mix_compare)

      // let tableDataMixTotal = {};//(tableData_mix || []).pop()
      // let tableDataMixTotal_compare = {};//(tableData_mix_compare || []).pop()

      // if(tableData_mix && tableData_mix_compare){
      //   tableDataMixTotal = Object.assign({},tableData_mix[tableData_mix.length-1])
      //   tableDataMixTotal_compare = Object.assign({},tableData_mix_compare[tableData_mix_compare.length-1])
      // }

      const sum_sold_num = dataObj.sum_sold_num
      const sum_sold_num_compare = dataObj_compare.sum_sold_num

      const diffMixFnc = (item, sold_num, index, _tableData_mix, _columns_step_mix) => {
        const item_range_min = item.range_min * 1
        const item_range_max = item.range_max * 1
        let total = 0
        if (item_range_min <= sold_num * 1 && item_range_max > sold_num) {
          total = this.tableData_step_mix_field('total', sold_num * 1, _tableData_mix, _columns_step_mix)
        }
        // const total = tableDataMixTotal['range_' + index]
        // let total_compare = 0
        // let compareIndex = -1
        // for (let i = 0; i < (tableData_mix_columns_compare || []).length; i++) {
        //   const compareInfo = tableData_mix_columns_compare[i]
        //   const item_range_min_compare = compareInfo.range_min
        //   const item_range_max_compare = compareInfo.range_max
        //   if (item_range_min >= item_range_min_compare && item_range_max <= item_range_max_compare) {
        //     compareIndex = i
        //     break
        //   }
        // }
        // if (compareIndex > -1) {
        //   total_compare = tableDataMixTotal_compare['range_' + compareIndex] || 0
        // }
        return numberToFixed(total)
      }

      const disffMixGroup = (tableData_mix_columns || []).map(function (item, index) {
        item.diffMoney = diffMixFnc(item, sum_sold_num, index, tableData_mix, tableData_mix_columns)
        return item
      })

      const disffMixGroup_compare = (tableData_mix_columns_compare || []).map(function (item, index) {
        item.diffMoney = diffMixFnc(item, sum_sold_num_compare, index, tableData_mix_compare, tableData_mix_columns_compare)
        return item
      })

      const diffMixFncResult = (item, index) => {
        const item_range_min = item.range_min * 1
        const item_range_max = item.range_max * 1
        let diffMoney = item.diffMoney
        let diffMoney_compare = 0
        for (let i = 0; i < (disffMixGroup_compare || []).length; i++) {
          const compareInfo = disffMixGroup_compare[i]
          const item_range_min_compare = compareInfo.range_min
          const item_range_max_compare = compareInfo.range_max
          if (item_range_min >= item_range_min_compare && item_range_max <= item_range_max_compare) {
            diffMoney_compare = compareInfo.diffMoney
            break
          }
        }
        return numberToFixed(diffMoney - diffMoney_compare)
      }

      this.diffMixGroup = (disffMixGroup || []).map(function (item, index) {
        item.diffMoney = diffMixFncResult(item, index)
        return item
      })

      //执行差异对比
      this.handleShowData()
    },

    diffTableData(tableData, tableData_compare) {
      if (!tableData || !tableData_compare) {
        return
      }

      this.scatter_tableData = tableData
      // console.log(this.scatter_tableData)
      this.scatter_tableData_compare = tableData_compare
      // return ;

      const keyFnc = (item) => {
        return item.key + '_' + item.name + item.type_name
      }

      const keyFncNo = (item) => {
        return item.no
      }
      const tableData_compare_obj = {}
      tableData_compare.map(function (item) {
        const key = keyFnc(item)
        tableData_compare_obj[key] = item
      })

      let tableDataList = {}
      let topSumObjScatter = {}

      tableData.map(function (item) {
        const groupKey = item.key
        if (!(groupKey in tableDataList)) {
          tableDataList[groupKey] = {
            key: groupKey,
            price_come_in: 0,
            material_cost_money: 0,
            sold_num: 0,
            slid_money: 0,
            list: []
          }

          topSumObjScatter[groupKey] = {
            sum_price_come_in: 0,
            sum_material_cost_money: 0,
            sum_sold_num: 0,
            sum_slid_money: 0,
            upSortData: [],
            pagination: tableDefaultPage()
          }
        }

        const key = keyFnc(item)
        const tableData_compare_objInfo = tableData_compare_obj[key] || {}
        // console.log(key,tableData_compare_objInfo)
        //处理每一项数据
        const itemList = item.list || []
        const itemList_compare = tableData_compare_objInfo.list || []
        let itemList_compare_obj = {}
        itemList_compare.map(function (item) {
          const key = keyFncNo(item)
          itemList_compare_obj[key] = item
        })

        // console.log(itemList_compare_obj)

        itemList.map((_item) => {
          const key = keyFncNo(_item)
          const item_compare_objInfo = itemList_compare_obj[key] || {}

          const sold_num = item_compare_objInfo.sold_num || 0
          const price_come_in = item_compare_objInfo.price_come_in || 0
          const material_cost_money = item_compare_objInfo.material_cost_money || 0
          const slid_money = item_compare_objInfo.slid_money || 0

          _item.sold_num_compare = numberToFixed(sold_num)
          _item.price_come_in_compare = numberToFixed(price_come_in)
          _item.material_cost_money_compare = numberToFixed(material_cost_money)
          _item.slid_money_compare = numberToFixed(slid_money)

          _item.sold_num_result = numberToFixed(sold_num - _item.sold_num)
          _item.price_come_in_result = numberToFixed(price_come_in - _item.price_come_in)
          _item.material_cost_money_result = numberToFixed(material_cost_money - _item.material_cost_money)
          _item.slid_money_result = numberToFixed(slid_money * 1 - _item.slid_money * 1)
        })

        const sold_num = tableData_compare_objInfo.sold_num || 0
        const price_come_in = tableData_compare_objInfo.price_come_in || 0
        const material_cost_money = tableData_compare_objInfo.material_cost_money || 0
        const slid_money = tableData_compare_objInfo.slid_money || 0

        item.sold_num = numberToFixed(sold_num - item.sold_num)
        item.price_come_in = numberToFixed(price_come_in - item.price_come_in)
        item.material_cost_money = numberToFixed(material_cost_money - item.material_cost_money)
        item.slid_money = numberToFixed(slid_money - item.slid_money)

        item.itemList = itemList
        tableDataList[groupKey].list.push(item)

        tableDataList[groupKey].price_come_in = numberToFixed(tableDataList[groupKey].price_come_in * 1 + item.price_come_in * 1)
        tableDataList[groupKey].material_cost_money = numberToFixed(tableDataList[groupKey].material_cost_money * 1 + item.material_cost_money * 1)
        tableDataList[groupKey].sold_num = numberToFixed(tableDataList[groupKey].sold_num * 1 + item.sold_num * 1)
        tableDataList[groupKey].slid_money = numberToFixed(tableDataList[groupKey].slid_money * 1 + item.slid_money * 1)
        return item
      })

      this.tableDataList = tableDataList
      this.topSumObjScatter = topSumObjScatter

      //计算其它加个
      this.$nextTick(() => {
        for (let key in tableDataList) {
          const item = tableDataList[key]
          this.calOtherSum(item, item.list)
        }
      })
    },

    typePlanData_fixed(id, callback) {
      return this.$network('/api/tools/planResultData', {
        id: id,
        type: 'step-fixed'
      })
        .then((res) => {
          const data = res.data || {}
          callback(data)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    typePlanData_mix(id, callback) {
      return this.$network('/api/tools/planResultData', {
        id: id,
        type: 'step-mix'
      })
        .then((res) => {
          const data = res.data || {}
          callback(data)
        })
        .catch((err) => {
          console.error(err)
        })
    },
    typePlanData(type, detail = {}, callback) {
      const pageParams = detail.page_params || {}
      const params_tax_per = pageParams.params_tax_per || 0
      const params_sold_per = pageParams.params_sold_per || 0
      const params_cost_per = pageParams.params_cost_per || 0
      const params_pass_per = pageParams.params_pass_per || 0
      const solutionBaseTable = pageParams.solutionBaseTable || {}

      this.$network('/api/tools/planResultData', { id: detail.id, type: 'step-yc' })
        .then((res) => {
          const data = res.data || {}
          const list = data.list || []
          const excelDataChange = []
          list.map(function (item) {
            const no = item.no || ''
            const noPerInfo = solutionBaseTable[no] || {}
            item.params_line_tax_per = noPerInfo.params_line_tax_per || 0
            item.params_line_sold_per = noPerInfo.params_line_sold_per || 0
            item.params_line_cost_per = noPerInfo.params_line_cost_per || 0
            item.params_line_pass_per = noPerInfo.params_line_pass_per || 0
            const afterItem = { ...item }
            for (let key in item) {
              afterItem['__' + key] = item[key]
            }
            excelDataChange.push(afterItem)
          })

          const dataAfter = fnc_excelData_yc_after(list, { params_tax_per, params_sold_per, params_cost_per, params_pass_per })

          callback(dataAfter)
          return dataAfter
        })
        .catch((err) => {
          console.error(err)
        })
    },

    planSolutionStuct(type, value) {
      let groupObject = {}
      value.map(function (item) {
        for (let key in groupTypeName) {
          const itemValue = item[key]
          const name = groupTypeName[key]
          if (!(name in groupObject)) {
            groupObject[name] = {}
          }
          if (!(itemValue in groupObject[name])) {
            groupObject[name][itemValue] = {
              name: name,
              key: key,
              type_name: itemValue,
              //销售收入
              price_come_in: 0,
              //变动成本
              material_cost_money: 0,
              //销量
              sold_num: 0,
              //边际收益
              slid_money: 0,

              list: []
            }
          }
          const sold_num = item.sold_num * 1
          //产量
          const pass_sold_num = item.pass_sold_num * 1

          groupObject[name][itemValue]['price_come_in'] += item.price_come_in * 1
          groupObject[name][itemValue]['material_cost_money'] += item.material_cost * 1 * pass_sold_num
          groupObject[name][itemValue]['sold_num'] += sold_num

          //边际贡献
          item.slid_money = numberToFixed(item.price_come_in - item.material_cost)
          groupObject[name][itemValue]['list'].push(item)

          // groupObject[name][itemValue]['slid_money'] +=  groupObject[name][itemValue]['price_come_in'] - groupObject[name][itemValue]['material_cost_money'];
        }
      })

      for (let _key in groupObject) {
        for (let _member in groupObject[_key]) {
          groupObject[_key][_member]['price_come_in'] = numberToFixed(groupObject[_key][_member]['price_come_in'])
          groupObject[_key][_member]['material_cost_money'] = numberToFixed(groupObject[_key][_member]['material_cost_money'])
          groupObject[_key][_member]['sold_num'] = numberToFixed(groupObject[_key][_member]['sold_num'])
          groupObject[_key][_member]['slid_money'] = numberToFixed(groupObject[_key][_member]['price_come_in'] - groupObject[_key][_member]['material_cost_money'])
        }
      }

      let tableData = []

      for (let typeKey in groupTypeName) {
        const name = groupTypeName[typeKey]

        //表格数据
        let sum_price_come_in = 0
        let sum_material_cost_money = 0
        let sum_sold_num = 0
        let sum_slid_money = 0

        for (let memberKey in groupObject[name]) {
          const item = groupObject[name][memberKey]
          sum_price_come_in = sum_price_come_in + item.price_come_in * 1
          sum_material_cost_money = sum_material_cost_money + item.material_cost_money * 1
          sum_sold_num = sum_sold_num + item.sold_num * 1
          sum_slid_money = sum_slid_money + item.slid_money * 1

          tableData.push({
            name: name,
            key: typeKey,
            lineType: 'line',
            type_name: memberKey,
            price_come_in: item.price_come_in,
            material_cost_money: item.material_cost_money,
            sold_num: item.sold_num,
            slid_money: item.slid_money,
            list: item.list
          })
        }
        // tableData.push({
        //   name: name + '合计',
        //   key: typeKey,
        //   lineType: 'sum_line',
        //   type_name: '/',
        //   price_come_in: sum_price_come_in,
        //   material_cost_money: sum_material_cost_money,
        //   sold_num: sum_sold_num,
        //   slid_money: sum_slid_money
        // })
      }

      return tableData
    }
    // calYcTableData_fumField(field, values) {
    //   let money = 0

    //   if (values && values.length > 0) {
    //     values.map(function (item) {
    //       money += (item[field] ?? 0) * 1
    //     })
    //   }

    //   return numberToFixed(money)
    // },
    // //固定成本-函数
    // tableData_step_fixed_field(field, values) {
    //   let money = 0
    //   if (values && values.length > 0) {
    //     ;(values || []).map(function (item) {
    //       if (item._type === field) {
    //         money = item.cost
    //       }
    //     })
    //   }
    //   return numberToFixed(money)
    // },

    // //混合成本-函数
    // tableData_step_mix_field(field, sold_num, values, columns_step_mix) {
    //   // console.log(field, sold_num, values, columns_step_mix)
    //   let money = 0
    //   if (values && values.length > 0) {
    //     values.map((item) => {
    //       if (item._type === field) {
    //         let index = -1
    //         for (let i = 0; i < columns_step_mix.length; i++) {
    //           const info = columns_step_mix[i]
    //           if ('range_min' in info) {
    //             index++
    //             if (info['range_min'] <= sold_num && sold_num < info['range_max']) {
    //               money = item['range_' + index]
    //               break
    //             }
    //           }
    //         }
    //       }
    //     })
    //   }
    //   return money
    // },
    // //处理数据
    // handleData(newData, fixData, mixData, columns_step_mix, handleParams) {
    //   //总成本
    //   const sum_material_cost_money = this.calYcTableData_fumField('material_cost_money', newData)
    //   //总产量
    //   const sum_pass_sold_num = this.calYcTableData_fumField('pass_sold_num', newData)
    //   //变动成本
    //   const sum_material_cost = this.calYcTableData_fumField('material_cost', newData)
    //   //销量
    //   const sum_sold_num = this.calYcTableData_fumField('sold_num', newData)

    //   //含税总收入
    //   const sum_price_come_in = this.calYcTableData_fumField('price_come_in', newData)
    //   // console.log('-sum_price_come_in-')
    //   // console.log(newData)
    //   // console.log(sum_price_come_in)
    //   //原边际贡献
    //   const sum_side_money = this.calYcTableData_fumField('side_money', newData)

    //   //含税均单价
    //   const avg_price_no_tax_unit_money = sum_sold_num * 1 == 0 ? 0 : sum_price_come_in / sum_sold_num
    //   //制造环节固定成本
    //   let sum_fixed_zhizao_money = this.tableData_step_fixed_field('zhizao_sum', fixData)
    //   //制造环节固定成本
    //   let sum_fixed_yunying_money = this.tableData_step_fixed_field('yunying_sum', fixData)
    //   let sum_fixed_total_money = this.tableData_step_fixed_field('total', fixData)
    //   if (handleParams && 'params_fixed_per' in handleParams) {
    //     sum_fixed_zhizao_money = sum_fixed_zhizao_money * (1 + handleParams['params_fixed_per'] / 100)
    //     sum_fixed_yunying_money = sum_fixed_yunying_money * (1 + handleParams['params_fixed_per'] / 100)
    //   }
    //   //制造环节混合成本
    //   let sum_mix_zhizao_money = this.tableData_step_mix_field('zhizao_sum', sum_sold_num * 1, mixData, columns_step_mix)
    //   //运营环节混合成本
    //   let sum_mix_yunying_money = this.tableData_step_mix_field('yunying_sum', sum_sold_num * 1, mixData, columns_step_mix)

    //   let sum_mix_total_money = this.tableData_step_mix_field('total', sum_sold_num * 1, mixData, columns_step_mix)
    //   // console.log(sum_sold_num * 1, mixData, columns_step_mix, sum_mix_zhizao_money, sum_mix_yunying_money, sum_mix_total_money)
    //   if (handleParams && 'params_mix_per' in handleParams) {
    //     sum_mix_zhizao_money = sum_mix_zhizao_money * (1 + handleParams['params_mix_per'] / 100)
    //     sum_mix_yunying_money = sum_mix_yunying_money * (1 + handleParams['params_mix_per'] / 100)
    //   }
    //   //毛利（原导入）= 原边际贡献 -  制造环节固定成本 - 制造环节对应销量下的混合成本
    //   const maoli = numberToFixed(sum_side_money - sum_fixed_zhizao_money - sum_mix_zhizao_money)
    //   //管理利润
    //   const manager_rate = numberToFixed(maoli - sum_fixed_yunying_money - sum_mix_yunying_money)

    //   return {
    //     maoli:numberToFixed(maoli),
    //     manager_rate:numberToFixed(manager_rate),
    //     sum_sold_num:numberToFixed(sum_sold_num),
    //     sum_price_come_in:numberToFixed(sum_price_come_in),
    //     sum_side_money:numberToFixed(sum_side_money),
    //     sum_fixed_total_money:numberToFixed(sum_fixed_total_money),
    //     sum_mix_total_money:numberToFixed(sum_mix_total_money) ,
    //     sum_material_cost:numberToFixed(sum_material_cost) ,
    //     sum_material_cost_money:numberToFixed(sum_material_cost_money) ,
    //     sum_pass_sold_num:numberToFixed(sum_pass_sold_num) ,
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
.checkbox-group {
  margin-bottom: 10px;
}
.solution-foot {
  // width: 1920px;
  background-color: #ffffff;
  text-align: right;
  .solution-foot-icon {
    display: inline-block;
    padding: 10px 0px 10px 150px;
    border-top: 1px solid var(--themeColor);
    img {
      height: 24px;
    }
  }
  .solution-foot-text {
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding: 0px 30px;
    background-color: var(--themeColor);
    width: 100%;
    color: #fff;
    text-align: center;
    span {
      float: right;
    }
  }
}
.table-children-box {
  padding: 15px 0px;
}
</style>
