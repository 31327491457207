<template></template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

import { numberToFixed, roundToDotNumber, checkZero, tableDefautlLine, tableSortFnc, tableDefaultPage, fnc_excelData_yc_line, fnc_excelData_yc_after, dealThousands } from '@/utils/util'
import { mapGetters } from 'vuex'
import { systemConfig } from '@/store/reactiveState'
import { SITE_SETTINGS, THEME_COLOR } from '@/store/mutation-types'
import ls from '@/utils/Storage'

import SliderInputBlock from '@/components/SliderInputBlock.vue'
import SvpLine from './SvpLine.vue'
import SvpScatter from './SvpScatter.vue'
import calMixIn from '../calMixIn.vue'
import { structData_stock_and_yc, structData_serialStand, structData_serialProduction, structData_NSerial } from '@/utils/UtilToos2P'

import SolutionExtraPlan_SFCBB from './SolutionExtraPlan_SFCBB.vue'
import SolutionExtraPlan_QTLMX from './SolutionExtraPlan_QTLMX.vue'
import SolutionExtraPlan_TRCC from './SolutionExtraPlan_TRCC.vue'
import SolutionExtraPlan_MLLR from './SolutionExtraPlan_MLLR.vue'
import SolutionExtraPlan_FXZB from './SolutionExtraPlan_FXZB.vue'

const columns = [
  { width: 50, title: '项目', dataIndex: 'type_name', ellipsis: true, align: 'center', fixed: 'left' },
  { width: 80, title: '销量', dataIndex: 'sold_num', align: 'right', ...tableSortFnc('sold_num') },
  { width: 80, title: '收入', dataIndex: 'price_come_in', align: 'right', ...tableSortFnc('price_come_in') },
  { width: 80, title: '变动成本', dataIndex: 'material_cost_money', align: 'right', ...tableSortFnc('material_cost_money') },
  { width: 80, title: '边际收益', dataIndex: 'slid_money', align: 'right', ...tableSortFnc('slid_money') }
]

export default {
  components: {
    SliderInputBlock,
    SvpLine,
    SvpScatter,
    SolutionExtraPlan_SFCBB,
    SolutionExtraPlan_QTLMX,
    SolutionExtraPlan_TRCC,
    SolutionExtraPlan_MLLR,
    SolutionExtraPlan_FXZB
  },
  data() {
    return {
      pagination: tableDefaultPage(),

      planType: 0,
      tableActionOther: 0,
      tableAction: 0,

      listQuery: {
        id: ''
      },
      //方案详情
      detail: {},

      xAxisNum: 1,
      //整体加成参数设置
      params_tax_per: 0,
      params_sold_per: 0,
      params_cost_per: 0,
      params_pass_per: 0,

      pageDataInitState: false,
      submitLoading: false,

      //备注-基础
      remark_base: '',
      remark_base_mobile: '',
      //备注-扩展
      remark_extra: '',
      remark_extra_mobile: '',

      columns: columns,

      getComeInpointSoldNum: [],
      pageParams: {},

      step2YC_data: [],
      step2Stock_data: [],
      step2SerialStand_data: [],
      step2Cost_data: [],
      step2NSerial_data: [],
      step2SerialProduction_data: [],

      fncResult_stockAndYc: {},
      fncResult_serialStand: {},
      fncResult_SerialProduction: {},
      fncResult_NSerial: {},
      //总销量
      sum_sold_num: 0,
      //x轴所有点
      echartXPoints: [],

      scatterTable: [],
      pageParams: {},
      scatterType: 'customer',
      sfc_showWay: 'no',
      topSumObj: {},
      onChangeParamsTimer: 0,
      hasActualityData: true,

      mySwiper: null,
      tableSwiperOther: null,
      tableSwiper: null,

      mobileParams: {},
      initSettingState: false
    }
  },
  computed: {
    ...mapGetters(['getHasToolAuth']),
    getWaterName() {
      return this.detail.water_name || ''
    },
    getMoneyUnit() {
      let moneyRange = [1, '元']
      if (this.getSumPriceComeIn * 1 > 100000) {
        moneyRange = [10000, '万元']
      }
      return moneyRange
    },
    getNumberFnc() {
      const w_1 = 10000
      const w_10 = 100000
      const div = this.getMoneyUnit[0]
      const soldUnit = this.getMoneyUnit[1]
      return (number, decimal = 2, bindUnit = false) => {
        let unit = soldUnit
        let str = dealThousands((number / div).toFixed(decimal), decimal)
        if (bindUnit) {
          //处理销量问题
          str = dealThousands((number / 1).toFixed(decimal), decimal)
          unit = ''
          if (number * 1 > w_10) {
            unit = '万'
            str = dealThousands((number / w_1).toFixed(decimal), decimal)
          }
        }
        if (bindUnit) {
          str += unit
        }
        return str
      }
    },
    //总销量
    getSumSoldNum() {
      return this.fncResult_stockAndYc.sum_sold_num
    },
    //总收入
    getSumPriceComeIn() {
      return this.fncResult_stockAndYc.sum_price_come_in
    },
    fncColSpanPriceNew() {
      return (moduleName = 'actuality') => {
        if (!this.fncResult_stockAndYc.fncColSpanPrice) {
          return 0
        }
        return this.fncResult_stockAndYc.fncColSpanPrice(
          this.fncResult_stockAndYc.newYcStockData || [],
          this.fncResult_stockAndYc.sum_sold_num,
          this.fncResult_SerialProduction.colSpan || {},
          moduleName
        )
      }
    },
    //毛利
    getMaoli() {
      // fixedMoney: fncResult_NSerial.totalCost,
      //   fixedNoMoney: fncResult_NSerial.totalCostNo,
      return roundToDotNumber(this.getSumPriceComeIn - this.fncResult_NSerial.totalCostNo - this.getSumMaterialCost)
    },
    //总成本
    getSumMaterialCostMoney() {
      return this.fncResult_stockAndYc.sum_cost_price
    },
    //毛利率
    getMaoliPer() {
      return roundToDotNumber(checkZero(this.getSumPriceComeIn) ? 0 : (this.getMaoli / this.getSumPriceComeIn) * 100) + '%'
    },
    //变动成本
    getSumMaterialCost() {
      return this.fncColSpanPriceNew()
    },
    //管理利润
    getManagerRate() {
      return roundToDotNumber(this.getSumPriceComeIn - this.fncResult_NSerial.totalCost - this.getSumMaterialCost)
    },
    //边际贡献
    getSumSideMoney() {
      return this.getSumPriceComeIn - this.getSumMaterialCost
    },
    //管理利润率
    getManagerRatePer() {
      return checkZero(this.getSumPriceComeIn) ? '0.00%' : roundToDotNumber((this.getManagerRate / this.getSumPriceComeIn) * 100) + '%'
    },
    //固定成本
    getSumFixedTotalMoney() {
      return this.fncResult_NSerial.totalCost
    },
    // //混合成本
    // getSumMixTotalMoney() {
    //   return this.getCalData.sum_mix_total_money
    // },

    getScatterTableInfo() {
      let scatterInfo = {}
      for (let i = 0; i < this.scatterTable.length; i++) {
        const info = this.scatterTable[i]
        if (info.key === this.scatterType) {
          scatterInfo = info
          break
        }
      }
      return scatterInfo
    },
    getScatterTableData() {
      return this.getScatterTableInfo.data || []
    }
  },
  watch: {
    planType() {
      this.initSwiper()
    }
  },
  created() {
    this.listQuery.id = this.$route.query.id || 0
  },

  mixins: [],
  mounted() {
    let themeColor = ''
    if (this.$route.query.themeColor && this.$route.query.themeColor !== '') {
      themeColor = '#' + this.$route.query.themeColor
      systemConfig.commit(THEME_COLOR, themeColor)
    }
    this.$nextTick(() => {
      this.initSwiper()
      this.getPlanDetail()

      const swiperNavClick = document.querySelectorAll('.swiperNavClick')
      if (swiperNavClick) {
        swiperNavClick.forEach((item, index) => {
          item.addEventListener('click', () => {
            if (this.tableActionOther === 0) {
              this.tableSwiperOther.slideNext()
              this.tableActionOther = 1
            } else if (this.tableActionOther === 1) {
              this.tableSwiperOther.slidePrev()
              this.tableActionOther = 0
            }
          })
        })
      }
    })
  },
  beforeDestroy() {
    if (this.tableSwiperOther) {
      this.tableSwiperOther.destroy()
      this.tableSwiperOther = null
    }
  },
  methods: {
    onParamsSettingChange(field, set = true) {
      if (!this.initSettingState) {
        return
      }

      if (set) {
        this.$store.state.app.cacheMobileParams[field] = this[field]
        this.onChangeParams()
      } else {
        return this.$store.state.app.cacheMobileParams[field]
      }
    },

    initParamsSetting() {
      //初始化配置参数

      this.initSettingState = true
      let pageParams = this.mobileParams || {}
      if (!pageParams || !('params_tax_per' in pageParams)) {
        pageParams = this.pageParams || {}
      }

      let params_tax_per = pageParams.params_tax_per || 0
      let params_sold_per = pageParams.params_sold_per || 0
      let params_cost_per = pageParams.params_cost_per || 0
      let params_pass_per = pageParams.params_pass_per || 0

      const cache_params_tax_per = this.onParamsSettingChange('params_tax_per', false)
      if (cache_params_tax_per != null) {
        params_tax_per = cache_params_tax_per
      }

      const cache_params_sold_per = this.onParamsSettingChange('params_sold_per', false)
      // console.log("params_sold_per:"+params_sold_per,this.$store.state.app.cacheMobileParams)
      if (cache_params_sold_per != null) {
        params_sold_per = cache_params_sold_per
      }

      const cache_params_cost_per = this.onParamsSettingChange('params_cost_per', false)
      if (cache_params_cost_per != null) {
        params_cost_per = cache_params_cost_per
      }

      const cache_params_pass_per = this.onParamsSettingChange('params_pass_per', false)
      if (cache_params_pass_per != null) {
        params_pass_per = cache_params_pass_per
      }

      this.params_tax_per = params_tax_per || 0
      this.params_sold_per = params_sold_per || 0
      this.params_cost_per = params_cost_per || 0
      this.params_pass_per = params_pass_per || 0
    },
    changeSettingParams() {
      this.$network('/api/tools/reulstUpdateMobileParams', {
        id: this.listQuery.id,
        mobile_params: {
          params_tax_per: this.params_tax_per,
          params_sold_per: this.params_sold_per,
          params_cost_per: this.params_cost_per,
          params_pass_per: this.params_pass_per
        }
      })
        .then((res) => {
          this.$message.success('已更新')
        })
        .catch((err) => {})
    },

    initSwiper() {
      const mySwiper = new Swiper('.swiper-canvas', {
        noSwiping: true,
        observer: true,
        observeParents: true,
        on: {
          slideChangeTransitionStart: (e) => {
            this.planType = mySwiper.activeIndex
          }
        }
      })

      const tableSwiperOther = new Swiper('.swiper-table-other', {
        noSwiping: true,
        observer: true,
        observeParents: true,
        allowTouchMove: false
        // on: {
        //   slideChangeTransitionStart: (e) => {
        //     this.tableActionOther = tableSwiperOther.activeIndex
        //   }
        // }
      })

      this.tableSwiperOther = tableSwiperOther
      const tableSwiper = new Swiper('.swiper-table', {
        noSwiping: true,
        observer: true,
        observeParents: true,
        on: {
          slideChangeTransitionStart: (e) => {
            this.tableAction = tableSwiper.activeIndex
          }
        }
      })
    },
    onChangeParams() {
      if (this.detail.id) {
        clearTimeout(this.onChangeParamsTimer)
        this.onChangeParamsTimer = setTimeout(() => {
          this.fncSettingOptions({
            params_tax_per: this.params_tax_per || 0,
            params_sold_per: this.params_sold_per || 0,
            params_cost_per: this.params_cost_per || 0,
            params_pass_per: this.params_pass_per || 0
          })
        }, 300)
      }
    },
    onScatterTypeChange(e) {
      var obj = { line: '产品线', type: '产品类别', customer: '客户', area: '区域', group: '业务组' }
      var selected = {}
      for (let key in obj) {
        selected[obj[key]] = this.scatterType == key
      }
      this.$refs.svpScatterActuality.setEchart({
        legend: { selected: selected }
      })
    },
    onChangeData_svp(options) {
      this.getComeInpointSoldNum = options.zeroPintValues || []
    },
    showMoreTableList(item) {
      const selectKey = item.key || ''
      // const selectBlock = this.topSumObj[selectKey]||{}

      if (this.topSumObj[selectKey].pagination === false) {
        this.topSumObj[selectKey].pagination = tableDefaultPage()
        this.calOtherSum(item, this.topSumObj[selectKey].upSortData, false)
      } else {
        this.topSumObj[selectKey].pagination = false
      }
    },
    //计算其它加个
    calOtherSum(item, currentDataSource, recordUpData = true) {
      const selectKey = item.key || ''
      const selectBlock = this.topSumObj[selectKey] || {}
      const totalObj = {}
      for (let key in selectBlock) {
        if (key == 'upSortData') continue

        const pageSize = this.pagination.pageSize || 0
        const field = key.replace('sum_', '')
        const topSumObj_field = key
        totalObj[topSumObj_field] = 0
        let total = 0
        for (let i = 0; i < currentDataSource.length; i++) {
          const info = currentDataSource[i]

          if (i >= tableDefautlLine) {
            break
          }
          totalObj[topSumObj_field] = roundToDotNumber(totalObj[topSumObj_field] * 1 + info[field] * 1)
        }
        totalObj[topSumObj_field] = roundToDotNumber(totalObj[topSumObj_field])
      }

      if (recordUpData) {
        totalObj.upSortData = currentDataSource
      } else {
        totalObj.upSortData = selectBlock.upSortData
      }
      totalObj.pagination = tableDefaultPage()
      // console.log('>>>>>>>>>>>>>>>')
      // console.log(totalObj)
      this.topSumObj[selectKey] = totalObj
    },
    onTableChange(item, pagination, filters, sorter, { currentDataSource, action }) {
      // console.log('>>>>>>>>>>>>>>>>>> onTableChange <<<<<<<<<<<<<<<<<<<')
      if (action == 'sort') {
        return this.calOtherSum(item, currentDataSource)
      }
    },
    onScatterData({ tableDataList, topSumObj }) {
      this.scatterTable = tableDataList
      this.topSumObj = topSumObj
      // console.log('>>>>>>>>>> onScatterData <<<<<<<<<<')
      // console.log(tableDataList)
      this.$nextTick(() => {
        tableDataList.map((item) => {
          this.calOtherSum(item, item.data)
        })
      })
    },
    handleParams(type) {
      if (type == 'zero') {
        this.params_tax_per = 0
        this.params_sold_per = 0
        this.params_cost_per = 0
        this.params_pass_per = 0
      } else {
        this.params_tax_per = this.pageParams.params_tax_per || 0
        this.params_sold_per = this.pageParams.params_sold_per || 0
        this.params_cost_per = this.pageParams.params_cost_per || 0
        this.params_pass_per = this.pageParams.params_pass_per || 0
      }

      this.fncSettingOptions({
        params_tax_per: this.params_tax_per || 0,
        params_sold_per: this.params_sold_per || 0,
        params_cost_per: this.params_cost_per || 0,
        params_pass_per: this.params_pass_per || 0
      })
    },

    //方案详情
    getPlanDetail() {
      this.$network('/api/tools/planDetail', this.listQuery)
        .then((res) => {
          const data = res.data || {}
          const detail = data.detail || {}
          const vip_type = data.vip_type || ''

          const pageParams = detail.page_params || {}
          this.mobileParams = detail.mobile_params || {}
          this.pageParams = pageParams
          this.detail = detail
          //vip类型
          this.$store.commit('updateVipType', { vip_type: vip_type })

          this.remark_base = detail.remark_base || ''
          this.remark_base_mobile = detail.remark_base_mobile || ''
          this.remark_extra = detail.remark_extra || ''
          this.remark_extra_mobile = detail.remark_extra_mobile || ''

          this.$nextTick(() => {
            // this.params_tax_per = pageParams.params_tax_per || 0
            // this.params_sold_per = pageParams.params_sold_per || 0
            // this.params_cost_per = pageParams.params_cost_per || 0
            // this.params_pass_per = pageParams.params_pass_per || 0

            //初始化配置参数
            this.initParamsSetting()

            this.$nextTick(() => {
              if (!this.getHasToolAuth('tools2_base')) {
                alert('需升级权益 查看更多内容')
                return
              }
              this.getData(pageParams.solutionBaseTable || {})
            })
          })
        })
        .catch((err) => {
          console.error(err)
        })
    },

    //处理数据
    getData(noPer = {}) {
      Promise.all([
        //销售预测表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-yc' }),
        //存货期初结存表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-stock' }),
        //单位产品工序用量与标准成本表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-serial-stand' }),
        //成本中心档案
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-cost' }),
        //非工序投入表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-n-serial' }),
        //工序投入产出明细表
        this.$network('/api/tools/planResultData', { id: this.listQuery.id, type: 'step2-serial-production' })
      ]).then((res) => {
        const [
          {
            data: { list: step2YC_data }
          },
          {
            data: { list: step2Stock_data }
          },
          {
            data: { list: step2SerialStand_data }
          },
          {
            data: { list: step2Cost_data }
          },
          {
            data: { list: step2NSerial_data }
          },
          {
            data: { list: step2SerialProduction_data, otherData: step2SerialProduction_otherData }
          }
        ] = res
        this.step2YC_data = step2YC_data
        this.step2Stock_data = step2Stock_data
        this.step2SerialStand_data = step2SerialStand_data
        this.step2Cost_data = step2Cost_data
        this.step2NSerial_data = step2NSerial_data
        this.step2SerialProduction_data = step2SerialProduction_data
        this.hasActualityData = step2SerialProduction_otherData.has_actuality == 1
        this.fncSettingOptions({
          params_tax_per: this.params_tax_per || 0,
          params_sold_per: this.params_sold_per || 0,
          params_cost_per: this.params_cost_per || 0,
          params_pass_per: this.params_pass_per || 0
        })
      })
    },

    fncSettingOptions(params, returnData = false) {
      const step2YC_data = JSON.parse(JSON.stringify(this.step2YC_data))
      const step2Stock_data = JSON.parse(JSON.stringify(this.step2Stock_data))
      const step2SerialStand_data = JSON.parse(JSON.stringify(this.step2SerialStand_data))
      const step2Cost_data = JSON.parse(JSON.stringify(this.step2Cost_data))
      const step2NSerial_data = JSON.parse(JSON.stringify(this.step2NSerial_data))
      const step2SerialProduction_data = JSON.parse(JSON.stringify(this.step2SerialProduction_data))

      // const params_cost_per = params.params_cost_per || 0
      const fncResult_stockAndYc = structData_stock_and_yc(step2YC_data, step2Stock_data, params)()
      const fncResult_serialStand = structData_serialStand(step2SerialStand_data, params)()

      const fncResult_SerialProduction = structData_serialProduction(step2SerialProduction_data, fncResult_serialStand, fncResult_stockAndYc, params)()

      const fncResult_NSerial = structData_NSerial(step2NSerial_data)()

      const colSpan = fncResult_SerialProduction.colSpan || {}
      // this.$refs.solutionTable.initTableData({
      //   tableData:fncResult_SerialProduction.newTableData,
      //   colSpan:fncResult_SerialProduction.colSpan,
      //   summaryObject:fncResult_SerialProduction.summaryObject,
      // })
      if (returnData) {
        return { fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction, step2Stock_data }
      }
      this.fncResult_stockAndYc = fncResult_stockAndYc
      this.fncResult_serialStand = fncResult_serialStand
      this.fncResult_SerialProduction = fncResult_SerialProduction
      this.fncResult_NSerial = fncResult_NSerial

      setTimeout(() => {
        //预测线条
        const svpLines = ['tools2SvpActuality']
        svpLines.map((item) => {
          if (this.$refs[item]) {
            this.$refs[item].drawLines({
              soldNum: fncResult_stockAndYc.sum_sold_num,
              tableList: fncResult_stockAndYc.newYcStockData || [],
              avg_pass_per_product: fncResult_stockAndYc.avg_pass_per_product,
              avg_price_no_tax_unit_money: fncResult_stockAndYc.avg_price_no_tax_unit_money,
              fixedMoney: fncResult_NSerial.totalCost,
              fixedNoMoney: fncResult_NSerial.totalCostNo,
              colSpan: colSpan,
              fncColSpanPrice: fncResult_stockAndYc.fncColSpanPrice
            })
          }
        })
        const svpScatter = ['svpScatterActuality']
        svpScatter.map((item) => {
          if (this.$refs[item]) {
            this.$refs[item].drawLines({
              soldNum: fncResult_stockAndYc.sum_sold_num,
              tableList: fncResult_stockAndYc.newYcStockData || [],
              avg_price_no_tax_unit_money: fncResult_stockAndYc.avg_price_no_tax_unit_money,
              avg_pass_per_product: fncResult_stockAndYc.avg_pass_per_product,
              colSpan: colSpan,
              fncColSpanPrice: fncResult_stockAndYc.fncColSpanPrice,
              fncColSpanPriceTableList: fncResult_stockAndYc.fncColSpanPriceTableList
            })
          }
        })
      }, 1000)
      //收发成本表
      // this.$refs.planSFCBB_no.createTableData(fncResult_SerialProduction.newTableData || [], fncResult_serialStand.colSpan || {}, fncResult_SerialProduction.colSpan || {}, 'no')
      // this.$refs.planSFCBB_customer.createTableData(fncResult_SerialProduction.newTableData || [], fncResult_serialStand.colSpan || {}, fncResult_SerialProduction.colSpan || {}, 'customer')
      //齐套率
      if (this.$refs.planQTLMX) {
        this.$refs.planQTLMX.createTableData({}, this)
      }

      //投入产出表
      if (this.$refs.planTRCC) {
        const grouTableObj = this.$refs.planTRCC.createTableData({}, this)
      }
      // const grouTableObj = this.$refs.planTRCC.createTableData(fncResult_SerialProduction.newTableData || [], step2Stock_data || [])

      //管理利润
      if (this.$refs.planMLLR_plan) {
        this.$refs.planMLLR_plan.createTableData(fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction, fncResult_NSerial, step2Stock_data)
      }

      if (this.$refs.planMLLR_actuality) {
        this.$refs.planMLLR_actuality.createTableData(fncResult_stockAndYc, fncResult_serialStand, fncResult_SerialProduction, fncResult_NSerial, step2Stock_data)
      }
    },
    handleSubmit() {
      const obj = { id: this.detail.id }
      if (this.planType) {
        obj.remark_extra_mobile = this.remark_extra_mobile
      } else {
        obj.remark_base_mobile = this.remark_base_mobile
      }
      this.submitLoading = true
      this.$network('/api/tools/planHistoryEdit', obj)
        .then((res) => {
          this.$message.success('保存成功')
          this.submitLoading = false
        })
        .catch(() => {
          this.submitLoading = false
        })
    }
  }
}
</script>

<style>
</style>